import { get } from 'lodash';
import { CACHE_ACTIONS } from '~/constants/cache-actions.constants';
import * as drawerMonthlyPaymentActions from '~/containers/DrawerMonthlyPayment/actions';

const monthly = (state = {data: null, isLoading: true}, action) => {
    switch (action.type) {
        case 'LOAD_MONTHLY_PAYMENTS': {
            return {
                ...state,
                filter: get(action, "payload.filter"),
            };
        }

        case CACHE_ACTIONS.monthlyPayments: {
            return {
                ...state,
                data: action.payload,
                isLoading: false
            }
        }
      
        case "LOAD_MONTHLY_PAYMENTS_SUCCESS":
            return {
                ...state,
                data: action.payload.data.data,
                isLoading: false
            };

        case  drawerMonthlyPaymentActions.SAVE.SUCCESS: 
        return {
            ...state,
            data: [...state.data.map(item => item.id !== action.meta.previousAction.id? item : action.payload.data.data)]
        }

        case '@@MONTHLY/DELETE_SUCCESS':
        return {
            ...state,
            data: [...state.data.filter(item => item.id !== action.meta.previousAction.id)]
        }

        case '@@MONTHLY/STATUS_SUCCESS':
        return {
            ...state,
            data: [...state.data.map(item => item.id !== action.meta.previousAction.id? item : action.payload.data.data)]
        }
        
        default:
        return state
    }
}

export default monthly;