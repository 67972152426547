export const phoneFormatter = (value) => {
  if (!value) return '';
  return value
    .replace(/\D/g, '')
    .substring(0, 11)
    .replace(/^(\d{2})(\d)/g, '($1) $2')
    .replace(/(\d)(\d{4})$/, '$1-$2');
};

export const identificationNumberFormatter = (value) => {
  if (!value) return '';

  return value.replace(/\D/g,"")
    .replace(/^(\d{2})(\d)/,"$1.$2")
    .replace(/^(\d{2})\.(\d{3})(\d)/,"$1.$2.$3")
    .replace(/\.(\d{3})(\d)/,".$1/$2")
    .replace(/(\d{4})(\d)/,"$1-$2");
}

export const removeCountryCodeFromPhone = (phone) => {
  if (!phone) return undefined;

  if (phone.length > 9 && phone.startsWith('55')) {
    return phone.slice(2);
  }

  return phone
}

export const capitalizeFormatter = (value) => {
  return `${value.slice(0, 1).toUpperCase()}${value.slice(1)}`;
}

export const convertToBRL = (value) => {
  if (value === null || value === undefined) return '';

  return new Intl.NumberFormat('pt-BR', {
    style: 'currency',
    currency: 'BRL',
  }).format(value)
}

export const limitCharacterLength = (text, maxLength) => {
  if (text.length > maxLength) {
    return `${text.slice(0, maxLength)}...`;
  }

  return text;
};

export const removeNonNumbers = (value) => {
  if (!value) return null;
  return value.replace(/\D/g, "")
}

export const formatAnyField = (field, value) => {
  switch (field) {
    case 'phone':
      return phoneFormatter(value);
    case 'identification_number':
      return identificationNumberFormatter(value);
    case 'billing':
    case 'first_honorary':
    case 'honorary':
      return convertToBRL(value);
    default:
      return value;
  }
}

export const selectIfOne = (array) => {
  if (!array || array.length === 0) {
    return undefined;
  }
  if (array.length === 1) {
    return array[0].description;
  }

  return undefined;
}