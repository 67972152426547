import { Breadcrumb, Icon, Tooltip } from "antd";
import React from "react";
import { useSelector } from "react-redux";
import uid from 'uid';
import styled from "styled-components";

import { Container, StyledBreadcrumb, Title } from "./styles";
import { checkForPermissions } from "../UI/Layout/utils";

export const ModuleHeaderWrapper = styled.div`
    margin: 0px -16px;
    padding: 15px;
    background: #fff;
    margin-bottom: 10px;
    display: flex;
    flex-direction: row;
    position: relative;
    justify-content: space-between;
    align-items: center;
`;

export const ButtonsWrapper = styled.div`
  display: flex;
  align-items: flex-start;
  gap: 10px;
`;

export const ModuleHeader = ({ breadcrumbs, title = null, ...props }) => {
  const { user, users } = useSelector((state) => ({
    user: state.user,
    users: state.users,
  }));

  const isPartner = user.permissions_group_name === "Sócio";

  const activeUsers = users.filter(({ deleted_at, disabled_at }) => !deleted_at && !disabled_at);

  const allowedUsersName =
    isPartner && activeUsers.length > 0
      ? activeUsers
          .filter(({ name, permissions }) => checkForPermissions(permissions) && !!name)
          .map(({ name }) => name)
          .join(", ")
      : "";

  const [firstItem, ...restItems] = breadcrumbs;
  
  return (
    <Container {...props}>
      <StyledBreadcrumb>
        <Breadcrumb.Item>
          {isPartner && (
            <Tooltip title={allowedUsersName}>
              <Icon type="lock" />
            </Tooltip>
          )}
          <span>{firstItem}</span>
        </Breadcrumb.Item>
        {restItems.map((item) => (
          <Breadcrumb.Item key={uid()}>{item}</Breadcrumb.Item>
        ))}
      </StyledBreadcrumb>
      <Title>{title || breadcrumbs.slice(-1)}</Title>
    </Container>
  );
};
