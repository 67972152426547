import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Dropdown, Menu, Card, Button, Input, Form, Row, Col, Statistic, Modal, Badge, message, Popover } from 'antd';
import moment from 'moment';
import fileDownload from 'js-file-download';

import 'moment/locale/pt-br';
import get from 'lodash/get';

import { toBRL } from '../UI/InputCurrency';
import AdvancedFilter from './Components/AdvancedFilter';
import DownloadBatch from '../Components/DownloadBatch';
import { request } from '../Instance';
import * as montlyPaymentActions from '~/components/MonthlyPayment/actions';
import { finishCurrentOnboardingStepAction } from '../Onboarding/helpers';
import { ModuleHeader } from '../ModuleHeader';
import { HeaderLeftContent, HeaderRightContent, HeaderContainer } from './styles';
import DrawerMonthlyPayment from '~/containers/DrawerMonthlyPayment';
import TableList from './Components/TableList';

const { Search } = Input;

class MonthlyPaymentList extends Component {
  state = {
    month: moment(),
    search: '',
    actived: 1,
    drawerPay: null,
    isGenerateLoading: false,
    selectedRowKeys: [],
  }

  onAction = (e, data) => {
    switch (e.key) {
      case 'pay':
        this.setState({ drawerPay: data });
        break;
      case 'inadimplente':
        Modal.confirm({
          title: 'Você realmente deseja marcar como inadimplente?',
          content: 'Você poderá alterar isso mais tarde.',
          okText: 'Sim',
          okType: 'danger',
          cancelText: 'Cancelar',
          onOk: () => this.props.markAsDefaulting(data.id)
        });
        break;
      case 'ajuizado':
        Modal.confirm({
          title: 'Você realmente deseja marcar como ajuizado?',
          content: 'Você poderá alterar isso mais tarde.',
          okText: 'Sim',
          okType: 'danger',
          cancelText: 'Cancelar',
          onOk: () => this.props.markAsProcessed(data.id)
        });
        break;
      case 'delete':
        Modal.confirm({
          title: 'Você realmente deseja excluir esta menalidade?',
          content: 'Mensalidades excluidas são geradas automaticamente após gerar novamente as mensalidades mensais.',
          okText: 'Sim',
          okType: 'danger',
          cancelText: 'Cancelar',
          onOk: () => this.props.deleteHonorary(data.id)
        });
        break;
    }
  }

  changeAmount = (key, value) => {
    const context = this;
    const { drawerPay } = this.state;

    drawerPay.amount = value;

    context.setState({ drawerPay: drawerPay });
    this.calculateDiff();
  }

  changePaid = (key, value) => {
    const context = this;
    const { drawerPay } = this.state;

    drawerPay.paid = value;

    context.setState({ drawerPay: drawerPay });
    this.calculateDiff();
  }

  calculateDiff = () => {

    const context = this;
    const { drawerPay } = this.state;

    let diff = drawerPay.paid > 0 ? drawerPay.paid - drawerPay.amount : 0;

    if (diff > 0) {
      drawerPay.interest = diff;
      drawerPay.discount = 0;
    } else if (diff < 0) {
      drawerPay.interest = 0;
      drawerPay.discount = diff;
    } else {
      drawerPay.interest = diff;
      drawerPay.discount = diff;
    }


    context.setState({ drawerPay: drawerPay });
  }

  handleGenerate = () => {
    const { filter, monthlyGenerate, loadPayments, finishCurrentOnboardingStep, onboardingStep, user } = this.props;

    this.setState({ isGenerateLoading: true });

    monthlyGenerate({
      from: filter.from,
      to: filter.to
    })
      .then(() => {
        this.setState({ isGenerateLoading: false });
        finishCurrentOnboardingStep({
          isActive: this.checkIfStepIsActive(11),
          step: onboardingStep,
          user
        })
        loadPayments(filter);
      });
  }

  selectRow = (record) => {
    const selectedRowKeys = [...this.state.selectedRowKeys];
    if (selectedRowKeys.indexOf(record.key) >= 0) {
      selectedRowKeys.splice(selectedRowKeys.indexOf(record.key), 1);
    } else {
      selectedRowKeys.push(record.key);
    }

    this.setState({ selectedRowKeys });
  }

  onSelectedRowKeysChange = (selectedRowKeys) => {
    this.setState({ selectedRowKeys });
  }

  handleExportToExcel = async () => {
    const selectedRowKeys = [...this.state.selectedRowKeys];

    const { filter } = this.props;
    try {
      const response = await request('POST', `/monthly/export/excel`, {
        ...filter,
        ids: selectedRowKeys
      }, true, {
        responseType: 'blob'
      })

      const formattedFrom = moment(filter.from).format('DD/MM/YYYY');
      const formattedTo = moment(filter.to).format('DD/MM/YYYY');

      fileDownload(response.data, `Mensalidades_${formattedFrom}_${formattedTo}.xlsx`);
    } catch (error) {
      message.error('Não foi possível realizar a exportação!');
    }
  }

  checkIfStepIsActive = (step) => {
    const { isOnboardingActive, onboardingStep, isVideoModalVisible } = this.props;
    return !isVideoModalVisible && isOnboardingActive && onboardingStep === step;
  }

  render() {
    const { monthly, user, filter, isLoading } = this.props;
    const { search, drawerPay, isGenerateLoading, selectedRowKeys } = this.state;

    let filtered = (monthly !== null) ? monthly.filter((str) => {

      if (str.customer.name.toLowerCase().indexOf(search.toLowerCase()) >= 0) {
        return true;
      }


      if (str.customer.cod !== null && str.customer.cod.toLowerCase().indexOf(search.toLowerCase()) >= 0) {
        return true;
      }


      return false;
    }) : [];

    let filteredByStatus = (status) => filtered.filter((str) => {

      if (str.status === status) {
        return true;
      }

      return false;
    });

    const sum = (array, key) => {
      return array.reduce((a, b) => a + (b[key] || 0), 0);
    }

    const show13 = () => {
      if (sum(filtered, 'amount13') > 0) return true;

      const decimoTerceiroSplit = user.tenant.decimo_terceiro_split;

      const isSingleQuote = decimoTerceiroSplit === 1;
      const isSplitInTwo = decimoTerceiroSplit === 2;
      const isSplitInThree = decimoTerceiroSplit === 3;

      const fromMonth = moment(get(filter, 'from')).format('MM');
      const toMonth = moment(get(filter, 'to')).format('MM');
      const filterBy = get(filter, 'filter_by');

      const isFilteredByDueAt = filterBy === 'due_at';
      const isFilteredByApurationAt = filterBy === 'apuration_at';

      if (isSingleQuote) {
        if (isFilteredByDueAt) {
          return fromMonth === '12' || toMonth === '12';
        } else if (isFilteredByApurationAt) {
          return fromMonth === '11' || toMonth === '11';
        }
      } else if (isSplitInTwo) {
        if (isFilteredByDueAt) {
          return ['11', '12'].includes(fromMonth) || ['11', '12'].includes(toMonth);
        } else if (isFilteredByApurationAt) {
          return ['10', '11'].includes(fromMonth) || ['10', '11'].includes(toMonth);
        }
      } else if (isSplitInThree) {
        if (isFilteredByDueAt) {
          return ['10', '11', '12'].includes(fromMonth) || ['10', '11', '12'].includes(toMonth);
        } else if (isFilteredByApurationAt) {
          return ['09', '10', '11'].includes(fromMonth) || ['09', '10', '11'].includes(toMonth);
        }
      }
    }



    const rowSelection = {
      selectedRowKeys,
      onChange: this.onSelectedRowKeysChange,
    };

    const isEleventhOnboardingActive = this.checkIfStepIsActive(11);
    const isTwelfthOnboardingActive = this.checkIfStepIsActive(12);

    return (
      <div>
        {drawerPay && (
          <DrawerMonthlyPayment
            data={drawerPay}
            setData={(data) => this.setState({ drawerPay: data })}
            isOnboardingActive={isTwelfthOnboardingActive}
            checkIfStepIsActive={this.checkIfStepIsActive}
            finishCurrentOnboardingStep={this.props.finishCurrentOnboardingStep}
            onboardingStep={this.props.onboardingStep}
          />
        )}

        <div style={{
          margin: `0px -16px`,
          padding: '15px',
          background: '#fff',
        }}>
          <HeaderContainer>
            <HeaderLeftContent>
              <ModuleHeader breadcrumbs={['Financeiro', 'Mensalidades']} />
              <Popover
                  placement="right"
                  content="Pesquise pelo nome do cliente ao qual foi gerada a mensalidade"
                  visible={isTwelfthOnboardingActive && !drawerPay}
                >
                <Search
                  placeholder="Pesquisar por cód ou cliente"
                  onChange={e => this.setState({ search: e.target.value })}
                  style={{ maxWidth: 400 , zIndex: isTwelfthOnboardingActive ? 999 : "auto" }}
                />
              </Popover>
            </HeaderLeftContent>
            <HeaderRightContent>
              <Dropdown
                trigger={['click']}
                overlay={
                  <Menu>
                    <Menu.Item key="1" onClick={this.handleGenerate}>
                      Gerar Mensalidades
                    </Menu.Item>
                    <Menu.Item key="2" onClick={this.handleExportToExcel} disabled={isEleventhOnboardingActive}>
                      Exportar para arquivo CSV (excel)
                    </Menu.Item>
                  </Menu>
                }
              >
                <Popover
                  placement="left" 
                  content="Clique aqui e gere mensalidades"
                  visible={isEleventhOnboardingActive}
                >
                  <Button
                    type="primary"
                    loading={isGenerateLoading}
                    disabled={isGenerateLoading}
                    icon="menu"
                    style={{ zIndex: isEleventhOnboardingActive ? 999 : 'auto' }}
                  >
                    Ações
                  </Button>
                </Popover>
              </Dropdown>
              <DownloadBatch
                url="/monthly/payments/print/batch"
                filename="mensalidade.zip"
                fileWillBeDownloaded={selectedRowKeys.length <= 10}
                ids={selectedRowKeys}>
                <Button
                  type="primary"
                  icon="printer"
                >
                  Imprimir
                </Button>
              </DownloadBatch>
            </HeaderRightContent>
          </HeaderContainer>
          <AdvancedFilter />
        </div>
        <Row gutter={16} style={{ paddingTop: 10 }}>
          <Col span={4}>
            <Card style={{ marginBottom: `10px` }} bodyStyle={{ padding: '15px' }}>
              <Statistic title="Total" value={(sum(filtered, 'amount') + sum(filtered, 'additionals_amount') + sum(filtered, 'amount13'))} precision={2} formatter={(v) => toBRL(v)} />
              {show13() && (<Statistic title={<small>Mensalidades</small>} value={sum(filtered, 'amount')} precision={2} formatter={(v) => toBRL(v)} valueRender={(val) => <small>{val}</small>} />)}
              {show13() && (<Statistic title={<small>Adicionais</small>} value={sum(filtered, 'additionals_amount')} precision={2} formatter={(v) => toBRL(v)} valueRender={(val) => <small>{val}</small>} />)}
              {show13() && (<Statistic title={<small>13º Salário</small>} value={sum(filtered, 'amount13')} precision={2} formatter={(v) => toBRL(v)} valueRender={(val) => <small>{val}</small>} />)}
            </Card>
          </Col>
          <Col span={4}>
            <Card style={{ marginBottom: `10px` }} bodyStyle={{ padding: '15px' }}>
              <Statistic title="Pago" value={(sum(filtered, 'paid') + sum(filtered, 'paid13'))} precision={2} valueStyle={{ color: '#52c41a' }} formatter={(v) => toBRL(v)} />
              {show13() && (<Statistic title={<small>Mensalidades & adicionais</small>} value={sum(filtered, 'paid')} precision={2} formatter={(v) => toBRL(v)} valueRender={(val) => <small>{val}</small>} />)}
              {show13() && (<Statistic title={<small>13º Salário</small>} value={sum(filtered, 'paid13')} precision={2} formatter={(v) => toBRL(v)} valueRender={(val) => <small>{val}</small>} />)}
            </Card>
          </Col>
          <Col span={4}>
            <Card style={{ marginBottom: `10px` }} bodyStyle={{ padding: '15px' }}>
              <Statistic title="Saldo" value={(((sum(filtered, 'amount')+sum(filtered, 'additionals_amount')) - sum(filtered, 'paid')) + (sum(filtered, 'amount13') - sum(filtered, 'paid13')))} precision={2} valueStyle={{ color: '#f5222d' }} formatter={(v) => toBRL(v)} />
              {show13() && (<Statistic title={<small>Mensalidades</small>} value={(sum(filtered, 'amount') - sum(filtered, 'paid'))} precision={2} formatter={(v) => toBRL(v)} valueRender={(val) => <small>{val}</small>} />)}
              {show13() && (<Statistic title={<small>Adicionais</small>} value={(sum(filtered, 'additionals_amount') - sum(filtered, 'paid'))} precision={2} formatter={(v) => toBRL(v)} valueRender={(val) => <small>{val}</small>} />)}
              {show13() && (<Statistic title={<small>13º Salário</small>} value={(sum(filtered, 'amount13') - sum(filtered, 'paid13'))} precision={2} formatter={(v) => toBRL(v)} valueRender={(val) => <small>{val}</small>} />)}
            </Card>
          </Col>
          <Col span={4}>
            <Card style={{ marginBottom: `10px` }} bodyStyle={{ padding: '15px' }}>
              <Statistic title={<span><Badge status="warning" /> Inadimplentes</span>} value={(sum(filteredByStatus('Inadimplente'), 'amount') - sum(filteredByStatus('Inadimplente'), 'paid'))} precision={2} formatter={(v) => toBRL(v)} valueRender={(val) => <small>{val}</small>} />
              <Statistic title={<span><Badge status="default" /> Ajuizados</span>} value={(sum(filteredByStatus('Ajuizado'), 'amount') - sum(filteredByStatus('Ajuizado'), 'paid'))} precision={2} formatter={(v) => toBRL(v)} valueRender={(val) => <small>{val}</small>} />
            </Card>
          </Col>
        </Row>
        <div className={`table-clabs${isTwelfthOnboardingActive ? ' onboarding-active' : ''}`}>
          <TableList
            isLoading={isLoading}
            filtered={filtered}
            monthly={monthly}
            drawerPay={drawerPay}
            isTwelfthOnboardingActive={isTwelfthOnboardingActive}
            rowSelection={rowSelection}
            user={user}
            onAction={this.onAction}
            selectRow={this.selectRow}
            changeAmount={this.changeAmount}
            changePaid={this.changePaid}
            calculateDiff={this.calculateDiff}
          />
        </div>
      </div>
    )
  }
}

const mapStateToProps = state => {
  return {
    user: state.user,
    monthly: state.monthly.data,
    filter: state.monthly.filter,
    file: state.monthly.file,
    findUser: (id) => state.users.filter(r => r.id === id)[0],
    onboardingStep: state.onboarding.step,
    isOnboardingActive: state.onboarding.isActive,
    isVideoModalVisible: state.onboarding.videoModal.isVisible,
    isLoading: state.monthly.isLoading
  }
}

const mapDispatchProps = dispatch => {
  return {
    monthlyGenerate: (data) => dispatch({
      type: '@@MONTHLY/GENERATE',
      payload: {
        request: {
          method: 'post',
          url: '/monthly/payments',
          data
        }
      }
    }),
    loadPayments: (filter) => dispatch(montlyPaymentActions.loadPayments(filter)),
    markAsDefaulting: (id) => dispatch({
      type: '@@MONTHLY/STATUS',
      payload: {
        request: {
          method: 'put',
          url: `/monthly/payments/${id}/defaulting`,
        }
      },
      id
    }),
    markAsProcessed: (id) => dispatch({
      type: '@@MONTHLY/STATUS',
      payload: {
        request: {
          method: 'put',
          url: `/monthly/payments/${id}/processed`,
        }
      },
      id
    }),
    deleteHonorary: (id) => dispatch({
      type: '@@MONTHLY/DELETE',
      payload: {
        request: {
          method: 'delete',
          url: '/monthly/payments/' + id,
        }
      },
      id
    }),
    finishCurrentOnboardingStep: ({ isActive, step, user }) => {
      finishCurrentOnboardingStepAction({ isActive, dispatch, step, user })
    }
  }
}

export default connect(mapStateToProps, mapDispatchProps)(Form.create()(MonthlyPaymentList));