import React, { useState } from 'react';
import { Button, DatePicker, Select, Form } from 'antd';
import { connect } from 'react-redux';

import moment from 'moment';
import 'moment/locale/pt-br';

import locale from '~/components/UI/DatePicker/Locale';
import * as refundsActions from './actions';

const { MonthPicker } = DatePicker;
const { Option } = Select;

const AdvancedFilter = ({
    loadRefunds,
    form
}) => {

    const [loading, setLoading] = useState(false);

    const { getFieldDecorator } = form;

    const handleFilter = () => {
        form.validateFields((err, values) => {
            loadRefunds({
                filter_by: values.filter_by, 
                from: values.from.format('YYYY-MM-01'), 
                to: values.to.endOf('month').format('YYYY-MM-DD'), 
                status: values.status
            })
            .then(() => setLoading(false));
        });
    }

    return (
        <Form layout="inline" className="ant-advanced-search-form-inline" onSubmit={(e) => {
            e.preventDefault();
            setLoading(true);
            handleFilter();
        }}>
            <Form.Item label="Filtrar por:">
                {getFieldDecorator('filter_by', {
                    initialValue: 'due_date',
                })(
                <Select>
                    <Option value="due_date">Vencimento</Option>
                    <Option value="apuration_at">Apuração</Option>
                </Select>
                )}
            </Form.Item>
            <Form.Item label="Período" style={{ marginBottom: 0 }}>
            <Form.Item style={{ marginRight: 0 }}>
                {getFieldDecorator('from', {
                    initialValue: moment()
                })(
                <MonthPicker 
                    format={'MM/YYYY'}
                    locale={locale}
                />
                )}
            </Form.Item>
            <span style={{ display: 'inline-block', width: '24px', textAlign: 'center' }}>-</span>
            <Form.Item style={{ marginRight: 0 }}>
                {getFieldDecorator('to', {
                    initialValue: moment()
                })(
                <MonthPicker 
                    format={'MM/YYYY'}
                    locale={locale}
                />
                )}
            </Form.Item>
            </Form.Item>
            <Form.Item label="Status">
                {getFieldDecorator('status', {
                    initialValue: 'all'
                })(
                <Select style={{width: `150px`}}>
                    <Option key={1} value="all">Todos</Option>
                    <Option key={2} value="pay">A pagar</Option>
                    <Option key={3} value="paid">Pago</Option>
                    <Option key={2} value="get">Crédito</Option>
                    <Option key={3} value="got">Recebido</Option>
                </Select>
                )}
            </Form.Item>
            <Form.Item>
            <Button type="primary" htmlType="submit" loading={loading}>
                Filtrar
            </Button>
        </Form.Item>
    </Form>
    )
}

const mapDispatchProps = dispatch => ({
    loadRefunds: (filter) => dispatch(refundsActions.loadRefunds(filter)),
});

export default connect(null, mapDispatchProps)(Form.create()(AdvancedFilter));