import React, { Component } from "react";
import { connect } from "react-redux";
import { Table, Icon, Dropdown, Menu, Modal, Button, Input, Radio } from "antd";
import moment from "moment";

import * as checklistActions from "~/containers/Checklist/actions";
import * as drawerChecklistActions from "~/containers/DrawerChecklist/actions";

import {
  ConcludedRadioButton,
  HeaderContainer,
  HeaderLeftContent,
  LateRadioButton,
  PendingRadioButton,
} from "./styles";
import {
  getAllowedDepartments,
  getPercentageColor,
  getStatusName,
  getStatusStyle,
  getTaskPercentage,
  getTaskTotalPercentage,
} from "./helpers";
import { ModuleHeader } from '~/components/ModuleHeader';
import { getDefaultPaginationConfig } from '~/utils/get-default-pagination-config';
import { STORAGE } from '~/constants/storage.constants';

const { Column } = Table;

const Search = Input.Search;

class Checklist extends Component {
  state = {
    search: "",
    activeButton: "late",
  };

  onAction = (e, item) => {
    switch (e.key) {
      case "edit":
        this.props.showDrawer(item);
        break;
      case "delete":
        Modal.confirm({
          title: "Você realmente deseja excluir este checklist?",
          content: "Esta ação não poderá ser desfeita.",
          okText: "Sim",
          okType: "danger",
          cancelText: "Cancelar",
          onOk: () => this.props.deleteChecklist(item.id),
        });
        break;
      default:
        return;
    }
  };

  renderPercentage = (percentage) => (
    <span style={{ color: getPercentageColor(percentage) }}>{percentage}%</span>
  );

  checkIfHasPermission = (department) => {
    const { allowedDepartments } = this.props;
    return allowedDepartments.includes(department);
  };

  render() {
    const { checklists, isLoading } = this.props;
    const { activeButton } = this.state;

    const filteredChecklists = checklists[activeButton]
      .filter((item) => {
        return item.customer
          ? item.customer.name.toLowerCase().includes(this.state.search)
          : false;
      })
      .sort((a, b) => moment(b.created_at).diff(moment(a.created_at)));

    return (
      <div>
        <div
          style={{
            margin: `0px -16px`,
            padding: "15px",
            background: "#fff",
            marginBottom: `10px`,
          }}
        >
          <HeaderContainer>
            <HeaderLeftContent>
              <ModuleHeader breadcrumbs={['Tarefas', 'Checklist']} />
              <Search
                placeholder="Pesquisar pelo nome do cliente"
                onChange={(e) =>
                  this.setState({ search: e.target.value.toLowerCase() })
                }
                style={{ maxWidth: 400 }}
              />
              <Radio.Group
                value={activeButton}
                onChange={(e) => this.setState({ activeButton: e.target.value })}
              >
                <LateRadioButton value="late" isActive={activeButton === "late"}>
                  Atrasados ({checklists.late.length})
                </LateRadioButton>
                <PendingRadioButton
                  value="pending"
                  isActive={activeButton === "pending"}
                >
                  Pendentes ({checklists.pending.length})
                </PendingRadioButton>
                <ConcludedRadioButton
                  value="concluded"
                  isActive={activeButton === "concluded"}
                >
                  Concluídos ({checklists.concluded.length})
                </ConcludedRadioButton>
              </Radio.Group>
            </HeaderLeftContent>
            <Button
              type="primary"
              onClick={() => this.props.showDrawer({})}
            >
              <Icon type="folder-add" /> Novo item
            </Button>
          </HeaderContainer>
        </div>
        <div className="table-clabs">
          <Table
            rowKey="id"
            loading={isLoading}
            dataSource={filteredChecklists}
            pagination={getDefaultPaginationConfig({
              storageKey: STORAGE.defaultPageSize.checklists,
              defaultPageSize: 1000
            })}
            bordered={true}
            rowClassName="row-clabs"
            scroll={{ x: 1890, y: `${window.innerHeight - 360}px` }}
          >
            <Column
              title="Status"
              dataIndex="status"
              key="status"
              align="center"
              width={300}
              fixed="left"
              render={(status) => (
                <Button value={1} size="small" style={getStatusStyle(status)}>
                  {getStatusName(status)}
                </Button>
              )}
              filters={[
                { text: "Troca de contabilidade (Saída)", value: 3 },
                { text: "Troca de contabilidade (Entrada)", value: 4 },
                { text: "Entrada", value: 2 },
                { text: "Encerramento", value: 1 },
              ]}
              filterMultiple={false}
              onFilter={(value, record) => record.status === value}
            />
            <Column
              title="Cliente"
              key="customer_name"
              dataIndex="customer.name"
              fixed="left"
              width={400}
              render={(name, record) => {
                if (!record.customer) return null;
                return record.customer.cod
                  ? `${record.customer.cod} - ${name}`
                  : name;
              }}
            />
            <Column
              title="Data de solicitação"
              dataIndex="requested_at"
              key="requested_at"
              align="center"
              render={(date) => moment(date).format("DD/MM/YYYY")}
            />
            <Column
              title="Competência"
              dataIndex="competency_at"
              key="competency_at"
              align="center"
              render={(date, record) =>
                date
                  ? `${
                      [1, 3].includes(record.status) ? "até" : "a partir de"
                    } ${moment(date).format("MM/YYYY")}`
                  : ""
              }
            />
            <Column
              title="Vencimento"
              dataIndex="due_at"
              key="due_at"
              align="center"
              render={(date) => (date ? moment(date).format("MM/YYYY") : "")}
            />
            {this.checkIfHasPermission("1") && (
              <Column
                title="Legalização"
                align="center"
                dataIndex="legalization_percentage"
                render={(percentage) => this.renderPercentage(percentage)}
              />
            )}
            {this.checkIfHasPermission("2") && (
              <Column
                title="Administrativo"
                align="center"
                dataIndex="administrative_percentage"
                render={(percentage) => this.renderPercentage(percentage)}
              />
            )}
            {this.checkIfHasPermission("3") && (
              <Column
                title="Contábil"
                align="center"
                dataIndex="accounting_percentage"
                render={(percentage) => this.renderPercentage(percentage)}
              />
            )}
            {this.checkIfHasPermission("4") && (
              <Column
                title="Fiscal"
                align="center"
                dataIndex="fiscal_percentage"
                render={(percentage) => this.renderPercentage(percentage)}
              />
            )}
            {this.checkIfHasPermission("5") && (
              <Column
                title="Pessoal"
                align="center"
                dataIndex="personal_percentage"
                render={(percentage) => this.renderPercentage(percentage)}
              />
            )}
            {this.checkIfHasPermission("6") && (
              <Column
                title="Sucesso do Cliente"
                align="center"
                dataIndex="cs_percentage"
                render={(percentage) => this.renderPercentage(percentage)}
              />
            )}
            <Column
              title="Total"
              align="center"
              dataIndex="total_percentage"
              render={(percentage) => this.renderPercentage(percentage)}
            />
            <Column
              dataIndex="actions"
              key="actions"
              width={100}
              fixed="right"
              render={(text, record, index) => (
                <center key={index}>
                  <Dropdown
                    overlay={
                      <Menu onClick={(e) => this.onAction(e, record)}>
                        <Menu.Item key="edit">
                          <Icon type="edit" /> Editar
                        </Menu.Item>
                        <Menu.Divider />
                        <Menu.Item key="delete">
                          <Icon type="delete" /> Excluir
                        </Menu.Item>
                      </Menu>
                    }
                    trigger={["click"]}
                  >
                    <a className="ant-dropdown-link" href="#">
                      Ações <Icon type="down" />
                    </a>
                  </Dropdown>
                </center>
              )}
            />
          </Table>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const allowedDepartments = getAllowedDepartments(
    state.user,
    state.permissions.list
  );
  const formattedChecklists = state.checklists.list.map((item) => ({
    ...item,
    legalization_percentage: getTaskPercentage(item, "1", allowedDepartments),
    administrative_percentage: getTaskPercentage(item, "2", allowedDepartments),
    accounting_percentage: getTaskPercentage(item, "3", allowedDepartments),
    fiscal_percentage: getTaskPercentage(item, "4", allowedDepartments),
    personal_percentage: getTaskPercentage(item, "5", allowedDepartments),
    cs_percentage: getTaskPercentage(item, "6", allowedDepartments),
    total_percentage: getTaskTotalPercentage(item, allowedDepartments),
  }));

  const lateChecklists = formattedChecklists.filter((item) => {
    return (
      item.total_percentage < 100 &&
      moment(item.due_at).isBefore(moment(), "months")
    );
  });

  const pendingChecklists = formattedChecklists.filter((item) => {
    return (
      item.total_percentage < 100 &&
      moment(item.due_at).isSameOrAfter(moment(), "months")
    );
  });

  const concludedChecklists = formattedChecklists.filter((item) => {
    return item.total_percentage === 100;
  });

  return {
    checklists: {
      late: lateChecklists,
      pending: pendingChecklists,
      concluded: concludedChecklists,
    },
    user: state.user,
    permissions: state.permissions.list,
    allowedDepartments,
    isLoading: state.checklists.isLoading
  };
};

const mapDispatchProps = (dispatch) => {
  return {
    showDrawer: (data) => dispatch(drawerChecklistActions.showDrawer(data)),
    deleteChecklist: (id) => dispatch(checklistActions.deleteChecklist(id)),
  };
};

export default connect(mapStateToProps, mapDispatchProps)(Checklist);
