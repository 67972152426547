import React, { useState } from 'react';
import { Button, Dropdown, Icon, Input, Menu, Modal, Table, Tag, Tooltip } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';

import { Container, Header, HeaderLeftContentWrapper, Main } from './styles';
import { ModuleHeader } from '~/components/ModuleHeader';
import { KnowledgeDrawer } from './components/KnowledgeDrawer';
import * as knowledgeBaseActions from '~/containers/KnowledgeBase/actions';
import { normalizeToFilter } from './utils';
import { getDefaultPaginationConfig } from '~/utils/get-default-pagination-config';
import { STORAGE } from '~/constants/storage.constants';
import Moment from 'react-moment';

const { Column } = Table;

export const KnowledgeBase = () => {
  const dispatch = useDispatch();

  const [filter, setFilter] = useState('');
  const { knowledges, isLoading } = useSelector((state) => ({
    knowledges: state.knowledgeBase.list,
    isLoading: state.knowledgeBase.isLoading,
  }));
  const [knowledgeDrawer, setKnowledgeDrawer] = useState({
    isVisible: false,
    data: null,
  });

  function openKnowledgeDrawer(data = null) {
    setKnowledgeDrawer({
      isVisible: true,
      data,
    });
  }

  function closeKnowledgeDrawer() {
    setKnowledgeDrawer({
      isVisible: false,
      data: null,
    });
  }

  function deleteKnowledge(id) {
    Modal.confirm({
      title: 'Você realmente deseja remover este conhecimento?',
      content: 'Esta ação não poderá ser desfeita.',
      okText: 'Sim',
      okType: 'danger',
      cancelText: 'Cancelar',
      onOk: async () => {
        await dispatch(knowledgeBaseActions.deleteKnowledge(id));
      },
    });
  }

  const filteredKnowledges = knowledges.filter((knowledge) => {
    const titleMatches = normalizeToFilter(knowledge.title).includes(filter);

    const categoryMatches = knowledge.categories
      .map((category) => normalizeToFilter(category))
      .includes(filter);

    return titleMatches || categoryMatches;
  });


  let categories = [];
  if(filteredKnowledges.length){
    categories = filteredKnowledges.reduce((acc, knowledge) => {
      knowledge.categories.forEach((category) => {
        if (!acc.includes(category)) {
          acc.push({ text: category, value: category });
        }
      });
      return acc;
    }, []);
  }

  // sort categories
  categories.sort();

  // remove duplicates
  categories = categories.filter((category, index, self) => {
    return index === self.findIndex((t) => t.text === category.text);
  });

  // add qtdd to categories
  categories = categories.map((category) => {
    const count = filteredKnowledges.filter((knowledge) => knowledge.categories.includes(category.text)).length;
    return { ...category, text: `${category.text} (${count})` };
  });

  return (
    <Container>
      <Header>
        <HeaderLeftContentWrapper>
          <ModuleHeader breadcrumbs={['Base de conhecimento']} />
          <Input
            placeholder="Pesquise pelo título ou categoria"
            onChange={({ target }) =>
              setFilter(normalizeToFilter(target.value))
            }
          />
        </HeaderLeftContentWrapper>
        <Button
          type="primary"
          icon="plus"
          onClick={() => openKnowledgeDrawer()}
        >
          Novo conhecimento
        </Button>
      </Header>
      <Main>
        <Table
          loading={isLoading}
          rowKey="id"
          bordered
          dataSource={filteredKnowledges}
          pagination={getDefaultPaginationConfig({
            storageKey: STORAGE.defaultPageSize.knowledgeBase
          })}
        >
          <Column 
            title="Título" 
            key="title" 
            dataIndex="title" 
            ellipsis
          />
          <Column
            title="Categorias"
            key="categories"
            dataIndex="categories"
            onFilter={(value, record) => record.categories.includes(value)}
            filters={categories}
            render={(categories) => {
              return categories.map((category) => <Tag key={category}>{category}</Tag>);
            }}
          />
          <Column
            title="Criado em"
            key="created_at"
            dataIndex="created_at"
            render={(date) => <Tooltip title={moment(date).format('DD/MM/YYYY HH:mm:ss')}><Moment fromNow>{date}</Moment></Tooltip>}
            sorter={(a, b) => moment(a.created_at).unix() - moment(b.created_at).unix()}
            align="center"
            width={150}
          />
          <Column
            title="Atualizado em"
            key="updated_at"
            dataIndex="updated_at"
            render={(date) => <Tooltip title={moment(date).format('DD/MM/YYYY HH:mm:ss')}><Moment fromNow>{date}</Moment></Tooltip>}
            sorter={(a, b) => moment(a.updated_at).unix() - moment(b.updated_at).unix()}
            align="center"
            width={150}
          />
          <Column
            width={150}
            key="actions"
            align="center"
            render={(_, record) => (
              <Dropdown
                overlay={
                  <Menu>
                    <Menu.Item
                      key="edit"
                      onClick={() => openKnowledgeDrawer(record)}
                    >
                      <Icon type="form"></Icon> Editar
                    </Menu.Item>
                    <Menu.Item
                      key="delete"
                      onClick={() => deleteKnowledge(record.id)}
                    >
                      <Icon type="delete"></Icon> Excluir
                    </Menu.Item>
                  </Menu>
                }
                trigger={['click']}
              >
                <Button type="link">
                  Ações <Icon type="down" />
                </Button>
              </Dropdown>
            )}
          />
        </Table>
      </Main>
      <KnowledgeDrawer
        isVisible={knowledgeDrawer.isVisible}
        data={knowledgeDrawer.data}
        onClose={closeKnowledgeDrawer}
      />
    </Container>
  );
};
