import { Card, Spin } from "antd";
import { find, get } from "lodash";
import React, { useState } from "react";
import { DragDropContext, Droppable } from "react-beautiful-dnd";
import { LeadCard } from "../LeadCard";
import {
  Column,
  ColumnContent,
  Container,
  ExtraBadge,
  ExtraContainer,
  ExtraTotalBadge,
  InfiniteScrollExtraContainer,
  InfiniteScrollStyled,
  LoadingSkeleton,
  LoadingSkeletonContainer,
} from "../styles";

export const LeadColumns = ({
  columns,
  moveLead,
  // fetchQtddByMonth,
  date,
  loadMoreLeads,
  isLoading,
  showDrawer,
}) => {
  const [hasMore, setHasMore] = useState(true);

  const loadMoreData = async (column) => {
    if (column.leads.length === column.total) {
      setHasMore(false);
      return;
    }
    await loadMoreLeads({
      columnId: column.id,
      currentCount: column.leads.length,
      moreCount: 50,
    });
  };

  const onDragEnd = async (result) => {
    const { source, destination } = result;
    if (!destination) {
      return;
    }

    if (source.droppableId !== destination.droppableId) {
      await moveLead(
        get(find(columns, { id: source.droppableId }), "leads", []),
        get(find(columns, { id: destination.droppableId }), "leads", []),
        source,
        destination,
        result.draggableId
      );

      // await fetchQtddByMonth(date);
    }
  };

  return (
    <Container>
      <DragDropContext onDragEnd={onDragEnd}>
        {columns.map((column) => (
          <Column key={column.id}>
            <Droppable droppableId={`${column.id}`}>
              {(provided) => (
                <Card
                  title={column.name}
                  bodyStyle={{ padding: 0 }}
                  headStyle={column.style}
                  extra={
                    <ExtraContainer>
                      {column.selectedMonth !== null && (
                        <ExtraBadge
                          count={column.selectedMonth}
                          overflowCount={9999}
                        />
                      )}
                      <ExtraTotalBadge
                        count={column.total}
                        overflowCount={9999}
                      />
                    </ExtraContainer>
                  }
                >
                  <ColumnContent ref={provided.innerRef}>
                    <InfiniteScrollStyled
                      style={{
                        overflowY: isLoading ? "hidden" : "initial",
                      }}
                      dataLength={column.leads.length}
                      next={() => loadMoreData(column)}
                      hasMore={hasMore}
                      height={window.innerHeight - 380}
                      scrollThreshold={1}
                      loader={
                        <InfiniteScrollExtraContainer>
                          <Spin />
                        </InfiniteScrollExtraContainer>
                      }
                    >
                      {isLoading
                        ? Array(10)
                            .fill(1)
                            .map((_, index) => (
                              <LoadingSkeletonContainer key={index}>
                                <LoadingSkeleton />
                              </LoadingSkeletonContainer>
                            ))
                        : column.leads
                            .sort((a, b) => {
                              if (column.id !== 1) return 0;
                              return (
                                a.conversation_started - b.conversation_started
                              );
                            })
                            .map((item, index) => (
                              <LeadCard
                                key={index}
                                draggableId={item.id}
                                index={index}
                                item={item}
                                showDrawer={showDrawer}
                                columnId={column.id}
                              />
                            ))}
                      {provided.placeholder}
                    </InfiniteScrollStyled>
                  </ColumnContent>
                </Card>
              )}
            </Droppable>
          </Column>
        ))}
      </DragDropContext>
    </Container>
  );
};
