/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import {
  Layout,
  Menu,
  Icon,
  Dropdown,
  Button,
  Popover,
  Badge,
  Progress,
  Tooltip,
  Tag
} from "antd";
import { WhatsAppOutlined } from "@ant-design/icons";
import { useSelector, useDispatch } from "react-redux";
import moment from 'moment';

import { Auth } from "./Auth";
import logo from "./images/icon.png";
import logo2 from "./images/contlabs_white.png";

import "./layout.css";
import { getAllGraphPermissions } from "~/utils/permissions";
import { department } from "~/components/RegisterTasks/Utils";
import {
  corporatePaths,
  financialPaths,
  fiscalMeshPaths,
  monthlyTasksPaths,
  reportsPaths,
  tasksPaths,
} from "./paths";
import { getSubdomain } from "~/helpers/getSubdomain";
import { ROUTES } from "~/constants/routes.constants";
import { removeNonNumbers } from "~/utils/formatters";
import * as onboardingActions from "~/components/Onboarding/actions";
import { PERMISSIONS } from "~/constants/permissions.constants";
import { getOnboardingSteps } from '~/utils/get-onboarding-steps';
import { VideosAvatars } from '../VideosAvatars';
import Version from './Version';

const { Header, Content, Sider } = Layout;
const SubMenu = Menu.SubMenu;

const Main = ({ children }) => {
  const location = useLocation();
  const dispatch = useDispatch();

  const {
    user,
    links,
    linkCategories,
    onboardingStep,
    isOnboardingActive,
    isVideoModalVisible,
    lastCreatedTask,
    failedEmailsCount
  } = useSelector((state) => ({
    user: state.user,
    links: state.links.list,
    linkCategories: state.links.categories,
    onboardingStep: state.onboarding.step,
    isVideoModalVisible: state.onboarding.videoModal.isVisible,
    isOnboardingActive: state.onboarding.isActive,
    failedEmailsCount: state.emailsLogs.failed,
    lastCreatedTask: [...state.tasks.list]
      .sort((a, b) => moment(b.created_at).diff(a.created_at))
      .at(0)
  }));

  const [isCollapsed, setIsCollapsed] = useState(false);
  const [isDropdownVisible, setIsDropdownVisible] = useState(false);
  const [openSubMenus, setOpenSubMenus] = useState(getDefaultOpenSubMenus());

  const isPremiumPlan = user.tenant.plan === 'premium';

  const activeLinks = links.filter((link) => link.show_in_menu);

  const activeLinkCategories = linkCategories
    .filter((category) => category.show_in_menu)
    .map((category) => {
      const currentLinks = links.filter(
        (link) => link.link_category_id === category.id
      );
      return {
        ...category,
        currentLinks: currentLinks,
      };
    });

  const canViewLinks = user.permissions
    ? user.permissions.includes(PERMISSIONS.links.view)
    : false;

  const isFirstOnboardingStepActive = checkIfStepIsActive(1);
  const isFifthOnboardingStepActive = checkIfStepIsActive(5);

  const { finishedStepsCount, allStepsCount } = getOnboardingSteps(user)
  const onboardingProgress = Math.round((finishedStepsCount * 100) / allStepsCount);

  useEffect(() => {
    if (
      location.search.includes("?file=") &&
      (location.pathname === ROUTES["/"] ||
        location.pathname === ROUTES["/login"])
    ) {
      return (window.location.href = `http://${getSubdomain()}.${
        process.env.REACT_APP_CUSTOMER_DOMAIN
      }/cliente/${location.search}`);
    }
  }, []);

  useEffect(() => {
    if (checkIfLeastStepIsActive([3, 4])) {
      setOpenSubMenus([...openSubMenus, "tarefas"]);
    } else if (checkIfStepIsActive(5)) {
      if (lastCreatedTask) {
        if (Number(lastCreatedTask.department) === 1) {
          setOpenSubMenus([...openSubMenus, "societario"]);
        } else {
          setOpenSubMenus([...openSubMenus, "tarefas", "tarefas-do-mes"]);
        }
      }
    } else if (checkIfStepIsActive(9)) {
      setOpenSubMenus([...openSubMenus, "societario"]);
    } else if (checkIfLeastStepIsActive([11, 12])) {
      setOpenSubMenus([...openSubMenus, "financeiro"]);
    } else if (isOnboardingActive) {
      setOpenSubMenus(getDefaultOpenSubMenus());
    }
  }, [onboardingStep, lastCreatedTask]);

  function checkIfStepIsActive(step) {
    return !isVideoModalVisible && isOnboardingActive && onboardingStep === step;
  }

  function checkIfLeastStepIsActive(steps) {
    return !isVideoModalVisible && isOnboardingActive && steps.includes(onboardingStep);
  }

  function onCollapse(collapsed) {
    setIsCollapsed(collapsed);
  }

  function toggleCollapse() {
    if (!isCollapsed) {
      setOpenSubMenus([]);
    }
    setIsCollapsed(!isCollapsed);
  }

  function getDefaultOpenSubMenus() {
    const currentPath = location.pathname;

    if (Object.values(tasksPaths).includes(currentPath)) {
      return ["tarefas"];
    }

    if (Object.values(monthlyTasksPaths).includes(currentPath)) {
      return ["tarefas", "tarefas-do-mes"];
    }

    if (Object.values(financialPaths).includes(currentPath)) {
      return ["financeiro"];
    }

    if (Object.values(corporatePaths).includes(currentPath)) {
      return ["societario"];
    }

    if (Object.values(fiscalMeshPaths).includes(currentPath)) {
      return ["societario", "malha-fiscal"];
    }

    if (Object.values(reportsPaths).includes(currentPath)) {
      return ["relatorios"];
    }

    return [];
  }

  function renderMonthlyTaskDepartment({ component, departmentId }) {
    if (!isFifthOnboardingStepActive) {
      return component;
    }

    const departmentFromLocation = Number(removeNonNumbers(location.pathname));

    if (departmentId === departmentFromLocation) {
      return component;
    }

    if (!lastCreatedTask) return component;

    if (Number(lastCreatedTask.department) !== departmentId) {
      return component;
    }

    return (
      <Popover
        placement="right"
        content={departmentId === 1
          ? 'Clique aqui para abrir o módulo Tarefas'
          : `Clique aqui para abrir o módulo Tarefas do mês / ${department(departmentId)}`
        }
        visible
      >
        {component}
      </Popover>
    );
  }

  return (
    <Auth>
      {user.permissions && (
        <Layout
          style={
            isOnboardingActive
              ? { minHeight: "100vh", float: "left", width: "83%" }
              : { minHeight: "100vh" }
          }
        >
          <Sider
            collapsible
            collapsed={isCollapsed}
            onCollapse={onCollapse}
            trigger={null}
            width={250}
            style={{
              overflow: "auto",
              height: "100vh",
              position: "fixed",
              left: 0,
              zIndex: checkIfLeastStepIsActive([
                2,
                3,
                4,
                5,
                6,
                7,
                8,
                9,
                10,
                11,
                12,
              ])
                ? 999
                : 990,
              transition: "left 0.3s",
            }}
          >
            <center style={{ backgroundColor: "#092138" }}>
              {isCollapsed ? (
                <img
                  src={logo}
                  className="logo"
                  alt="ContLabs"
                  style={{ height: "30px", margin: "16px" }}
                />
              ) : (
                  <div style={{position: 'relative'}}>
                    <img
                      src={logo2}
                      className="logo"
                      alt="ContLabs"
                      style={{ height: "30px", margin: "15px" }}
                    />
                </div>
              )}
            </center>
            <Menu
              theme="dark"
              selectedKeys={[location.pathname]}
              openKeys={openSubMenus}
              onOpenChange={(openKeys) => setOpenSubMenus(openKeys)}
              mode="inline"
            >
              {user.permissions.some((permission) =>
                getAllGraphPermissions().includes(permission)
              ) && (
                <Menu.Item key="/">
                  <Link to="/">
                    <Icon type="dashboard" /> <span>Dashboard</span>
                  </Link>
                </Menu.Item>
              )}
              {user.permissions.includes(PERMISSIONS.customers.view) && (
                <Menu.Item key="/clientes">
                  <Popover
                    placement="right"
                    content="Clique aqui para abrir o módulo Clientes"
                    visible={
                      checkIfLeastStepIsActive([2, 10]) &&
                      location.pathname !== ROUTES["/clientes"]
                    }
                  >
                    <Link to="/clientes">
                      <Icon type="team" /> <span>Clientes</span>
                    </Link>
                  </Popover>
                </Menu.Item>
              )}
              {user.permissions.includes(PERMISSIONS.calendarTasks.view) && (
                <SubMenu
                  key="tarefas"
                  title={
                    <span>
                      <Icon type="schedule" />
                      <span>Tarefas</span>
                    </span>
                  }
                >
                  {user.permissions.includes(
                    PERMISSIONS.calendarTasks.create
                  ) && (
                    <Menu.Item key={tasksPaths["tarefas"]}>
                      <Popover
                        placement="right"
                        content="Clique aqui para abrir o módulo Cadastro de Tarefas"
                        visible={
                          checkIfLeastStepIsActive([3, 4]) &&
                          location.pathname !== ROUTES["/tarefas"]
                        }
                      >
                        <Link to={tasksPaths["tarefas"]}>
                          Cadastro de tarefas
                        </Link>
                      </Popover>
                    </Menu.Item>
                  )}
                  {isPremiumPlan && user.permissions.includes(PERMISSIONS.checklist.view) && (
                    <Menu.Item key={tasksPaths["checklist"]}>
                      <Link to={tasksPaths["checklist"]}>Checklist</Link>
                    </Menu.Item>
                  )}
                  {isPremiumPlan && user.permissions.includes(PERMISSIONS.conventions.view) && (
                    <Menu.Item key={tasksPaths["convencoes"]}>
                      <Link to={tasksPaths["convencoes"]}>
                        Convenções coletiva
                      </Link>
                    </Menu.Item>
                  )}
                  {user.permissions.includes(
                    PERMISSIONS.calendarTasks.view
                  ) && (
                    <SubMenu key="tarefas-do-mes" title="Tarefas do mês">
                      <Menu.Item key={monthlyTasksPaths["administrativo"]}>
                        {renderMonthlyTaskDepartment({
                          component: (
                            <Link to={monthlyTasksPaths["administrativo"]}>
                              Administrativo
                            </Link>
                          ),
                          departmentId: 2,
                        })}
                      </Menu.Item>
                      <Menu.Item key={monthlyTasksPaths["contabil"]}>
                        {renderMonthlyTaskDepartment({
                          component: (
                            <Link to={monthlyTasksPaths["contabil"]}>
                              Contábil
                            </Link>
                          ),
                          departmentId: 3,
                        })}
                      </Menu.Item>
                      <Menu.Item key={monthlyTasksPaths["fiscal"]}>
                        {renderMonthlyTaskDepartment({
                          component: (
                            <Link to={monthlyTasksPaths["fiscal"]}>Fiscal</Link>
                          ),
                          departmentId: 4,
                        })}
                      </Menu.Item>
                      <Menu.Item key={monthlyTasksPaths["pessoal"]}>
                        {renderMonthlyTaskDepartment({
                          component: (
                            <Link to={monthlyTasksPaths["pessoal"]}>
                              Pessoal
                            </Link>
                          ),
                          departmentId: 5,
                        })}
                      </Menu.Item>
                      <Menu.Item key={monthlyTasksPaths["auditoria"]}>
                        {renderMonthlyTaskDepartment({
                          component: (
                            <Link to={monthlyTasksPaths["auditoria"]}>
                              Sucesso do Cliente (CS)
                            </Link>
                          ),
                          departmentId: 6,
                        })}
                      </Menu.Item>
                    </SubMenu>
                  )}
                  {isPremiumPlan && user.permissions.includes(PERMISSIONS.irpf.view) && (
                    <Menu.Item key={tasksPaths["irpf"]}>
                      <Link to={tasksPaths["irpf"]}>IRPF</Link>
                    </Menu.Item>
                  )}
                </SubMenu>
              )}
              {user.permissions.some((permission) =>
                [
                  PERMISSIONS.financial.monthlyPayments.view,
                  PERMISSIONS.extraServices.view,
                  PERMISSIONS.refunds.view,
                ].includes(permission)
              ) && (
                <SubMenu
                  key="financeiro"
                  title={
                    <span>
                      <Icon type="calculator" />
                      <span>Financeiro</span>
                    </span>
                  }
                >
                  {user.permissions.includes(
                    PERMISSIONS.financial.monthlyPayments.view
                  ) && (
                    <Menu.Item key={financialPaths["mensalidades"]}>
                      <Popover
                        placement="right"
                        content="Clique aqui para abrir o módulo Mensalidades"
                        visible={
                          checkIfLeastStepIsActive([11, 12]) &&
                          location.pathname !==
                            ROUTES["/financeiro/mensalidades"]
                        }
                      >
                        <Link to={financialPaths["mensalidades"]}>
                          Mensalidades
                        </Link>
                      </Popover>
                    </Menu.Item>
                  )}
                  {isPremiumPlan && user.permissions.includes(
                    PERMISSIONS.extraServices.view
                  ) && (
                    <Menu.Item key={financialPaths["extras"]}>
                      <Link to={financialPaths["extras"]}>Serviços extras</Link>
                    </Menu.Item>
                  )}
                  {isPremiumPlan && user.permissions.includes(PERMISSIONS.refunds.view) && (
                    <Menu.Item key={financialPaths["comissoes"]}>
                      <Link to={financialPaths["comissoes"]}>Comissões</Link>
                    </Menu.Item>
                  )}
                </SubMenu>
              )}
              {isPremiumPlan && user.permissions.includes(PERMISSIONS.corporate.view) && (
                <SubMenu
                  key="societario"
                  title={
                    <span>
                      <Icon type="file-sync" />
                      <span>Dep. Societário</span>
                    </span>
                  }
                >
                  {user.permissions.includes(PERMISSIONS.corporate.view) && (
                    <Menu.Item key={corporatePaths["societario"]}>
                      <Popover
                        placement="right"
                        content="Clique para entrar no módulo Tarefas"
                        visible={
                          checkIfStepIsActive(9) &&
                          location.pathname !== ROUTES["/societario"]
                        }
                      >
                        <Link to={corporatePaths["societario"]}>Painel</Link>
                      </Popover>
                    </Menu.Item>
                  )}
                  {user.permissions.includes(PERMISSIONS.calendarTasks.view) && (
                    <Menu.Item key={corporatePaths["legalizacao"]}>
                      {renderMonthlyTaskDepartment({
                        component: (
                          <Link to={corporatePaths["legalizacao"]}>
                            Tarefas
                          </Link>
                        ),
                        departmentId: 1,
                      })}
                    </Menu.Item>
                  )}
                  {user.permissions.includes(PERMISSIONS.certificates.view) && (
                    <Menu.Item key={corporatePaths["certificados"]}>
                      <Link to={corporatePaths["certificados"]}>
                        Certificados digitais
                      </Link>
                    </Menu.Item>
                  )}
                  {user.permissions.includes(PERMISSIONS.procurations.view) && (
                    <Menu.Item key={corporatePaths["lembretes"]}>
                      <Link to={corporatePaths["lembretes"]}>
                        Lembretes
                      </Link>
                    </Menu.Item>
                  )}
                  {user.permissions.includes(
                    PERMISSIONS.irpf.malhaFina.view
                  ) && (
                    <SubMenu key="malha-fiscal" title="Malha fiscal">
                      <Menu.Item key={fiscalMeshPaths["federal"]}>
                        <Link to={fiscalMeshPaths["federal"]}>Federal</Link>
                      </Menu.Item>
                      <Menu.Item key={fiscalMeshPaths["estadual"]}>
                        <Link to={fiscalMeshPaths["estadual"]}>Estadual</Link>
                      </Menu.Item>
                      <Menu.Item key={fiscalMeshPaths["municipal"]}>
                        <Link to={fiscalMeshPaths["municipal"]}>Municipal</Link>
                      </Menu.Item>
                      <Menu.Item key={fiscalMeshPaths["fgts"]}>
                        <Link to={fiscalMeshPaths["fgts"]}>FGTS</Link>
                      </Menu.Item>
                      <Menu.Item key={fiscalMeshPaths["irpf"]}>
                        <Link to={fiscalMeshPaths["irpf"]}>IRPF</Link>
                      </Menu.Item>
                    </SubMenu>
                  )}
                </SubMenu>
              )}
              {(user.permissions.includes(PERMISSIONS.documents.view) 
                || user.permissions.includes(PERMISSIONS.sentEmails.view)) && (
                  <SubMenu
                      key="sent-items"
                      title={
                          <span>
                            <Icon type="cloud-upload" />
                            <span>Itens Enviados</span> 
                            {user.permissions.includes(PERMISSIONS.sentEmails.view) && (
                              <Link to={reportsPaths["failedEmails"]}>
                                <Tooltip title={`${failedEmailsCount} e-mails falharam`}>
                                  <Badge size="small" count={failedEmailsCount} title={`${failedEmailsCount} e-mails falharam`} style={{zoom: '0.8', marginLeft: '10px'}} />
                                </Tooltip>
                              </Link>
                            )}
                          </span>
                      }
                    >
                    {user.permissions.includes(PERMISSIONS.documents.view) && (
                      <Menu.Item key="/documentos">
                        <Link to="/documentos">
                          <Icon type="file" /> <span>Documentos enviados</span>
                        </Link>
                      </Menu.Item>
                    )}
                    {user.permissions.includes(PERMISSIONS.sentEmails.view) && (
                      <Menu.Item key={reportsPaths["emails"]}>
                        <Link to={reportsPaths["emails"]}>
                          <Icon type="mail" />E-mails enviados
                        </Link>
                      </Menu.Item>
                    )}
                  </SubMenu>
              )}
              {isPremiumPlan && user.permissions.includes(PERMISSIONS.leads.view) && (
                <Menu.Item key="/leads">
                  <Popover
                    placement="right"
                    content="Clique aqui para abrir o módulo Leads"
                    visible={
                      checkIfLeastStepIsActive([6, 7, 8]) &&
                      location.pathname !== ROUTES["/leads"]
                    }
                  >
                    <Link to="/leads">
                      <Icon type="notification" /> <span>Leads</span>
                    </Link>
                  </Popover>
                </Menu.Item>
              )}
               {isPremiumPlan && user.permissions.includes(PERMISSIONS.leadsRT.view) && (
                <Menu.Item key="/recuperacao-tributaria">
                  <Link to="/recuperacao-tributaria">
                    <Icon type="reconciliation" /> <span>RT - Recuperação Tributária</span>
                  </Link>
                </Menu.Item>
              )}

              {isPremiumPlan && user.permissions.some((permission) =>
                [
                  PERMISSIONS.documentsDeadline.view,
                  PERMISSIONS.honoraries.view,
                  PERMISSIONS.sentEmails.view,
                  PERMISSIONS.feedbacks.view
                ].includes(permission)
              ) && (
                <SubMenu
                  key="relatorios"
                  title={
                    <span>
                      <Icon type="book" />
                      <span>Relatórios</span>
                    </span>
                  }
                >
                  {user.permissions.includes(PERMISSIONS.reports.defaulters) && (
                    <Menu.Item key={ROUTES['/relatorios/inadimplentes']}>
                      <Link to={ROUTES['/relatorios/inadimplentes']}>Inadimplentes</Link>
                    </Menu.Item>
                  )}
                  {user.permissions.includes(PERMISSIONS.documentsDeadline.view) && (
                    <Menu.Item key={reportsPaths["prazos"]}>
                      <Link to={reportsPaths["prazos"]}>Prazos</Link>
                    </Menu.Item>
                  )}
                  {user.permissions.includes(PERMISSIONS.honoraries.view) && (
                    <Menu.Item key={reportsPaths["honoraries"]}>
                      <Link to={reportsPaths["honoraries"]}>Controle de honorários</Link>
                    </Menu.Item>
                  )}
                  {user.permissions.includes(PERMISSIONS.feedbacks.view) && (
                    <Menu.Item key={reportsPaths["feedbacks"]}>
                      <Link to={reportsPaths["feedbacks"]}>KPIs</Link>
                    </Menu.Item>
                  )}
                </SubMenu>
              )}

              {isPremiumPlan && user.permissions.includes(PERMISSIONS.plugins.view) && (
                <Menu.Item key="/plugins">
                  <Link to="/plugins">
                    <Icon type="plus-circle" /> <span>Plugins</span>
                  </Link>
                </Menu.Item>
              )}
              {isPremiumPlan && user.permissions.includes(PERMISSIONS.knowledgeBase.view) && (
                <Menu.Item key="/base-de-conhecimento">
                  <Link to="/base-de-conhecimento">
                    <Icon type="exclamation-circle" /> <span>Base de conhecimento</span>
                  </Link>
                </Menu.Item>
              )}
              {isPremiumPlan && user.permissions.includes(PERMISSIONS.boards.view) && (
                <Menu.Item key="/boards">
                  <Link to="/boards">
                    <Icon type="project" /> <span>Marketing</span>
                  </Link>
                </Menu.Item>
              )}
              {/* {isPremiumPlan && user.permissions.includes(PERMISSIONS.referralProgram.view) && (
                <Menu.Item key="/indicacao">
                  <Link to="/indicacao">
                    <Icon type="gift" /> <span>Indicação premiada</span>
                  </Link>
                </Menu.Item>
              )} */}

              {isPremiumPlan && canViewLinks && (
                <Menu.Item key="/links" style={{marginTop: '50px'}}>
                  <Link to="/links" style={{borderTop: 'solid 1px rgb(0 33 64)'}}>
                    <Icon type="link" /> <span>Links de atalho</span>
                  </Link>
                </Menu.Item>
              )}

              {isPremiumPlan && canViewLinks &&
                activeLinkCategories.map((category) => (
                  <SubMenu
                    key={category.id}
                    title={
                      <span>
                        <Icon type="link" />
                        <span>{category.name}</span>
                      </span>
                    }
                  >
                    {category.currentLinks.map((link) => (
                      <Menu.Item key={link.id}>
                        <a
                          href={link.address}
                          key={link.id}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          <img
                            src={`http://www.google.com/s2/favicons?domain=${link.address}`}
                            alt="Ícone do site"
                            width={14}
                            height={14}
                            style={{ marginRight: 10 }}
                          />
                          {link.title}
                        </a>
                      </Menu.Item>
                    ))}
                  </SubMenu>
                ))}

              {isPremiumPlan && canViewLinks &&
                activeLinks.map((link) => (
                  <Menu.Item key={link.id} title={link.title}>
                    <a
                      href={link.address}
                      key={link.id}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <img
                        src={`http://www.google.com/s2/favicons?domain=${link.address}`}
                        alt="Ícone do site"
                        width={14}
                        height={14}
                        style={{ marginRight: 10 }}
                      />
                      {!isCollapsed && link.title}
                    </a>
                  </Menu.Item>
                ))}

            </Menu>
            <div className="whatsapp-support-button-container">
              <Button
                type="primary"
                target="_blank"
                href="https://api.whatsapp.com/send?phone=5548991191788"
                className="whatsapp-support-button"
              >
                <WhatsAppOutlined />
                {!isCollapsed && "Suporte"}
              </Button>
              <Version />
            </div>
          </Sider>
          <Layout
            style={{
              marginLeft: `${isCollapsed ? "80px" : "250px"}`,
              transition: "margin-left 0.3s",
            }}
          >
            <Header
              id="header-main"
              style={{
                background: "#fff",
                padding: 0,
                boxShadow: `0px 1px 8px 0px rgba(0,0,0,0.1)`,
                zIndex: `989`,
                transition: "height 0.3s",
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <div>
              <Icon
                className="trigger"
                type={isCollapsed ? "menu-unfold" : "menu-fold"}
                onClick={toggleCollapse}
              />
              </div>
              <div style={{ display: "flex", gap: 20, alignItems: "center" }}>
                <VideosAvatars />
                <div
                  style={{ display: "flex", gap: 4, flexDirection: "column" }}
                >
                  <Badge dot={finishedStepsCount === 0}>
                    <Button
                      type="primary"
                      onClick={() =>
                        dispatch(onboardingActions.openOnboarding())
                      }
                    >
                      Tutorial
                    </Button>
                  </Badge>
                  <Progress
                    style={{ lineHeight: "7px" }}
                    size="small"
                    showInfo={false}
                    percent={onboardingProgress}
                  />
                </div>
                <Dropdown
                  visible={
                    (isFirstOnboardingStepActive &&
                      location.pathname !== ROUTES["/usuarios"]) ||
                    isDropdownVisible
                  }
                  placement="bottomRight"
                  onVisibleChange={(visible) => {
                    if (
                      isFirstOnboardingStepActive &&
                      location.pathname !== ROUTES["/usuarios"]
                    ) {
                      return;
                    }

                    setIsDropdownVisible(visible);
                  }}
                  className="cl-menu-main-right"
                  overlay={
                    <Menu>
                      <Menu.Item key="/conta">
                        <Link to="/conta">
                          <Icon type="user" /> Minha Conta
                        </Link>
                      </Menu.Item>
                      {user.permissions.includes(PERMISSIONS.users.view) && (
                        <Menu.Item key="/usuarios">
                          <Popover
                            content="Clique aqui para acessar o módulo Usuários"
                            visible={isFirstOnboardingStepActive}
                            placement="left"
                          >
                            <Link to="/usuarios">
                              <Icon type="team" /> Gerenciar Usuários
                            </Link>
                          </Popover>
                        </Menu.Item>
                      )}
                      {user.permissions.includes(PERMISSIONS.users.view) && (
                        <Menu.Item key="/permissoes">
                          <Link to="/permissoes">
                            <Icon type="key" /> Gerenciar Permissões
                          </Link>
                        </Menu.Item>
                      )}
                      <Menu.Item key="/whatsapp-support">
                        <a
                          target="_blank"
                          rel="noopener noreferrer"
                          href="https://api.whatsapp.com/send?phone=5548991191788"
                        >
                          <WhatsAppOutlined /> Suporte via Whatsapp
                        </a>
                      </Menu.Item>
                      <Menu.Divider />
                      <Menu.Item key="/sair">
                        <Link to="/sair">
                          <Icon type="logout" /> Sair
                        </Link>
                      </Menu.Item>
                      <Menu.Divider />
                      {isPremiumPlan ? (
                        <Menu.Item key="/">
                          <center>
                            <Tag color="#722ed1">Plano Premium</Tag>
                          </center>
                        </Menu.Item>
                      ): (
                        <Menu.Item key="/">
                          <center>
                            <Tag color="#1890ff">Plano Starter</Tag>
                          </center>
                        </Menu.Item>
                      )}
                    </Menu>
                  }
                >
                  <a className="ant-dropdown-link" href="#">
                    {/* <Avatar style={{ backgroundColor: '#722ed1' }} icon="user" size="small"/>  */}
                    {user.name} <Icon type="down" />
                  </a>
                </Dropdown>
              </div>
            </Header>
            <Content style={{ margin: `0px 16px` }}>{children}</Content>
          </Layout>
        </Layout>
      )}
    </Auth>
  );
};

export default Main;
