import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Table, Row, Col, Select } from 'antd';
import moment from 'moment';

import * as pluginsActions from "~/containers/Plugins/actions";

const { Column } = Table;
const { Option } = Select;

const TableReports = ({settingsAcquisition, source, loading}) => {
    return (
        <div>
            <Table
                rowKey="id"
                dataSource={source}
                bordered
                pagination={false}
                className="mb-20"
                scroll={{ x: 1500 }}
                loading={loading}
            >
                <Column
                    title="Origem" 
                    dataIndex="acquisition_id" 
                    key="acquisition_id" 
                    ellipsis={true}
                    width={300} 
                    fixed="left"
                    filterMultiple={true}
                    filters={settingsAcquisition.map((item) => ({ text: item.value, value: item.id }))}
                    onFilter={(value, record) => record.acquisition_id === value}
                    render={(acquisition_id) => {
                        const acquisition = settingsAcquisition.find((item) => parseInt(item.id) === parseInt(acquisition_id));
                        return acquisition ? acquisition.value : '';
                    }}
                />
                <Column
                    title="Janeiro"
                    dataIndex="reports.january"
                    key="reports.january"
                    align="center"
                    width={100}
                    ellipsis={true}
                />
                <Column
                    title="Fevereiro"
                    dataIndex="reports.february"
                    key="reports.february"
                    align="center"
                    width={100}
                    ellipsis={true}
                />
                <Column
                    title="Março"
                    dataIndex="reports.march"
                    key="reports.march"
                    align="center"
                    width={100}
                    ellipsis={true}
                />
                <Column
                    title="Abril"
                    dataIndex="reports.april"
                    key="reports.april"
                    align="center"
                    width={100}
                    ellipsis={true}
                />
                <Column
                    title="Maio"
                    dataIndex="reports.may"
                    key="reports.may"
                    align="center"
                    width={100}
                    ellipsis={true}
                />
                <Column
                    title="Junho"
                    dataIndex="reports.june"
                    key="reports.june"
                    align="center"
                    width={100}
                    ellipsis={true}
                />
                <Column
                    title="Julho"
                    dataIndex="reports.july"
                    key="reports.july"
                    align="center"
                    width={100}
                    ellipsis={true}
                />
                <Column
                    title="Agosto"
                    dataIndex="reports.august"
                    key="reports.august"
                    align="center"
                    width={100}
                    ellipsis={true}
                />
                <Column
                    title="Setembro"
                    dataIndex="reports.september"
                    key="reports.september"
                    align="center"
                    width={100}
                    ellipsis={true}
                />
                <Column
                    title="Outubro"
                    dataIndex="reports.october"
                    key="reports.october"
                    align="center"
                    width={100}
                    ellipsis={true}
                />
                <Column
                    title="Novembro"
                    dataIndex="reports.november"
                    key="reports.november"
                    align="center"
                    width={100}
                    ellipsis={true}
                />
                <Column
                    title="Dezembro"
                    dataIndex="reports.december"
                    key="reports.december"
                    align="center"
                    width={100}
                    ellipsis={true}
                />
                <Column
                    title="Total"
                    dataIndex="reports.total"
                    key="reports.total"
                    align="center"
                    width={100}
                    ellipsis={true}
                    fixed="right"
                />
            </Table>
        </div>
    );
}

const Reports = () => {
    const dispatch = useDispatch();
    const settingsAcquisition = useSelector((state) => state.settings["leads.acquisition"]);
    const [source, setSource] = useState([]);
    const [year, setYear] = useState(moment().year());
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const fetchData = async () => {
            try {
                setLoading(true);
                const result = await dispatch(pluginsActions.loadReportsFromCountClicks(year));
                setSource(result.payload.data);
                setLoading(false);
            } catch (error) {
                console.log(error);
            }
        };

        fetchData();
    }, [year]);
   

    return (
        <div>
            <Row>
                <Col span={24} style={{ textAlign: 'right' }} className="mb-20">
                    <Select 
                        defaultValue={year} 
                        style={{ width: 120 }} 
                        onChange={(value) => setYear(value)}
                    >
                        {[...Array(5).keys()].map((item) => {
                            return (
                                <Option key={moment().year() - item} value={moment().year() - item}>
                                    {moment().year() - item}
                                </Option>
                            );
                        })}
                    </Select>
                </Col>
            </Row>
            <TableReports 
                settingsAcquisition={settingsAcquisition} 
                source={source} 
                loading={loading}
            />
        </div>
    );
}

export default Reports;