import React from 'react';
import { Button, Icon } from 'antd';
import moment from 'moment';

import { toBRL } from '~/components/UI/InputCurrency';
import { 
    taxationTypes, 
    necessityOptions, 
    necessityOptionsArray, 
} from '~/containers/Customer/data';

export const leadsTable = {
    getColumns: function({columns, showDrawer}) {
        return [
            {
                title: 'Cadastro',
                dataIndex: 'created_at',
                key: 'created_at',
                className: 'text-center',
                sortDirections: ['descend', 'ascend'],
                defaultSortOrder: 'descend',
                sorter: (a, b) => moment(a.created_at).unix() - moment(b.created_at).unix(),
                render: (created_at) => moment(created_at).format('DD/MM/YYYY'),
                width: 150,
                ellipsis: true,
            },
            {
                title: 'Lead',
                dataIndex: 'name',
                key: 'name',
                width: 400,
                ellipsis: true,
            },
            {
                title: 'Tags',
                dataIndex: 'tags',
                key: 'tags',
                width: 200,
                ellipsis: true,
                render: (tags) => {
                    if(!tags) return '';
                    // check if tags is a string
                    if(typeof tags === 'string') {
                        return JSON.parse(tags).join(', ');
                    }
                    // check if tags is an array
                    if(Array.isArray(tags)) {
                        return tags.join(', ');
                    }
                }
            },
            {
                title: 'Origem da aquisição',
                dataIndex: 'acquisition_id_text',
                key: 'acquisition_id_text',
                width: 150,
                ellipsis: true,
            },
            {
                title: 'Necessidade',
                dataIndex: 'necessity',
                key: 'necessity',
                width: 250,
                ellipsis: true,
                render: (necessity) => necessityOptions[necessity],
                filters: necessityOptionsArray,
                filterMultiple: true,
                onFilter: (value, record) => record.necessity === value,
            },
            {
                title: 'Atividade',
                dataIndex: 'company_type_id_text',
                key: 'company_type_id_text',
                width: 150,
                ellipsis: true,
            },
            {
                title: 'Funcionários',
                dataIndex: 'n_staffs',
                key: 'n_staffs',
                width: 150,
                ellipsis: true,
            },
            {
                title: 'Faturamento',
                dataIndex: 'billing',
                key: 'billing',
                width: 150,
                ellipsis: true,
                render: (value) => toBRL(value),
            },
            {
                title: 'Cidade',
                dataIndex: 'city',
                key: 'city',
                width: 150,
                ellipsis: true,
            },
            {
                title: 'Estado',
                dataIndex: 'state',
                key: 'state',
                width: 150,
                ellipsis: true,
            },
            {
                title: 'Abertura',
                dataIndex: 'first_honorary',
                key: 'first_honorary',
                width: 150,
                ellipsis: true,
                render: (value) => toBRL(value),
            },
            {
                title: 'Mensalidade',
                dataIndex: 'honorary',
                key: 'honorary',
                width: 150,
                ellipsis: true,
                render: (value) => toBRL(value),
            },
            {
                title: 'Último honorário pago',
                dataIndex: 'last_accounting_honorary',
                key: 'last_accounting_honorary',
                width: 150,
                ellipsis: true,
                render: (value) => toBRL(value),
            },
            {
                title: 'Regime tributário',
                dataIndex: 'type_taxation',
                key: 'type_taxation',
                width: 200,
                ellipsis: true,
                filters: taxationTypes.map(taxation => ({ text: taxation, value: taxation })),
                filterMultiple: true,
                onFilter: (value, record) => record.type_taxation === value,
            },
            {
                title: 'Proposta enviada?',
                dataIndex: 'proposal_sent',
                key: 'proposal_sent',
                width: 150,
                ellipsis: true,
                filters: [ { text: 'Sim', value: 'Sim' }, { text: 'Não', value: 'Não' } ],
                filterMultiple: true,
                onFilter: (value, record) => record.proposal_sent === value,
            },
            {
                title: 'Motivo da perda',
                dataIndex: 'lost_reason_text',
                key: 'lost_reason_text',
                width: 200,
                ellipsis: true,
            },
            {
                title: 'Revisado?',
                dataIndex: 'is_revised',
                key: 'is_revised',
                width: 100,
                ellipsis: true,
                render: (value) => value ? 'Sim' : 'Não',
            },
            {
                title: 'Email',
                dataIndex: 'email',
                key: 'email',
                width: 300,
                ellipsis: true,
            },
            {
                title: 'Descrição',
                dataIndex: 'description',
                key: 'description',
                width: 250,
                ellipsis: true,
            },
            {
                title: 'Status',
                width: 150,
                dataIndex: 'stage',
                key: 'stage',
                render: (stage) => {
                    let selected = columns.find(column => parseInt(column.id) === parseInt(stage));
                    if(!selected) return <span style={{ color: 'black', fontWeight: 'bold' }}>Não definido</span>;
                    return <span style={{ color: selected.style.color, fontWeight: 'bold' }}>{selected.name}</span>;
                },
                filters: columns.map(column => ({ text: column.name, value: column.id })),
                filterMultiple: true,
                onFilter: (value, record) => record.stage === value,
                ellipsis: true,
                fixed: 'right',
            },
            {
                title: '',
                width: 80,
                key: 'id',
                className: 'text-center',
                render: (id, data) => (
                    <center>
                        <Button type="default" onClick={(() => showDrawer(data))}>
                            <Icon type="eye" />
                        </Button>
                    </center>
                ),
                fixed: 'right',
            }
        ];
    }
}