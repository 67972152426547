import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Row, Col, Card, Radio, DatePicker, Icon, Alert } from 'antd';
import { withRouter } from 'react-router-dom';

import './dashboard.css';

import * as dashboardActions from './actions';
import * as onboardingActions from '~/components/Onboarding/actions';
import { Layout } from './UI';
import moment from 'moment';
import { MonthlyPaymentChart } from './Dashboard/Charts/MonthlyPaymentChart';
import { CustomersCountChart } from './Dashboard/Charts/CustomersCountChart';
import { ExtraPaymentChart } from './Dashboard/Charts/ExtraPaymentChart';
import { DashTasksTable } from './Dashboard/Tables/DashTasksTable';
import { TasksChart } from './Dashboard/Charts/TasksChart';
// import { CertificatesTable } from './Dashboard/Tables/CertificatesTable';
import { LeadsChart } from './Dashboard/Charts/LeadsChart';
import { CustomersByType } from './Dashboard/Charts/CustomersByType';
import { TasksChartByMonth } from './Dashboard/Charts/TasksChartByMonth';
import { CardTitleWithMonth } from './Dashboard/CardTitleWithMonth';
import { CalendarTasksModal } from './Dashboard/Modals/CalendarTasksModal';
import { LastUpdatedAt } from './Dashboard/Charts/styles';
import { PERMISSIONS } from '~/constants/permissions.constants';
import { VideoModal } from './VideoModal';
import { VIDEOS } from '~/constants/videos.constants';
import { ModuleHeader } from './ModuleHeader';
import { AccountStorage } from '~/storages/account';
import { DefaultersChart } from './Dashboard/Charts/DefaultersChart';

const { MonthPicker } = DatePicker;

class Dashboard extends Component {
    constructor(props) {
        super(props);
        this.interval = React.createRef();
    }

    state = {
        customFrom: moment().subtract({ month: 1 }),
        customTo: moment(),
        isFullscreenMode: false,
        isTasksModalOpen: false,
        isSuccessAlertVisible: false
    }

    componentWillUnmount = () => {
        clearInterval(this.interval.current);
    }

    componentDidMount = () => {
        const { history } = this.props;
        const query = new URLSearchParams(history.location.search);
        const tokenFromQuery = query.get('token');

        if (
            tokenFromQuery &&
            AccountStorage.get().token !== tokenFromQuery
        ) {
            AccountStorage.set({ token: tokenFromQuery });
            query.delete("token");
            history.replace({
                search: undefined,
            });
            this.setState({ isSuccessAlertVisible: true });
            this.props.openVideoModal({
                title: VIDEOS['bem-vindo'].title,
                url: VIDEOS['bem-vindo'].url
            });
        }
    }

    componentDidUpdate = (prevProps, prevState) => {
        const { dashboard: { visualizationType: prevVisualizationType } } = prevProps;
        const {
            isFullscreenMode: prevIsFullscreenMode,
            customFrom: prevCustomFrom,
            customTo: prevCustomTo
        } = prevState;

        const { isFullscreenMode, customFrom, customTo } = this.state;
        const { dashboard: { visualizationType }} = this.props;

        if (prevVisualizationType !== visualizationType) {
            if (isFullscreenMode) {
                clearInterval(this.interval.current);
                this.initializeIntervalToFetchData();
            }
        }

        if (prevIsFullscreenMode !== isFullscreenMode) {
            if (!isFullscreenMode) {
                clearInterval(this.interval.current);
                return;
            };
            this.initializeIntervalToFetchData();            
        }

        if (prevCustomFrom !== customFrom || prevCustomTo !== customTo) {
            if (isFullscreenMode) {
                clearInterval(this.interval.current);
                this.initializeIntervalToFetchData();
            }
        }
    }

    initializeIntervalToFetchData = () => {
        const { dashboard: { visualizationType }} = this.props;
        const { customFrom, customTo } = this.state;

        const oneMinute = 1000 * 60;

        if (visualizationType === 'current_month') {
            this.interval.current = setInterval(() => {
                this.props.loadDataForCurrentMonth();
            }, oneMinute);
        } else if (visualizationType === 'custom_months') {
            if (!customFrom || !customTo) {
                clearInterval(this.interval.current);
                return;
            }
            this.interval.current = setInterval(() => {
                this.loadDataForCustomDates();
            }, oneMinute);
        }
    }

    loadDataForCustomDates = () => {
        const from = this.state.customFrom;
        const to = this.state.customTo;

        if (!from || !to) {
            this.props.changeVisualizationType('custom_months');
            return;
        }

        const datesBetweenFromAndTo = [];
        const tempFrom = from.clone();

        while (to > tempFrom || tempFrom.format('M') === to.format('M')) {
            datesBetweenFromAndTo.push(tempFrom.format('YYYY-MM'));
            tempFrom.add(1, 'month');
        }

        this.props.loadDataForCustomMonths(datesBetweenFromAndTo.join(','));
    }

    selectDate = (event) => {
        const selected = event.target.value;

        if (selected === 'custom_months') {
            this.loadDataForCustomDates();
        } else {
            this.props.loadDataForCurrentMonth();
        }
    }

    getDisabledDatesForCustomTo = (current) => {
        const from = this.state.customFrom;

        if (from) {
            return current < from ||
                current > moment(from).add(12, 'months') ||
                current > moment();
        }

        return current > moment() || current < moment(this.props.user.created_at).startOf('month');
    }

    toggleFullscreenMode = () => {
        const { isFullscreenMode } = this.state;

        this.setState({ isFullscreenMode: !isFullscreenMode }, () => {
            const aside = document.querySelector('#root > div > section > aside');
            const layout = document.querySelector('#root > div > section > section');
            const toggleButton = document.querySelector('#header-main > i');
            const mainHeader = document.querySelector('#header-main');

            const asideWidth = getComputedStyle(aside).width;
    
            if (isFullscreenMode) {
                aside.style.left = 0;
                mainHeader.style.display = 'flex';
                toggleButton.style.display = 'inline-block';

                layout.style.marginLeft = asideWidth === '80px' ? '80px' : '250px';
            } else {
                aside.style.left = '-300px';
                mainHeader.style.display = 'none';
                toggleButton.style.display = 'none';
                layout.style.marginLeft = 0;
            }

            // FIXME: Por algum motivo, os gráficos não redimensionam corretamente ao mudar para fullscreen.
            // Por hora estou forçando uma atualização do estado após 500ms como solução temporária.
            setTimeout(() => {
                this.props.changeVisualizationType(this.props.dashboard.visualizationType);
            },500);
        });
    }

    render() {
        const { dashboard, user } = this.props;
        const isPremiumPlan = user.tenant && user.tenant.plan === 'premium';
        const { isFullscreenMode, isSuccessAlertVisible } = this.state;
        const { visualizationType, isLoading } = dashboard;

        const isMediumScreen = window.screen.width < 1366;

        const lastUpdatedAt = (visualizationType === 'custom_months' && dashboard.customGraphicsLastUpdatedAt) ?
            `Atualizado ${moment(Number(dashboard.customGraphicsLastUpdatedAt) * 1000).fromNow()}` :
            null;

        const calendarTasksLastUpdatedAt = (visualizationType === 'custom_months' && dashboard.calendarTasksGraphLastUpdatedAt) ?
            `Atualizado ${moment(Number(dashboard.calendarTasksGraphLastUpdatedAt) * 1000).fromNow()}` :
            null;

        return (
            <Layout>
                <div className="header-container">
                    <ModuleHeader breadcrumbs={['Dashboard']} />
                    <div className="search-buttons">
                        <Radio.Group
                            value={visualizationType}
                            onChange={this.selectDate}>
                            <Radio.Button value="current_month">Este mês</Radio.Button>
                            <Radio.Button value="custom_months">Customizado</Radio.Button>
                        </Radio.Group>
                        <div className="custom-dates">
                            <MonthPicker
                                format="MM/YYYY"
                                placeholder="Data initial"
                                disabled={visualizationType !== 'custom_months'}
                                disabledDate={(current) => current > moment() || current < moment(user.tenant.created_at).startOf('month')}
                                value={this.state.customFrom}
                                onChange={(value) => this.setState({ customFrom: value }, () => this.loadDataForCustomDates())}
                                allowClear={false}
                            />
                            <MonthPicker
                                format="MM/YYYY"
                                placeholder="Data final"
                                disabledDate={this.getDisabledDatesForCustomTo}
                                disabled={visualizationType !== 'custom_months' || !this.state.customFrom}
                                value={this.state.customTo}
                                allowClear={false}
                                onChange={(value) => this.setState({ customTo: value }, () => this.loadDataForCustomDates())}
                            />
                        </div>
                    </div>
                    <button className="fullscreen-button" type="button" onClick={this.toggleFullscreenMode}>
                        <Icon type={this.state.isFullscreenMode ? 'fullscreen-exit' : 'fullscreen'} />
                    </button>
                </div>
                {user.name && (
                    <div className="main-container">
                        {/* {isSuccessAlertVisible && (
                            <Alert
                                message="Pagamento efetuado!"
                                description="O acesso ao sistema está liberado."
                                type="success"
                                showIcon
                                closable
                                style={{ marginBottom: 10 }}
                            />
                        )} */}
                        {user.permissions.includes(PERMISSIONS.graphs.calendarTasks.view) && (
                            <Row gutter={[16, 16]}>
                                <Col span={24}>
                                        <Card
                                            loading={isLoading}
                                            title={
                                                <CardTitleWithMonth
                                                    title="Tarefas do mês"
                                                    from={this.state.customFrom}
                                                    to={this.state.customTo}
                                                    visualizationType={visualizationType}
                                                    showFullScreenButton={visualizationType === 'custom_months'}
                                                    openCalendarTasksModal={() => this.setState({ isTasksModalOpen: true })}
                                                    isCalendarTasksModalOpen={this.state.isTasksModalOpen}
                                                />
                                            }
                                        >
                                            {visualizationType === 'current_month' ? (
                                                <TasksChart data={dashboard.calendarTasksGraph} />
                                            ) : (
                                                <>
                                                    <TasksChartByMonth data={dashboard.calendarTasksGraph} />
                                                    {calendarTasksLastUpdatedAt && <LastUpdatedAt>{calendarTasksLastUpdatedAt}</LastUpdatedAt>}
                                                </>
                                            )}
                                        </Card>
                                </Col>
                            </Row>
                        )}
                        <Row gutter={[16, 16]}>
                            {isPremiumPlan && user.permissions.includes(PERMISSIONS.graphs.leads.view) && (
                                <Col span={24}>
                                    <Card
                                        loading={isLoading}
                                        title={
                                            <CardTitleWithMonth
                                                title="Leads"
                                                from={this.state.customFrom}
                                                to={this.state.customTo}
                                                visualizationType={visualizationType}
                                            />
                                        }>
                                            <LeadsChart
                                                data={dashboard.leadsGraph}
                                                visualizationType={visualizationType}
                                            />
                                            {lastUpdatedAt && <LastUpdatedAt>{lastUpdatedAt}</LastUpdatedAt>}
                                    </Card>
                                </Col>
                            )}
                            {user.permissions.includes(PERMISSIONS.graphs.monthlyPayments.view) && (
                                <Col span={isMediumScreen ? 24 : isFullscreenMode ? 8 : 12}>
                                    <Card loading={isLoading} title="Mensalidade x Recebido">
                                        <MonthlyPaymentChart data={dashboard.monthlyPaymentsGraph} />
                                        {lastUpdatedAt && <LastUpdatedAt>{lastUpdatedAt}</LastUpdatedAt>}
                                    </Card>
                                </Col>
                            )}
                            {/* {user.permissions.includes(PERMISSIONS.graphs.certificates.view) && (
                                <Card
                                    title={
                                        <CardTitleWithMonth
                                            title="Certificados digitais"
                                            from={this.state.customFrom}
                                            to={this.state.customTo}
                                            visualizationType={visualizationType}
                                        />
                                    }
                                >
                                    <CertificatesTable data={dashboard.certificatesSummary} />
                                </Card>
                            )} */}
                            {isPremiumPlan && user.permissions.includes(PERMISSIONS.graphs.extraPayments.view) && (
                                <Col span={isMediumScreen ? 24 : isFullscreenMode ? 8 : 12}>
                                    <Card loading={isLoading} title="Serviço extras / Financeiro">
                                        <ExtraPaymentChart data={dashboard.extraPaymentsGraph} />
                                        {lastUpdatedAt && <LastUpdatedAt>{lastUpdatedAt}</LastUpdatedAt>}
                                    </Card>
                                </Col>
                            )}
                            {user.permissions.includes(PERMISSIONS.graphs.customersCount.view) && (
                                <Col span={isMediumScreen ? 24 : isFullscreenMode ? 8 : 12}>
                                    <Card loading={isLoading} title="Quantidade de clientes">
                                        <CustomersCountChart data={dashboard.customersCountGraph} />
                                        {lastUpdatedAt && <LastUpdatedAt>{lastUpdatedAt}</LastUpdatedAt>}
                                    </Card>
                                </Col>
                            )}
                            {user.permissions.includes(PERMISSIONS.graphs.customersByType.view) && (
                                <Col span={isMediumScreen ? 24 : isFullscreenMode ? 8 : 12}>
                                    <Card loading={isLoading} title="Clientes por tipo">
                                        <CustomersByType data={dashboard.customersByType} />
                                        {lastUpdatedAt && <LastUpdatedAt>{lastUpdatedAt}</LastUpdatedAt>}
                                    </Card>
                                </Col>
                            )}
                        </Row>
                        {user.permissions.includes(PERMISSIONS.graphs.dashTasks.view) && (
                            <Row gutter={[16, 16]}>
                                <Col span={isMediumScreen ? 24 : isFullscreenMode ? 8 : 12}>
                                    <Card
                                        loading={isLoading}
                                        title={
                                            <CardTitleWithMonth
                                                title="Ranking inadimplentes"
                                                from={this.state.customFrom}
                                                to={this.state.customTo}
                                                visualizationType={visualizationType}
                                            />
                                        }>
                                        <DefaultersChart data={dashboard.defaultersGraph} />
                                        {lastUpdatedAt && <LastUpdatedAt>{lastUpdatedAt}</LastUpdatedAt>}
                                    </Card>
                                </Col>
                                {isPremiumPlan && user.permissions.includes(PERMISSIONS.graphs.dashTasks.view) && (
                                <Col span={isMediumScreen ? 24 : isFullscreenMode ? 8 : 12}>
                                    <Card
                                        loading={isLoading}
                                        title={
                                            <CardTitleWithMonth
                                                title="Serviços extras / Tarefas"
                                                from={this.state.customFrom}
                                                to={this.state.customTo}
                                                visualizationType={visualizationType}
                                            />
                                        }>
                                        <DashTasksTable data={dashboard.dashtasksSummary} />
                                        {lastUpdatedAt && <LastUpdatedAt>{lastUpdatedAt}</LastUpdatedAt>}
                                    </Card>
                                </Col>
                                )}
                            </Row>
                        )}
                    </div>
                )}
                <CalendarTasksModal
                    tasksData={dashboard.calendarTasksGraph}
                    isOpen={this.state.isTasksModalOpen}
                    closeCalendarTasksModal={() => {
                        this.setState({ isTasksModalOpen: false})
                    }}
                />
                <VideoModal />
            </Layout>
        )
    }
}

const mapStateToProps = state => {
    return {
        dashboard: state.dashboard,
        user: state.user
    }
}

const mapDispatchProps = (dispatch) => ({
    loadDataForCurrentMonth: () => dispatch(dashboardActions.loadForCurrentMonth()),
    loadDataForCustomMonths: (months) => dispatch(dashboardActions.loadForCustomMonths(months)),
    changeVisualizationType: (type) => dispatch(dashboardActions.changeVisualizationType(type)),
    openVideoModal: (params) => dispatch(onboardingActions.openVideoModal(params))
})

export default withRouter(connect(mapStateToProps, mapDispatchProps)(Dashboard));