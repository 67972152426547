import React, { Component } from 'react';
import { Button, DatePicker, Select, Form } from 'antd';
import { connect } from 'react-redux';

import moment from 'moment';
import 'moment/locale/pt-br';

import locale from '../../UI/DatePicker/Locale';
import * as billsToReceiveActions from '~/components/BillsToReceive/actions';

const { MonthPicker } = DatePicker;
const { Option } = Select;

class AdvancedFilter extends Component {

    handleFilter = (e) => {
        e.preventDefault();

        this.props.form.validateFields((err, {
          filter_by,
          from,
          to,
          status
        }) => {
          if (err) return;

          const filters = {
            filter_by,
            from: from.format('YYYY-MM-01'),
            to: to.endOf('month').format('YYYY-MM-DD'),
            status
          }

          this.props.setFilters(filters);
          this.props.loadExtras(filters);
        });
    }

    render() {
        const { getFieldDecorator } = this.props.form;

        return (
            <Form layout="inline" className="ant-advanced-search-form-inline" onSubmit={this.handleFilter}>
                <Form.Item label="Filtrar por:">
                {getFieldDecorator('filter_by', {
                    initialValue: 'due_date',
                })(
                <Select>
                    <Option value="due_date">Vencimento</Option>
                    <Option value="apuration_at">Apuração</Option>
                </Select>
                )}
                </Form.Item>
                <Form.Item label="Período" style={{ marginBottom: 0 }}>
                  <Form.Item style={{ marginRight: 0 }}>
                      {getFieldDecorator('from', {
                          initialValue: moment()
                      })(
                      <MonthPicker 
                          format={'MM/YYYY'}
                          locale={locale}
                      />
                      )}
                  </Form.Item>
                  <span style={{ display: 'inline-block', width: '24px', textAlign: 'center' }}>-</span>
                  <Form.Item style={{ marginRight: 0 }}>
                      {getFieldDecorator('to', {
                          initialValue: moment()
                      })(
                      <MonthPicker 
                          format={'MM/YYYY'}
                          locale={locale}
                      />
                      )}
                  </Form.Item>
                </Form.Item>
                <Form.Item label="Status">
                    {getFieldDecorator('status', {
                        initialValue: 'all'
                    })(
                    <Select style={{width: `150px`}}>
                      <Option key={1} value="all">Todos</Option>
                      <Option key={2} value="paid">Pago</Option>
                      <Option key={3} value="pendent">Pendente</Option>
                      <Option key={4} value="defaulting">Inadimplente</Option>
                      <Option key={5} value="processed">Ajuizado</Option>
                      <Option key={6} value="revised">Revisado</Option>
                      <Option key={7} value="unrevised">Não Revisado</Option>
                    </Select>
                    )}
                </Form.Item>
                <Form.Item>
                <Button type="primary" htmlType="submit">
                    Filtrar
                </Button>
            </Form.Item>
          </Form>
        )
    }
}

const mapDispatchProps = dispatch => {
    return{
      saveBill: (id, data) => dispatch({
        type: 'SAVE_BILL',
        payload: {
          request:{
            method: 'post',
            url:`/extras/${id}`,
            data: data
          }
        }
      }),
      loadExtras: (filter) => dispatch(billsToReceiveActions.loadExtras(filter)),
    }
}
export default connect(null, mapDispatchProps)(Form.create()(AdvancedFilter));