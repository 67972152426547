import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";

import Dashboard from "./Dashboard";
import Drawer from "~/containers/DrawerSocietario/index";
import Layout from "~/components/UI/Layout/Layout";
import RefundsForm from "~/containers/DrawerRefunds";
import * as dashTasksActions from "~/screens/Societario/actions";
import { LoadingTopBar } from "~/components/UI/LoadingTopBar";
import DrawerLeads from "~/containers/DrawerLeads";
import CustomerForm from "~/containers/Customer/index";

export const ScreensSocietario = () => {
  const dispatch = useDispatch();

  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    loadData();
  }, []);

  async function loadData() {
    try {
      await Promise.all([
        dispatch(dashTasksActions.loadDashTasks({ showLoading: false })),
      ]);
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  }

  return (
    <div>
      <LoadingTopBar isActive={isLoading} />
      <Layout>
        <DrawerLeads />
        <CustomerForm />
        <Dashboard />
        <Drawer />
        <RefundsForm />
      </Layout>
    </div>
  );
};
