import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Table, Button, Tooltip, Dropdown, Menu, Icon, Modal, Row, Col } from 'antd';
import { LinkModal } from './LinkModal';
import { copyToClipBoard } from '~/utils/copy-to-clipboard';
import { getSubdomain } from '~/helpers/getSubdomain';
import * as pluginsAction from '~/containers/Plugins/actions';

const { Column } = Table;

const List = ({ isVisible }) => {

  const dispatch = useDispatch();
  const { settings } = useSelector((state) => state);

  const [isLoadingLinks, setIsLoadingLinks] = useState(true);
  const [links, setLinks] = useState([]);
  const [linkModal, setLinkModal] = useState({
    isVisible: false,
    id: null,
    url: '',
    acquisition_id: null,
  });

  useEffect(() => {
    async function getInitialData() {
      setIsLoadingLinks(true);
      try {
        const data = await loadCountClicksRequest();
        setLinks(data.payload.data);
      } catch (error) {
        console.log(error);
      } finally {
        setIsLoadingLinks(false);
      }
    }
    if (isVisible) {
      getInitialData();
    }
  }, [isVisible]);

  async function loadCountClicksRequest() {
    return dispatch(pluginsAction.loadCountClicks());
  }

  async function deleteCountClickRequest({ id }) {
    return dispatch(pluginsAction.deleteCountClick({ id }));
  }

  function hideLinkModal() {
    setLinkModal({
      isVisible: false,
      id: null,
      url: '',
      acquisition_id: null,
    });
  }

  function showLinkModal(data) {
    console.log(data);
    setLinkModal({
      isVisible: true,
      ...data,
    });
  }

  function formatLink(id) {
    return `https://${getSubdomain()}.${
      process.env.REACT_APP_API_DOMAIN
    }/plugins/count-clicks/${id}`;
  }

  function deleteLink(id) {
    Modal.confirm({
      title: 'Você realmente deseja remover este link?',
      content: 'Esta ação não poderá ser desfeita.',
      okText: 'Sim',
      okType: 'danger',
      cancelText: 'Cancelar',
      onOk: async () => {
        await deleteCountClickRequest({ id });
        setLinks(links.filter((item) => item.id !== id));
      },
    });
  }

  return (
    <>
    <Row>
      <Col span={24} style={{ textAlign: 'right' }} className="mb-20">
        <Button 
            type="primary" 
            onClick={showLinkModal} 
            disabled={isLoadingLinks}>
              <Icon type="plus" /> Adicionar Link
          </Button>
      </Col>
    </Row>
    <Table
        rowKey="id"
        dataSource={links}
        bordered
        pagination={false}
        className="mb-20"
        loading={isLoadingLinks}
      >
        <Column 
          title="Origem" 
          dataIndex="acquisition_id" 
          key="acquisition_id" 
          ellipsis={true}
          width={150} 
          render={(acquisition_id) => {
            const acquisition = settings['leads.acquisition'].find((item) => parseInt(item.id) === parseInt(acquisition_id));
            return acquisition ? acquisition.value : '';
          }}
        />
        <Column
          title="Link compartilhável"
          dataIndex="id"
          key="full_url"
          width={400}
          ellipsis={true}
          render={(id) => {
            const formattedUrl = formatLink(id);
            return (
              <Tooltip title={'Clique para copiar'}>
                <span onClick={() => copyToClipBoard(formattedUrl)} style={{cursor: 'pointer'}}>
                  {formattedUrl}
                </span>
              </Tooltip>
            );
          }}
        />
        <Column
          title="Acessos"
          dataIndex="clicks_count"
          key="clicks_count"
          align="center"
          width={100}
        />
        <Column
          key="actions"
          dataIndex="id"
          align="center"
          width={100}
          render={(id, record, index) => (
            <center key={index}>
              <Dropdown overlay={
                  <Menu>
                    <Menu.Item key="edit" onClick={() => showLinkModal(record)}>
                        <Icon type="edit"></Icon> Editar
                    </Menu.Item>
                    <Menu.Divider />
                    <Menu.Item key="delete" onClick={() => deleteLink(id)}>
                        <Icon type="delete"></Icon> Excluir
                    </Menu.Item>
                  </Menu>
              } trigger={['click']}>
                  <a className="ant-dropdown-link" href="#">
                    Ações <Icon type="down" />
                  </a>
              </Dropdown>
            </center>
          )}
        />
      </Table>
      <LinkModal
        data={linkModal}
        isVisible={linkModal.isVisible}
        onClose={hideLinkModal}
        setLinks={setLinks}
      />
    </>
  );
}

export default List;