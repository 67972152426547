import * as SETTINGS from './actions';
import { CACHE_ACTIONS } from '~/constants/cache-actions.constants';
import { LocalCacheHandler } from '~/utils/local-cache-handler';



const ACTION_HANDLERS = {
    [CACHE_ACTIONS.settings]: (state, action) => {
        return action.payload;
    },

    [SETTINGS.LOAD_SETTINGS.SUCCESS]: (state, action) => {
        const { data } = action.payload;
        const leadsLostReason = data.filter((item) => item.key === 'leads.lost_reason');
        const leadsAcquisition = data.filter((item) => item.key === 'leads.acquisition');
        const leadsCompanyType = data.filter((item) => item.key === 'leads.company_type');
        const leadsTags = data.filter((item) => item.key === 'leads.tags');
        const formsGroup = data.filter((item) => item.key === 'forms.group');
        const orgNames = data.filter((item) => item.key === 'customers.org_name');

        return {
            'leads.lost_reason': leadsLostReason ? leadsLostReason : [],
            'leads.acquisition': leadsAcquisition ? leadsAcquisition : [],
            'leads.company_type': leadsCompanyType ? leadsCompanyType : [],
            'leads.tags': leadsTags ? leadsTags : [],
            'forms.group': formsGroup ? formsGroup : [],
            'customers.org_name': orgNames ? orgNames : [],
            isLoading: false
        };
    },

    [SETTINGS.CREATE_SETTINGS.SUCCESS]: (state, action) => {
        const { data } = action.payload;
        const { key } = data;

        return {
            ...state,
            [key]: [...state[key], { ...data }]
        };
    },

    [SETTINGS.UPDATE_SETTINGS.SUCCESS]: (state, action) => {
        const { data } = action.payload;
        const { key, value } = data;

        return {
            ...state,
            [key]: [...state[key].map((item) => item.id === value.id ? value : item)]
        };
    },

    [SETTINGS.DELETE_SETTINGS.SUCCESS]: (state, action) => {
        const { data } = action.payload;
        const { key, value } = data;

        return {
            ...state,
            [key]: [...state[key].filter((item) => item.id !== value.id)]
        };
    }
};

const defaultState = {
    'leads.lost_reason': [],
    'leads.acquisition': [],
    'leads.company_type': [],
    'leads.tags': [],
    'forms.group': [],
    isLoading: true
}

const reducer = (state = defaultState, action) => {
    const handler = ACTION_HANDLERS[action.type];
    if(!handler) return state;

    const newState = handler(state, action);

    const updateCache = [
        SETTINGS.LOAD_SETTINGS.SUCCESS,
        SETTINGS.CREATE_SETTINGS.SUCCESS,
        SETTINGS.UPDATE_SETTINGS.SUCCESS,
        SETTINGS.DELETE_SETTINGS.SUCCESS
    ].includes(action.type);

    if(updateCache){
        LocalCacheHandler.save({
            pathname: CACHE_ACTIONS.settings,
            data: newState
        });
    }

    return newState;
};

export default reducer;