import React from "react";
import { Form, Row, Col, Input, Select } from "antd";
import { useDispatch, useSelector } from "react-redux";

import SelectWithAdd from "~/components/SelectWithAdd";
import Tip from "~/components/Tip";

import * as settingActions from "~/containers/Settings/actions";

const { Option } = Select;

export const Details = ({
    data,
    form
}) => {
    const dispatch = useDispatch();
    const { getFieldDecorator } = form;
    const { settings } = useSelector((state) => state);

    return (
        <>
            <Row>
                <Col span={24}>
                    <Form.Item label={<span>Nome <Tip>De um nome para este formulário, ele será utilizado para você identificar este formulário. Visitantes não verão este nome.</Tip></span>} required>
                        {getFieldDecorator("name", {
                        initialValue: data ? data.name : "",
                        rules: [{ required: true, message: "Por favor, informe um nome para o formulário!" }],
                        })(<Input placeholder="Nome do formulário" />)}
                    </Form.Item>
                </Col>
            </Row>
            <Row gutter={16}>
                <Col span={8}>
                    <Form.Item label="Tipo de formulário" required>
                        {getFieldDecorator("type", {
                        initialValue: data ? data.type : "form1",
                        rules: [{ required: true, message: "Por favor, informe o tipo do formulário!" }],
                        })(
                        <Select>
                            <Option value="form1">Básico <Tip>Nome, Email e Whatsapp</Tip></Option>
                            <Option value="form2">Reunião <Tip>Nome, Email, Whatsapp e Data</Tip></Option>
                            <Option value="chatbot">Chatbot <Tip>Você pode definir os campos que deseja coletar.</Tip></Option>
                        </Select>
                        )}
                    </Form.Item>
                </Col>
                <Col span={8}>
                    <Form.Item label={<span>Origem <Tip>Origem é onde você pretende compartilhar este formulário, exemplo: Seu site, Blog, Instagram etc... <br/><br/> Se preenchido, o lead será marcado com esta origem.</Tip></span>} required>
                        {getFieldDecorator("acquisition_id", {
                        initialValue: data ? data.acquisition_id : null,
                        rules: [{ required: true, message: "Por favor, informe a origem do formulário!" }],
                        })(
                        <SelectWithAdd
                            placeholder="Selecione ou cadastre uma origem"
                            registerItem={(value) => dispatch(settingActions.createSetting({ data: { key: "leads.acquisition", value }}))}
                        >
                            {settings["leads.acquisition"].map((item) => (
                            <Option key={item.id} value={item.id}>
                                {item.value}
                            </Option>
                            ))}
                        </SelectWithAdd>
                        )}
                    </Form.Item>
                </Col>
                {/* <Col span={8}>
                    <Form.Item label={<span>Grupo <Tip>Visualize os relatórios de formulários por grupos</Tip></span>}>
                        {getFieldDecorator("group_id", {
                        initialValue: data ? data.group_id : null,
                        })(
                        <SelectWithAdd
                            placeholder="Selecione ou cadastre um grupo"
                            registerItem={(value) => dispatch(settingActions.createSetting({ data: { key: "forms.group", value }}))}
                        >
                            {settings["forms.group"] && settings["forms.group"].map((item) => (
                            <Option key={item.id} value={item.id}>
                                {item.value}
                            </Option>
                            ))}
                        </SelectWithAdd>
                        )}
                    </Form.Item>
                </Col> */}
            </Row>
        </>
    );
}

export default Details;