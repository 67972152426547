import React from 'react';
import { useDispatch } from "react-redux";
import { Row, Col, Tooltip, Progress, Dropdown, Menu, Modal } from 'antd';
import styled from 'styled-components';
import { Icon } from 'antd';
import * as boardActions from '../actions';
import * as drawerBoardActions from '~/containers/DrawerBoardItem/actions';

const grid = 8;
export const ItemTask = styled.div`
    user-select: none;
    padding: 10px;
    margin: 0 0 ${grid}px 0;
    border-radius: 3px;
    background: #fff;
    position: relative;

    ${props => props.isDragging && `
        box-shadow: 1.5px 1px 1px #ccc;
    `}

    ${props => props.isCreating && `
        opacity: 0.3;
        animation: fadeIn 0.3s;
    `}
`

export const ItemTaskTitle = styled.div`
    display: inline-block;
    margin-top: 8px;
    margin-bottom: 8px;
    font-size: 16px;
`

export const ItemId = styled.div`
    font-size: 10px;
    font-weight: bold;
    color: #ccc;
    position: absolute;
    top: 3px;
    left: 10px;
`

export const PercentageContainer = styled.div`
  position: absolute;
  bottom: -5px;
  left: 0;
  right: 0;
  height: 15px;
`;

export const ProgressStyled = styled(Progress).attrs({
    strokeColor: '#52c41a',
    showInfo: false,
    size: 'small',
  })``;

export const MenuItem = styled.div`
    position: absolute;
    padding: 10px;
    top: 0;
    right: 0;
    cursor: pointer;
    z-index: 1;
`

export const ItemCard = (props) => {
    const dispatch = useDispatch();
    const progress = props.item.tasks.filter(task => task.done).length / props.item.tasks.length * 100;

    const onClick = ({ key }) => {
        if (key === '1') {
            dispatch(boardActions.duplicateItem(props.item));
        } else if (key === '2') {
            Modal.confirm({
                title: `Você tem certeza que deseja deletar o item "${props.item.title}"?`,
                okText: 'Sim',
                cancelText: 'Não',
                onOk: () => {
                    dispatch(drawerBoardActions.remove(props.item));
                }
            });
        }
    };

    const menu = (
        <Menu onClick={onClick}>
          <Menu.Item key="1">
            <Icon type="copy" /> Duplicar
          </Menu.Item>
          <Menu.Divider />
          <Menu.Item key="2">
            <Icon type="delete" /> Deletar
          </Menu.Item>
        </Menu>
      );

    return (
        <ItemTask
          {...props}
          {...props.item}
            >
            <Row
                type="flex"
                justify="center"
                align="bottom"
            >
                <ItemId>#{props.item.id}</ItemId>
                <MenuItem>
                    <Dropdown overlay={menu} placement="bottomRight">
                        <Icon type="more" />
                    </Dropdown>
                </MenuItem>
                <Col span={24}>
                    <ItemTaskTitle>
                        {props.item.title}
                    </ItemTaskTitle>
                </Col>
            </Row>
            {progress > 0 && (
            <PercentageContainer>
                <Tooltip title="Percentual de tarefas concluídas">
                  <ProgressStyled percent={progress} />
                </Tooltip>
            </PercentageContainer>
            )}
        </ItemTask>
    );
};

export default ItemCard;