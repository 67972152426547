import styled from 'styled-components';

export const HeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  margin-bottom: 10px;
`;

export const HeaderLeftContent = styled.div`
  display: flex;
  align-items: flex-end;
  gap: 6px;
  width: 100%;
  max-width: 600px;
`;

export const HeaderRightContent = styled.div`
  display: flex;
  gap: 6px;
`;