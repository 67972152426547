import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import {
  Button,
  DatePicker,
  Dropdown,
  Form,
  Icon,
  Menu,
  Modal,
  Table,
  message,
} from 'antd';
import moment from 'moment';
import uid from 'uid';

import { PartnerModal } from '../PartnerModal';
import { Footer } from './styles';
import { formOptions } from './form-options';

import * as customerActions from '~/containers/Customer/actions';

const { Column } = Table;

export const PartnersSetModal = Form.create()(
  ({ isVisible, onClose, setPartnersSets, form, initialData }) => {
    const dispatch = useDispatch();
    const { getFieldDecorator, validateFields, resetFields } = form;

    const [partners, setPartners] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [partnerModal, setPartnerModal] = useState({
      isVisible: false,
      partnerId: null
    });

    function closePartnerModal() {
      setPartnerModal({
        isVisible: false,
        partnerId: null
      });
    }

    function openPartnerModal(partnerId = null) {
      setPartnerModal({
        isVisible: true,
        partnerId
      });
    }

    function deletePartner(id) {
      Modal.confirm({
        title: 'Você realmente deseja remover este sócio?',
        content: 'Esta ação não poderá ser desfeita.',
        okText: 'Sim',
        okType: 'danger',
        cancelText: 'Cancelar',
        onOk: async () => {
          setPartners((state) => state.filter((partner) => partner.id !== id));
        },
      });
    }

    function closeModal() {
      setPartners([]);
      resetFields();
      onClose();
    }

    function createPartnersSet() {
      validateFields((error, { change_date }) => {
        if (error) return;

        const partnersTotalPercentage = partners.reduce(
          (total, { percentage }) => percentage + total,
          0
        );

        if (partnersTotalPercentage < 100) {
          return message.warning(
            'Para criar o cenário é necessário que a soma do percentual dos sócios seja 100%'
          );
        }

        const newPartnersSet = {
          id: uid(),
          partners,
          change_date: change_date.format('YYYY-MM-DD LTS'),
          formatted_change_date: change_date.format('DD/MM/YYYY'),
          is_new: true,
        };

        setPartnersSets((state) => [...state, newPartnersSet]);
        closeModal();
      });
    }

    function updatePartnersSet() {
      validateFields((error, { change_date }) => {
        if (error) return;

        const partnersTotalPercentage = partners.reduce(
          (total, { percentage }) => percentage + total,
          0
        );

        if (partnersTotalPercentage < 100) {
          return message.warning(
            'Para criar o cenário é necessário que a soma do percentual dos sócios seja 100%'
          );
        }

        const updatedPartnersSet = {
          ...initialData,
          partners,
        };

        // save to backend
        setIsLoading(true);
        dispatch(customerActions.updatePartner(updatedPartnersSet)).then(() => {
          setIsLoading(false);
          setPartnersSets((state) =>
            state.map((partnersSet) => {
              if (partnersSet.id !== updatedPartnersSet.id) {
                return partnersSet;
              }
  
              return updatedPartnersSet;
            })
          );
  
          closeModal();
        });
      });
    }

    useEffect(() => {
      if (initialData && initialData.partners) {

        var partners = initialData.partners.map((partner) => {
          return {
            ...partner,
            id: partner.id || uid(10),
          };
        });

        setPartners(partners);
      }
    }, [initialData]);

    const isViwing = initialData.action === 'view';

    return (
      <Modal
        title={isViwing ? `Cenário em ${initialData.formatted_change_date}` : 'Novo cenário'}
        visible={isVisible}
        onCancel={closeModal}
        okText={isLoading ? 'Salvando...' : 'Salvar'}
        onOk={isViwing ? updatePartnersSet : createPartnersSet}
        cancelText="Voltar"
        width={900}
        closable={false}
        destroyOnClose={true}
      >
        <Table 
          dataSource={partners} 
          rowKey={(record) => record.id}
          bordered 
          pagination={false}>
          <Column title="Nome" key="name" dataIndex="name" />
          <Column
            title="CPF"
            key="identification_number"
            dataIndex="identification_number"
          />
          <Column
            title="Data de nascimento"
            key="birthdate"
            dataIndex="birthdate"
            render={(value) =>
              value ? moment(value).format('DD/MM/YYYY') : null
            }
          />
          <Column title="Whatsapp" key="phone" dataIndex="phone" />
          <Column
            title="Percentual"
            key="percentage"
            dataIndex="percentage"
            align="center"
            render={(value) => (value ? `${value}%` : null)}
          />
          <Column
            width={80}
            key="actions"
            align="center"
            render={(_, record) => (
              <Dropdown
                overlay={
                  <Menu>
                    <Menu.Item
                      key="edit"
                      onClick={() => openPartnerModal(record.id)}
                    >
                      <Icon type="form"></Icon> Editar
                    </Menu.Item>
                    {!isViwing && (
                    <Menu.Item
                      key="delete"
                      onClick={() => deletePartner(record.id)}
                    >
                      <Icon type="delete"></Icon> Excluir
                    </Menu.Item>
                    )}
                  </Menu>
                }
                trigger={['click']}
              >
                <Button type="link" size="small">
                  Ações <Icon type="down" />
                </Button>
              </Dropdown>
            )}
          />
        </Table>
        <Footer>
          {!isViwing && (
          <Button type="primary" onClick={() => openPartnerModal()}>
            Novo sócio
          </Button>
          )}
          {!isViwing && (
          <Form layout="inline">
            <Form.Item label="Data da alteração">
              {getFieldDecorator(
                'change_date',
                formOptions.change_date
              )(
                <DatePicker
                  disabledDate={(current) => current > moment()}
                  format="DD/MM/YYYY"
                />
              )}
            </Form.Item>
          </Form>
          )}
        </Footer>
        {partnerModal.isVisible && (
          <PartnerModal
            isVisible={partnerModal.isVisible}
            onClose={closePartnerModal}
            partnerId={partnerModal.partnerId}
            partners={partners}
            setPartners={setPartners}
            isViwing={isViwing}
          />
        )}
      </Modal>
    );
  }
);
