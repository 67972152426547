import React from 'react';
import ReactDOM from 'react-dom';
import Routes from './Routes';
import { Provider } from 'react-redux';
import { createStore, combineReducers, applyMiddleware, compose} from 'redux';
import * as serviceWorker from './serviceWorker';
import axiosMiddleware from 'redux-axios-middleware';
import { client, options } from './components/Instance';
import { ConfigProvider } from 'antd';
import pt_BR from 'antd/lib/locale-provider/pt_BR';

import moment from 'moment';
import 'moment/locale/pt-br';

import * as todoApp from './store/reducers';

import 'antd/dist/antd.css';
import './components/UI/IziToast/style.css';
import 'swiper/swiper-bundle.css'

moment.locale('pt-br');

export const ApplicationVersion = 'v1.34.7';

let composeEnhancers = compose;

if (process.env.NODE_ENV === 'development') {
  const composeWithDevToolsExtension =
    window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__;

  if (typeof composeWithDevToolsExtension === 'function') {
    composeEnhancers = composeWithDevToolsExtension;
  }
}

const store = createStore(
    combineReducers(todoApp), 
    composeEnhancers(applyMiddleware(axiosMiddleware(client, options))), 
);

ReactDOM.render(
    <Provider store={store}>
        <ConfigProvider locale={pt_BR}>
            <Routes />
        </ConfigProvider>
    </Provider>, 
    document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();