import { Form, InputNumber, Modal } from "antd";
import moment from "moment";
import React from "react";
import { useState } from "react";
import InputCurrency, { currency2Float } from "~/components/UI/InputCurrency";
import { capitalizeFormatter } from "~/utils/formatters";

export const LeadMetricsModal = Form.create()(
  ({ isVisible, form, closeModal, selectedDate, onSaveMetrics, currentMetrics }) => {
    const [isLoading, setIsLoading] = useState(false);
    const { getFieldDecorator, validateFields } = form;
    const selectedMonth = selectedDate.split("-")[0];
    const selectedYear = selectedDate.split("-")[1];

    const monthName = moment(`${selectedYear}-${selectedMonth}-01`).format(
      "MMMM"
    );

    function createMetrics() {
      validateFields((errors, values) => {
        if (errors) return;

        setIsLoading(true);
        onSaveMetrics({
          ...values,
          date: `${selectedYear}-${selectedMonth}-01`,
        })
          .then(() => closeModal())
          .finally(() => setIsLoading(false));
      });
    }

    return (
      <Modal
        centered
        visible={isVisible}
        title={`Métricas (${capitalizeFormatter(monthName)}/${selectedYear})`}
        onOk={createMetrics}
        onCancel={closeModal}
        okText="Salvar"
        destroyOnClose
        confirmLoading={isLoading}
      >
        <Form
          layout="horizontal"
          labelCol={{ span: 10 }}
          labelAlign="left"
          wrapperCol={{ span: 14 }}
        >
          <Form.Item label="Quantidade de acessos">
            {getFieldDecorator("access_count", {
              initialValue: currentMetrics.access_count,
              rules: [
                {
                  required: true,
                  message: "Por favor, informe a quantidade de acessos",
                },
              ],
            })(<InputNumber style={{ width: "100%" }} min={0} />)}
          </Form.Item>
          <Form.Item label="Valor investido em ads">
            {getFieldDecorator("ads_amount", {
              normalize: (value) => currency2Float(value),
              initialValue: currentMetrics.ads_amount,
              rules: [
                {
                  required: true,
                  message: "Por favor, informe o valor investido em ads",
                },
              ],
            })(<InputCurrency />)}
          </Form.Item>
          <Form.Item label="Valor investido em blog">
            {getFieldDecorator("blog_amount", {
              normalize: (value) => currency2Float(value),
              initialValue: currentMetrics.blog_amount,
              rules: [
                {
                  required: true,
                  message: "Por favor, informe o valor investido em blog",
                },
              ],
            })(<InputCurrency />)}
          </Form.Item>
        </Form>
      </Modal>
    );
  }
);
