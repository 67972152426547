export const loadPayments = ({ filter_by, from, to, status, ...params }) => ({
  type: "LOAD_MONTHLY_PAYMENTS",
  payload: {
    request: {
      method: "get",
      url: `/monthly/payments?filter_by=${filter_by}&from=${from}&to=${to}&status=${status}`,
      ...params
    },
    filter: {
      from,
      to,
      status,
      filter_by,
    },
  },
});
