import React, { useState } from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import Moment from 'react-moment';
import 'moment/locale/pt-br';
import AdvancedFilter from './AdvancedFilter';
import DownloadBatch from '../../components/Components/DownloadBatch';
import Download from '../../components/Components/Download';

import {
    Input,
    Button,
    Table,
    Menu,
    Icon,
    Dropdown,
    Modal
} from 'antd';

import {
    TopBar,
    Cod
} from '~/components/UI';
import * as drawerRefundsActions from '../DrawerRefunds/actions';
import * as refundsActions from './actions';

import { toBRL } from '~/components/UI/InputCurrency';
import { PERMISSIONS } from '~/constants/permissions.constants';
import { ModuleHeader } from '~/components/ModuleHeader';
import { HeaderContainer, HeaderLeftContent, HeaderRightContent } from './styles';
import { getDefaultPaginationConfig } from '~/utils/get-default-pagination-config';
import { STORAGE } from '~/constants/storage.constants';

const { Search } = Input;
const { Column } = Table;

const payment_method = (val) =>{
    switch(val){
      case 1: return 'Dinheiro';
      case 2: return 'Boleto';
      case 3: return 'Cartão';
      case 4: return 'Transferência';
      case 5: return 'Pix';
      case 6: return 'Depósito';
      case 7: return 'Cheque';
    }
}

const RefundsList = (props) => {

    const [search, setSearch, ] = useState('');
    const [selectedRowKeys, setSelectedRowKeys] = useState([]);    
    
    const { 
        user, 
        showDrawerRefunds,
        setPaid,
        data,
        isLoading
    } = props;

    const handleMenu = (key, item) => {
        switch(key){
            case 'edit': showDrawerRefunds(item); break;            
            case 'paid': 
                Modal.confirm({
                    title: `Você realmente deseja alterar esta comissão para ${item.refund.type? 'Recebido':'Pago'}?`,
                    content: 'Esta ação não poderá ser desfeita.',
                    okText: 'Sim',
                    okType: 'danger',
                    cancelText: 'Cancelar',
                onOk: () => setPaid(item.id)
              }); break;
            // default: `key: ${key} is not supported.`;
        }
    }

    const getFilename = (data) => {
          const now = moment(data.refund.apuration_at).format('MM-YYYY');
          return `${data.refund.responsible}_COMISSÃO_${now}.pdf`;      
    }

    let filtered = data.filter((str) => {
        if(search.toLowerCase() === ''){
            return true;
        }

        if (str.customer) {
            if (str.customer.name.toLowerCase().indexOf(search.toLowerCase()) >= 0) {
                return true;
            }

            if(str.customer.cod !== null && str.customer.cod.toLowerCase().indexOf(search.toLowerCase()) >= 0){
              return true;
            }
        }

        if (str.refund.responsible.toLowerCase().indexOf(search.toLowerCase()) >= 0) {
            return true;
        }

        return false;
    });

    const selectRow = (record) => {        
        if (selectedRowKeys.indexOf(record.id) >= 0) {
            setSelectedRowKeys((state) => state.filter((row) => row.id !== record.id));
        } else {
            setSelectedRowKeys((state) => [...state, record.id]);
        }
    }
    
    const onSelectedRowKeysChange = (keys) => {
        setSelectedRowKeys(keys)
    }

    const rowSelection = {
        selectedRowKeys,
        onChange: onSelectedRowKeysChange,
    };

    console.log('data', data);
    console.log('filtered', filtered);

    return (
        <div>
            <TopBar>
                <HeaderContainer>
                    <HeaderLeftContent>
                        <ModuleHeader breadcrumbs={['Financeiro', 'Comissões']} />
                        <Search
                            placeholder="Pesquisar por cód, cliente ou responsável pela indicação" 
                            onChange={e => setSearch(e.target.value)}
                            style={{ maxWidth: 400 }}
                        />
                    </HeaderLeftContent>
                    <HeaderRightContent>
                        <DownloadBatch 
                            url="/refunds/payments/print/batch"
                            filename="comissão.zip"
                            ids={selectedRowKeys}>  
                            <Button 
                                type="primary" 
                                icon="printer"
                            >
                                Imprimir
                            </Button>
                        </DownloadBatch>
                        <Button type="primary" onClick={() => showDrawerRefunds()}>
                            Cadastrar Comissão
                        </Button>
                    </HeaderRightContent>                    
                </HeaderContainer>
                <AdvancedFilter {...props}/>
            </TopBar>
            <div className="table-clabs">
                <Table 
                    rowKey="id"
                    dataSource={filtered}
                    loading={isLoading} 
                    pagination={getDefaultPaginationConfig({
                        storageKey: STORAGE.defaultPageSize.refunds
                    })}
                    bordered={true}
                    rowClassName="row-clabs"
                    scroll={{ x: 1990, y: `${window.innerHeight-175}px`}}
                    rowSelection={rowSelection}
                    onRow={record => ({
                    onClick: () => {
                        selectRow(record);
                    }
                    })}
                >
                    <Column
                        title="Status"
                        dataIndex="status"
                        key="status"
                        width={120}
                        fixed="left"
                        render={(status, record) => <center>
                            <Button
                                value={1}
                                size="small"
                                style={
                                    status === 'Crédito' ? {borderColor: '#ffa940', color: '#ffa940'} :
                                    (status === 'Pago' || status === 'Recebido') ? {borderColor: `#52c41a`, color: `#52c41a`} :
                                    {borderColor: `#f5222d`, color: `#f5222d`}}>
                                {status}
                            </Button>
                        </center>}
                        filters={[{
                            text: 'A pagar',
                            value: 'A pagar'
                        }, {
                            text: 'Pago',
                            value: 'Pago'
                        },
                        {
                            text: 'Crédito',
                            value: 'Crédito'
                        },
                        {
                            text: 'Recebido',
                            value: 'Recebido'
                        },
                        ]}
                        filterMultiple={false}
                        onFilter={(value, record) => record.status && record.status.indexOf(value) === 0}
                    />
                    <Column
                        title="Cod"
                        dataIndex="cod"
                        key="cod"
                        fixed="left"
                        width={100}
                    />
                    <Column
                        title="Empresa do responsável pela indicação"
                        dataIndex="customer.name"
                        key="name"
                        fixed="left"
                        width={400}
                        render={(val, record) => record.customer && <span>{record.customer.cod} - {record.customer.name}</span>}
                    />
                    <Column
                        title="Responsável pela indicação"
                        dataIndex="refund.responsible"
                        key="refund.responsible"
                        width={320}
                    />
                    <Column
                        title="Empresa indicada"
                        dataIndex="refund.nominated_company"
                        key="nominated_company"
                        width={320}
                    />
                    <Column
                        title="Parcela"
                        dataIndex="quota"
                        key="quota"
                        width={100}
                    />
                    <Column
                        title="Método"
                        dataIndex="method"
                        key="method"
                        width={100}
                        filters={[{
                            text: payment_method(1),
                            value: 1,
                        }, {
                            text: payment_method(2),
                            value: 2,
                        },
                        {
                            text: payment_method(3),
                            value: 3,
                        },
                        {
                            text: payment_method(4),
                            value: 4,
                        }
                        ]}
                        filterMultiple={false}
                        render={val => payment_method(val)}
                        onFilter={(value, record) => record.type_staff && record.type_staff.indexOf(value) === 0}
                    />
                    <Column
                        title="Apuração"
                        dataIndex="refund.apuration_at"
                        key="refund.apuration_at"
                        width={150}
                        sorter={(a, b) => a.apuration_at && a.apuration_at.localeCompare(b.apuration_at)}
                        render={apuration_at => 
                            <center>
                            <Moment format="DD/MM/YYYY">
                                {apuration_at}
                            </Moment>
                            </center>
                        }
                    />
                    <Column
                        title="Vencimento"
                        dataIndex="due_date"
                        key="due_date"
                        width={150}
                        sorter={(a, b) => a.due_date && a.due_date.localeCompare(b.due_date)}
                        render={due_date => 
                            <center>
                            <Moment format="DD/MM/YYYY">
                                {due_date}
                            </Moment>
                            </center>
                        }
                    />
                    <Column
                        title="Valor"
                        dataIndex="amount"
                        key="amount"
                        width={200}
                        sorter={(a, b) => a.amount && (a.amount-b.amount)}
                        render={value => toBRL(value)}
                    />
                    <Column
                        title="Societário"
                        dataIndex="refund.dash_task_id"
                        key="refund.dash_task_id"
                        width={200}
                        render={value => value && (<Cod>#SOC{value}</Cod>)}
                    />
                    <Column
                        dataIndex="actions"
                        key="actions"
                        fixed="right"
                        width={150}
                        render={(text, record, index) => (
                        <center key={index}>
                            <Dropdown overlay={
                                <Menu onClick={(e) => handleMenu(e.key, record)}>
                                {user.permissions.includes(PERMISSIONS.extraServices.edit) ?
                                <Menu.Item key="edit">
                                    <Icon type="edit"></Icon> Editar
                                </Menu.Item>
                                :
                                <Menu.Item key="edit">
                                    <Icon type="file"></Icon> Detalhes
                                </Menu.Item>
                                }
                                {record.paid_at === null && (<Menu.Divider />)}
                                {record.paid_at === null && (
                                <Menu.Item key="paid">
                                    <Icon type="check"></Icon> {record.refund.type ? 'Marcar como Recebido':'Marcar como Pago'}
                                </Menu.Item>
                                )}
                                <Menu.Item>
                                    <Download url={`/refunds/payments/print/${record.id}`} filename={getFilename(record)}>
                                        <div><Icon type="printer" style={{
                                        minWidth: '12px',
                                        marginRight: '8px',
                                        fontSize: '12px'
                                        }} /> Imprimir</div>
                                    </Download>
                                </Menu.Item>
                                </Menu>
                            } trigger={['click']}>
                                <a className="ant-dropdown-link" href="#">
                                Ações <Icon type="down" />
                                </a>
                            </Dropdown>
                        </center>
                    )}
                    />
                </Table>
            </div>
        </div>
    )
}

const mapStateToProps = state => ({
    user: state.user,
    data: state.refunds.payments,
    isLoading: state.refunds.isLoading
});

const mapDispatchProps = dispatch => ({
    showDrawerRefunds: (payload) => dispatch(drawerRefundsActions.show(payload)),
    setPaid: (payload) => dispatch(refundsActions.setPaid(payload))
});

export default connect(mapStateToProps, mapDispatchProps)(RefundsList);