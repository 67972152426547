import { Button, Dropdown, Icon, Menu, Table, Tag } from "antd";
import moment from "moment";
import React, { Component } from "react";
import { connect } from "react-redux";
import { department } from "~/components/RegisterTasks/Utils";
import { getSubdomain } from "~/helpers/getSubdomain";
import * as documentsActions from "./actions";
import AdvancedFilter from "./AdvancedFilter";
import {
  ActiveRadioButton,
  ArchivedRadioButton,
  DeliveryMethodsWrapper,
  Header,
  HeaderWrapper,
  PendingRadioButton,
  RadioButtons,
  SearchInput,
} from './styles';
import { ArchiveDocumentModal } from "./Modals/ArchiveDocumentModal";
import { STORAGE } from "~/constants/storage.constants";
import { EmailLogsModal } from "./Modals/EmailLogsModal";
import { getFormattedEvent } from "./helpers";
import { convertToBRL } from "~/utils/formatters";
import { ModuleHeader } from '~/components/ModuleHeader';
import { deliveryMethodNames } from '../Customer/data';
import { SporadictDocumentUpload } from '~/components/CalendarTask/Drawers/SporadicDocumentUpload';
import { SporadicDocumentUploadInBatch } from '~/components/CalendarTask/Drawers/SporadicDocumentUploadInBatch';
import { PendingDocumentsDrawer } from './components/PendingDocumentsDrawer';
import { getDefaultPaginationConfig } from '~/utils/get-default-pagination-config';

const { Column } = Table;
class Documents extends Component {
  state = {
    filters: {
      search: "",
    },
    impersonateAccessLoading: null,
    visualizationType: 'active',
    archiveModal: {
      isOpen: false,
      id: null
    },
    emailLogsModal: {
      isOpen: false,
      id: null
    },
    documentDrawer: {
      isOpen: false,
    },
    documentsInBatchDrawer: {
      isOpen: false
    },
    pendingDocumentsDrawer: {
      isOpen: false
    },
  };

  getStatusTag = (file) => {
    let selectedStatus;
    let icon;

    if (moment(file.due_at).isBefore(moment(), 'dates') && !file.viewed_at) {
      selectedStatus = { name: "VENCIDO", color: "#ff0a02" };
    } else if (file.viewed_at) {
      selectedStatus = { name: "BAIXADO", color: "#03b14e" };
    } else {
      selectedStatus = { name: "NÃO BAIXADO", color: "#0071c0" };
    }

    if (file.status === "Pago") {
      icon = <Icon type="check" />;
    } else if (file.viewed_at) {
      icon = <Icon type="download" />;
    }

    return (
      <div
        style={{
          backgroundColor: selectedStatus.color,
          color: "white",
          display: "inline-flex",
          justifyContent: "center",
          alignItems: "center",
          gap: "5px",
          fontSize: "13px",
          padding: "5px 10px",
          borderRadius: "3px",
        }}
      >
        {icon || null}
        {selectedStatus.name}
      </div>
    );
  };

  impersonateAccess = (file) => {
    this.setState({ impersonateAccessLoading: file.id });
    const data = {
      customer_id: file.customer.id,
    };

    this.props.impersonateAccess(file.id, data)
      .then((response) => {
        const apiToken = response.payload.data.data.api_token;

        const customerAppLink = `http://${getSubdomain()}.${
          process.env.REACT_APP_CUSTOMER_DOMAIN
        }/cliente/?file=${file.id}&token=${apiToken}`;

        window.open(customerAppLink, '_blank').focus();
      })
      .finally(() => {
        this.setState({ impersonateAccessLoading: null });
      })
  }

  generateAccessToFile = async (file) => {
    try {
      const response = await this.props.generateAccessToFile(file.id);
      const { file_url: url } = response.payload.data;

      const whatsappMessage = `Prezado cliente, ${
        file.customer.name
      }.%0aEncontra-se disponível a obrigação ${
        file.description
      } com os seguintes dados:%0a%0aDescrição: ${
        file.description
      }%0aApuração / Data de competência: ${moment(file.competency_at).format(
        'MM/YYYY'
      )}%0aVencimento: ${moment(file.due_at).format(
        'DD/MM/YYYY'
      )}%0aValor: ${convertToBRL(file.amount)}%0aLink: ${url}`;

      const whatsappUrl = `https://api.whatsapp.com/send?phone=55${file.customer.phone}&text=${whatsappMessage}`;

      window.open(whatsappUrl, '_blank').focus();

    } catch (error) {
      console.log(error);
    }
  }

  getRevisedTitle = (file) => {
    const { revised_at, revised_by } = file;
    if (revised_at && revised_by) {
      const user = this.props.getUser(revised_by);

      if (!user) {
        return `Revisado as ${moment(revised_at).format('DD/MM/YYYY H:mm:ss')}`;
      }

      return `Revisado por ${user.name} - ${moment(revised_at).format('DD/MM/YYYY H:mm:ss')}`;
    }
    return 'Pendente';
  }

  openArchiveModal = (id) => {
    this.setState({
      archiveModal: {
        isOpen: true,
        id
      }
    })
  }

  closeArchiveModal = () => {
    this.setState({
      archiveModal: {
        isOpen: false,
        id: null
      }
    })
  }

  openEmailLogsModal = (id) => {
    this.setState({
      emailLogsModal: {
        isOpen: true,
        id
      }
    })
  }

  closeEmailLogsModal = () => {
    this.setState({
      emailLogsModal: {
        isOpen: false,
        id: null
      }
    })
  }

  openDocumentDrawer = () => {
    this.setState({ documentDrawer: {
        isOpen: true
    }});
  }

  closeDocumentDrawer = () => {
    this.setState({ documentDrawer: {
        isOpen: false
    }});
  }

  openDocumentsInBatchDrawer = () => {
    this.setState({
      documentsInBatchDrawer: {
        isOpen: true
      }
    });
  }

  closeDocumentsInBatchDrawer = () => {
    this.setState({
      documentsInBatchDrawer: {
        isOpen: false
      }
    });
  }

  openPendingDocumentsDrawer = () => {
    this.setState({
        pendingDocumentsDrawer: {
            isOpen: true
        }
    })
  }

  closePendingDocumentsDrawer = () => {
      this.setState({
          pendingDocumentsDrawer: {
              isOpen: false
          }
      })
  }

  render() {
    const { documents, isLoading } = this.props;
    const { visualizationType } = this.state;

    const {
      search: searchFilter,
    } = this.state.filters;

    const filteredDocuments = documents.filter((document) => {
      const customerName = document.customer.name.toLowerCase();
      const customerCod = document.customer.cod;

      if (
        customerName.includes(searchFilter) ||
        customerCod.includes(searchFilter)
      ) {
        switch (visualizationType) {
          case 'active': {
            return !document.archived_at && !document.archived_by;
          }
          case 'pending': {
            return document.delivery_methods.length === 0;
          }
          case 'archived': {
            return !!document.archived_at && !!document.archived_by;
          }
          default: {
            return false;
          }
        }
      }

      return false;
    });

    const uniqueTaskNames = [
      ...new Set(
        documents
          .filter((document) => !!document.task_name)
          .map((document) => document.task_name))
    ];

    return (
      <div>
        <Header>
          <HeaderWrapper>
            <ModuleHeader breadcrumbs={['Documentos enviados']} />
            <SearchInput
              placeholder="Pesquisar pelo nome ou codigo do cliente"
              onChange={(e) =>
                this.setState({
                  filters: {
                    search: e.target.value.toLowerCase(),
                  },
                })
              }
            />
            <RadioButtons
              value={visualizationType}
              onChange={(event) => this.setState({ visualizationType: event.target.value })}
            >
              <ActiveRadioButton
                isActive={visualizationType === 'active'}
                value="active"
              >
                Ativos
              </ActiveRadioButton>
              <PendingRadioButton
                isActive={visualizationType === 'pending'}
                value="pending"
              >
                Pendentes
              </PendingRadioButton>
              <ArchivedRadioButton
                isActive={visualizationType === 'archived'}
                value="archived"
              >
                Arquivados
              </ArchivedRadioButton>
            </RadioButtons>
            <Dropdown
              trigger={['click']}
              overlay={
                <Menu>
                  <Menu.Item key="1" onClick={this.openDocumentDrawer}>
                    Individual
                  </Menu.Item>
                  <Menu.Item key="2" onClick={this.openDocumentsInBatchDrawer}>
                    Em lote
                  </Menu.Item>
                  <Menu.Item key="3" onClick={this.openPendingDocumentsDrawer}>
                    Pendentes
                  </Menu.Item>
                </Menu>
              }
            >
              <Button type="primary">Adicionar documento</Button>
            </Dropdown>
          </HeaderWrapper>
          <AdvancedFilter />
        </Header>
        <Table
          rowKey="id"
          bordered
          loading={isLoading}
          style={{ marginTop: 20 }}
          dataSource={filteredDocuments}
          scroll={{ x: true, y: 'calc(100vh - 370px)' }}
          pagination={getDefaultPaginationConfig({
            storageKey: STORAGE.defaultPageSize.documents,
          })}
        >
          <Column
            title="Arquivo"
            dataIndex="file_url"
            align="center"
            key="file_url"
            fixed="left"
            width={150}
            render={(file_url, file) => (
              <a
                href={file_url}
                style={{ color: '#7c4dff'}}
                download
                target="_blank"
                rel="noopener noreferrer"
              >
                <Icon style={{ marginRight: 10 }} type="file" />
                {file.description}
              </a>
            )}
          />
          <Column
            title="Cliente"
            key="customer"
            dataIndex="customer.name"
            fixed="left"
            width={350}
            render={(_, record) => `${record.customer.cod} - ${record.customer.name}`}
          />
          <Column
            title="Nome da tarefa"
            key="task_name"
            dataIndex="task_name"
            align="center"
            filters={uniqueTaskNames.map((task) => ({
              text: task,
              value: task,
            }))}
            filterMultiple={false}
            onFilter={(value, file) => file.task_name === value}
            width={200}
          />
          <Column
            title="Tipo"
            key="type"
            dataIndex="type"
            align="center"
            width={200}
          />
          <Column
            title="Colaborador"
            key="created_by_user"
            dataIndex="created_by_user"
            width={150}
            align="center"
          />
          <Column
            title="Departamento"
            key="department"
            dataIndex="department"
            align="center"
            filters={[
              {
                text: department(1),
                value: "1",
              },
              {
                text: department(2),
                value: "2",
              },
              {
                text: department(3),
                value: "3",
              },
              {
                text: department(4),
                value: "4",
              },
              {
                text: department(5),
                value: "5",
              },
              {
                text: department(6),
                value: "6",
              },
            ]}
            filterMultiple={false}
            onFilter={(value, file) => {
              if (!file.department) return false;
              return file.department.indexOf(value) === 0;
            }}
            render={(departmentId) => department(departmentId)}
            width={150}
          />
          <Column
            title="Competência"
            key="competency_at"
            dataIndex="competency_at"
            align="center"
            sorter={(a, b) => moment(a.competency_at).diff(b.competency_at)}
            render={(competency_at) => moment(competency_at).format("MM/YYYY")}
            width={150}
          />
          <Column
            title="Vencimento"
            key="due_at"
            dataIndex="due_at"
            align="center"
            sorter={(a, b) => moment(a.due_at).diff(b.due_at)}
            render={(due_at) => moment(due_at).format("DD/MM/YYYY")}
            width={140}
          />
          <Column
            title="Valor"
            key="amount"
            dataIndex="amount"
            align="center"
            render={(amount) =>
              new Intl.NumberFormat("pt-BR", {
                style: "currency",
                currency: "BRL",
              }).format(amount)
            }
            width={130}
          />
          <Column
            title="Escritório"
            key="office"
            dataIndex="created_by_user"
            align="center"
            render={(userName, record) => {
              const createdAt = moment(record.created_at);
              const firstName = userName ? userName.split(' ')[0] : '';

              return `${firstName} ${createdAt.format('DD/MM/YYYY')} as ${createdAt.format('HH:MM')}`
            }}
            width={200}
          />
          <Column
            title="Administração"
            key="viewed_at"
            dataIndex="viewed_at"
            align="center"
            width={250}
            render={(viewed_at, file) => {
              const appUser = file.viewed_by_app_user;

              if (!viewed_at || !appUser) return null;

              const firstAppUserName = appUser.name.split(" ")[0];
              const formattedViewedAt = moment(viewed_at).format('DD/MM/YYYY [as] LTS');

              return `${firstAppUserName}... ${formattedViewedAt}`;
            }}
          />
          <Column
            align="center"
            title="Status"
            key="status"
            width={200}
            filters={[
              {
                text: 'Vencido',
                value: 'Vencido'
              },
              {
                text: 'Baixado',
                value: 'Baixado'
              },
              {
                text: 'Não baixado',
                value: 'Não baixado'
              },
            ]}
            filterMultiple={false}
            onFilter={(value, file) => {
              if (value === 'Vencido') {
                return moment(file.due_at).add(1, "day") < moment() && !file.viewed_at;
              } else if (value === 'Baixado') {
                return !!file.viewed_at;
              } else if (value === 'Não baixado') {
                return moment(file.due_at).add(1, "day") > moment() && !file.viewed_at;
              }
            }}
            render={(_, file) => this.getStatusTag(file)}
          />
          <Column
            align="center"
            title="Status do e-mail"
            dataIndex="email_status"
            key="email_status"
            width={150}
            filters={[
              {
                text: 'Enviado',
                value: 'delivered'
              },
              {
                text: 'Aberto',
                value: 'opened'
              },
              {
                text: 'Clicado',
                value: 'clicked'
              },
              {
                text: 'Marcado como spam',
                value: 'complained'
              },
              {
                text: 'Falhou',
                value: 'failed'
              },
              {
                text: 'Inscrição cancelada',
                value: 'unsubscribed'
              },
              {
                text: 'Envio solicitado',
                value: 'pendent'
              },
            ]}
            onFilter={(value, record) => record.email_status === value}
            render={(emailStatus, { id }) => {
              if (!emailStatus) {
                return (
                  <Tag
                    style={{ cursor: 'pointer' }}
                    onClick={() => this.props.sendEmails(id)}
                  >
                    Enviar e-mail
                  </Tag>
                )
              }
              const status = getFormattedEvent(emailStatus);
              return (
                <Tag
                  style={{ cursor: 'pointer' }}
                  color={status.color}
                  onClick={() => this.openEmailLogsModal(id)}
                >
                    {status.text}
                </Tag>
              )
            }}
          />
          {/* <Column
            title="Whatsapp"
            key="whatsapp"
            dataIndex="customer.phone_formated"
            align="center"
            render={(formattedPhone, file) => {
              if (!formattedPhone) return null;

              return (
                <div style={{
                  display: 'flex',
                  alignItems: 'center',
                  gap: '2px',
                  justifyContent: 'center'
                }}>
                  <span>{formattedPhone}</span>
                  <Tooltip title="Enviar acesso ao arquivo para o Whatsapp do cliente">
                    <img
                      src={WhatsappIcon}
                      alt="Whatsapp logo"
                      style={{ cursor: 'pointer' }}
                      onClick={() => this.generateAccessToFile(file)}
                    />
                  </Tooltip>
                </div>
              )
            }}
            width={160}
          />
          <Column
            title="Revisado?"
            key="revised_at"
            dataIndex="revised_at"
            align="center"
            render={(revised_at, file) => {
              return (
                <Tooltip title={this.getRevisedTitle(file)}>
                  <Switch
                    checked={!!revised_at}
                    onChange={(isChecked) => this.props.changeRevised(file.id, { is_revised: isChecked})}
                  />
                </Tooltip>
              )
            }}
            width={100}
          /> */}
          {visualizationType === 'archived' && (
            <Column
              title="Arquivado em"
              key="archived_at"
              dataIndex="archived_at"
              render={(archivedAt) => moment(archivedAt).format('DD/MM/YYYY [as] H:m:s')}
              width={150}
            />
          )}

          {visualizationType === 'archived' && (
            <Column
              title="Arquivado por"
              key="archived_by"
              dataIndex="archived_by"
              render={(archivedBy) => {
                const { getUser } = this.props;
                const user = getUser(archivedBy);
                return user ? user.name : null
              }}
              width={150}
            />
          )}

          {visualizationType === 'archived' && (
            <Column
              title="Motivo por arquivar"
              key="reason_to_archive"
              dataIndex="reason_to_archive"
              width={200}
            />
          )}
          <Column
            title="Métodos de envio"
            key="delivery_methods"
            filters={[
              {
                text: 'Email',
                value: 0
              },
              {
                text: 'Protocolo',
                value: 1
              },
              {
                text: 'Painel Web',
                value: 2
              },
              {
                text: 'WhatsApp',
                value: 3
              },
            ]}
            onFilter={(value, record) => record.delivery_methods.includes(value)}
            dataIndex="delivery_methods"
            width={200}
            render={(deliveryMethods, { protocol_id }) => (
              <DeliveryMethodsWrapper>
                {deliveryMethods && deliveryMethods.map((method) => (
                  <Tag key={method}>{deliveryMethodNames[method]}{method === 1 && `#${protocol_id}`}</Tag>
                ))}
              </DeliveryMethodsWrapper>
            )}
          />
          <Column
            title="Criado em"
            key="created_at"
            dataIndex="created_at"
            align="center"
            sorter={(a, b) => moment(a.created_at).diff(b.created_at)}
            defaultSortOrder="descend"
            render={(created_at) =>
              `${moment(created_at).format('DD/MM/YYYY')} as ${moment(
                created_at
              ).format('LTS')}`
            }
            width={120}
          />
          <Column
            key="action"
            align="center"
            render={(_, file) => {
              return (
                <Button
                  icon="link"
                  onClick={() => this.impersonateAccess(file)}
                >
                  {this.state.impersonateAccessLoading === file.id ? 'Gerando acesso...' : 'Painel do cliente'}
                </Button>
              );
            }}
            width={230}
          />
          {visualizationType === 'active' && (
            <Column
              key="archive"
              dataIndex="id"
              align="center"
              fixed="right"
              render={(id) => (
                <Button
                  type="danger"
                  icon="delete"
                  onClick={() => this.openArchiveModal(id)}
                />
              )}
              width={80}
            />
          )}
        </Table>
        <SporadictDocumentUpload
          isOpen={this.state.documentDrawer.isOpen}
          onClose={this.closeDocumentDrawer}
        />
        <SporadicDocumentUploadInBatch
          isVisible={this.state.documentsInBatchDrawer.isOpen}
          onClose={this.closeDocumentsInBatchDrawer}
        />
        <PendingDocumentsDrawer
          isVisible={this.state.pendingDocumentsDrawer.isOpen}
          onClose={this.closePendingDocumentsDrawer}
        />
        <ArchiveDocumentModal
          isOpen={this.state.archiveModal.isOpen}
          id={this.state.archiveModal.id}
          closeModal={this.closeArchiveModal}
          archiveDocument={this.props.archiveDocument}
        />
        <EmailLogsModal
          isOpen={this.state.emailLogsModal.isOpen}
          documentId={this.state.emailLogsModal.id}
          closeModal={this.closeEmailLogsModal}
        />
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    documents: state.documents.list,
    isLoading: state.documents.isLoading,
    user: state.user,
    getUser: (id) => state.users.find((user) => user.id === id)
  };
};

const mapDispatchProps = (dispatch) => {
  return {
    impersonateAccess: (id, data) => dispatch(documentsActions.impersonateAccess(id, data)),
    changeRevised: (id, data) => dispatch(documentsActions.changeRevised(id, data)),
    generateAccessToFile: (id) => dispatch(documentsActions.generateAccessToFile(id)),
    archiveDocument: (id, data) => dispatch(documentsActions.archiveDocument(id, data)),
    sendEmails: (id) => dispatch(documentsActions.sendEmails(id))
  };
};

export default connect(mapStateToProps, mapDispatchProps)(Documents);
