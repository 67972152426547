/* eslint-disable jsx-a11y/img-redundant-alt */
import React, { useEffect, useState, useRef, useLayoutEffect } from 'react';
import { useSelector } from 'react-redux';
import { 
    Form, 
    Input, 
    Select,
    Button,
    Icon,
    Row,
    Col,
    message
} from 'antd';

import uid from 'uid';
import { DEFAULT_MESSAGES } from '~/constants/default-messages.constants';
import axios from 'axios';
import { getSubdomain } from '~/helpers/getSubdomain';

import InputMask from '~components/UI/InputMask'
import InputCurrency, { currency2Float } from '~components/UI/InputCurrency';

import { 
    Container,
    BotWrapper,
    BotHeader,
    BotTitle,
    ChatWrapper,    
    ChatMessage,
    FormWrapper
} from './styles';

import { Typing } from './Typing';

export const ChatBot = ({
    form,
    data,
    isCheckingIfFormIsValid,
    token,
}) => {
    const [currentMsg, setCurrentMsg] = useState(null);
    const [chat, setChat] = useState([]);
    const [typing, setTyping] = useState(true);
    const [id, setId] = useState(null);

    const chatRef = useRef(null);
    const inputRef = useRef(null);

    const msgs = data && data.fields ? data.fields : [];

    const settings = useSelector(state => state.settings);

    async function sendData() {
        let chatValues = {}
        // eslint-disable-next-line array-callback-return
        chat.map((c) => {
            if(c.field_name && c.text && c.from === 'user') {
                chatValues = {
                    ...chatValues,
                    [c.field_name]: {
                        value: c.text,
                        props: {
                            ...msgs.find(m => m.field_name === c.field_name) || {}
                        }
                    }
                }
            }
        });

        // check if object is empty
        if(Object.keys(chatValues).length === 0) {
            return;
        }

        try {
            await axios.post(
              `${process.env.REACT_APP_API}/forms/${token}/log`,
              {
                fields: chatValues,
                id: id,
              },
              {
                headers: {
                  "X-Tenant": getSubdomain(),
                },
              }
            ).then(response => {
                setId(response.data.data.id);
            });
    
          } catch (error) {
            const { response } = error;
            if (response) {
              if (response.status === 404 || response.status >= 500) {
                message.error(DEFAULT_MESSAGES['high-demand']);
              }
            }
        }
    }

    useEffect(() => {
        if (msgs && msgs[currentMsg] && msgs[currentMsg].type !== 'input') {
            setTyping(true);

            // wait 2 seconds before sending the next message
            setTimeout(() => {
                setChat([...chat, msgs[currentMsg]]);
                setCurrentMsg(currentMsg + 1);
                setTyping(false);

                // scroll to the bottom of the chat
                const scrollHeight = chatRef.current.scrollHeight;
                const clientHeight = chatRef.current.clientHeight;
                chatRef.current.scrollTo({ top: scrollHeight - clientHeight, behavior: 'smooth' });

                sendData();
            }, 1500);
        }
    }, [currentMsg]);

    useLayoutEffect(() => {
        if (currentMsg === null) {
            return;
        }

        if (msgs[currentMsg] && msgs[currentMsg].type === 'input' && msgs[currentMsg].format !== 'select' && inputRef.current && inputRef.current.focus) {
            inputRef.current.focus();
        }
    }, [currentMsg]);

    useEffect(() => {
        if(data !== null){
            setCurrentMsg(0);
        }
    }, [data]);

    const handleNext = (e) => {
        e.preventDefault();
        form.validateFields((err, values) => {
            if (!err) {
                setChat([...chat, { 
                    id: uid(), 
                    text: values.text,
                    from: 'user',
                    field_name: msgs[currentMsg].field_name
                }]);
                setCurrentMsg(currentMsg + 1);
            }
        });
    }


    if(!isCheckingIfFormIsValid) {
        return (
            <Container>
                <BotWrapper>
                    <BotHeader>
                        <BotTitle>Formulário Indisponível</BotTitle>
                    </BotHeader>
                    <ChatWrapper ref={chatRef}>
                        <ChatMessage className="bot">
                            Este formulário não está mais disponível.
                        </ChatMessage>
                    </ChatWrapper>
                </BotWrapper>
            </Container>
        )
    }

    if (currentMsg === null || data === null) {
        return (
            <Container>
                <BotWrapper>
                    <BotHeader>
                        <BotTitle>Chat</BotTitle>
                    </BotHeader>
                    <ChatWrapper ref={chatRef}>
                        {typing && (
                            <ChatMessage className="bot">
                                <Typing />
                            </ChatMessage>
                        )}
                    </ChatWrapper>
                </BotWrapper>
            </Container>
        )
    }

    return (
        <Container>
            <BotWrapper>
                <BotHeader>
                    <BotTitle>{data.name}</BotTitle>
                </BotHeader>
                <ChatWrapper ref={chatRef}>
                    {chat.map((msg, index) => {
                        if (msg.image) {
                            if (msg.image === '$logo$') {
                                return (
                                    <ChatMessage className="bot" key={index}>
                                        <img src={data.logo} alt="logo" />
                                    </ChatMessage>
                                );
                            }
                            return (
                                <ChatMessage className="bot" key={index}>
                                    <img src={msg.image} alt="logo" />
                                </ChatMessage>
                            );
                        }

                        if (msg.text === '$description$') {
                            return (
                                <ChatMessage className="bot" key={index}>
                                    <p>{data.description}</p>
                                </ChatMessage>
                            );
                        }

                        if(msg.type === 'image') {
                            return (
                                <ChatMessage className="bot" key={index}>
                                    <img src={msg.url} style={{ maxWidth: '85px', maxHeight: '85px' }} alt="image" />
                                </ChatMessage>
                            )
                        }

                        if(msg.type === 'button') {
                            return (
                                <ChatMessage className="bot" key={index}>
                                    {msg.text}
                                    <Button 
                                        type="primary"
                                        style={{ backgroundColor: '#25D366', borderColor: '#25D366', marginTop: 10 }}
                                        block
                                        onClick={() => {
                                            window.open(msg.value, '_blank');
                                        }}
                                    >
                                        {msg.label}
                                    </Button>
                                </ChatMessage>
                            )
                        }

                        return (
                            <ChatMessage
                                className={msg.from}
                                dangerouslySetInnerHTML={{ __html: msg.text }}
                                key={index}
                            />
                        );
                    })}
                    {typing && (
                        <ChatMessage className="bot">
                            <Typing />
                        </ChatMessage>
                    )}
                    {msgs[currentMsg] && msgs[currentMsg].type === 'input' && (
                        <Form onSubmit={handleNext}>
                            <Row>
                                <Col span={18} offset={6}>
                                    {(msgs[currentMsg].format === 'text' || !msgs[currentMsg].format) && (
                                        <FormWrapper>
                                            <Form.Item>
                                                {form.getFieldDecorator('text', {
                                                    rules: [
                                                        { required: true, message: 'Campo obrigatório' },
                                                    ],
                                                })(<Input placeholder="Sua resposta..." ref={inputRef} />)}
                                            </Form.Item>
                                            <Button type="primary" block htmlType="submit">
                                                <Icon type="right" />
                                            </Button>
                                        </FormWrapper>
                                    )}
                                    {msgs[currentMsg].format === 'phone' && (
                                        <FormWrapper>
                                            <Form.Item>
                                                {form.getFieldDecorator('text', {
                                                    rules: [{ required: true, message: 'Campo obrigatório' }],
                                                })(
                                                    <InputMask
                                                        mask="(99) 9999tt999?"
                                                        formatChars={{ '9': '[0-9]', t: '[0-9-]', '?': '[0-9 ]' }}
                                                        maskChar={null}
                                                        placeholder="Sua resposta..."
                                                        getRef={inputRef}
                                                    />
                                                )}
                                            </Form.Item>
                                            <Button type="primary" block htmlType="submit">
                                                <Icon type="right" />
                                            </Button>
                                        </FormWrapper>
                                    )}
                                    {msgs[currentMsg].format === 'email' && (
                                        <FormWrapper>
                                            <Form.Item>
                                                {form.getFieldDecorator('text', {
                                                    rules: [
                                                        { required: true, message: 'Campo obrigatório' },
                                                        {
                                                            type: 'email',
                                                            message: 'Formato de email inválido',
                                                        },
                                                    ],
                                                })(<Input type="email" placeholder="Sua resposta..." ref={inputRef} />)}
                                            </Form.Item>
                                            <Button type="primary" block htmlType="submit">
                                                <Icon type="right" />
                                            </Button>
                                        </FormWrapper>
                                    )}
                                    {msgs[currentMsg].format === 'cnpj' && (
                                        <FormWrapper>
                                            <Form.Item>
                                                {form.getFieldDecorator('text', {
                                                    rules: [
                                                        { required: true, message: 'Campo obrigatório' },
                                                        {
                                                            pattern: /^\d{2}\.\d{3}\.\d{3}\/\d{4}-\d{2}$/,
                                                            message: 'Formato de CNPJ inválido',
                                                        },
                                                    ],
                                                })(
                                                    <InputMask
                                                        mask="99.999.999/9999-99"
                                                        formatChars={{ '9': '[0-9]' }}
                                                        maskChar={null}
                                                        placeholder="Sua resposta..."
                                                        getRef={inputRef}
                                                    />
                                                )}
                                            </Form.Item>
                                            <Button type="primary" block htmlType="submit">
                                                <Icon type="right" />
                                            </Button>
                                        </FormWrapper>
                                    )}
                                    {msgs[currentMsg].format === 'number' && (
                                        <FormWrapper>
                                            <Form.Item>
                                                {form.getFieldDecorator('text', {
                                                    rules: [
                                                        { required: true, message: 'Campo obrigatório' },
                                                        {
                                                            pattern: /^[0-9]*$/,
                                                            message: 'Campo deve ser numérico',
                                                        },
                                                    ],
                                                })(<Input type="number" placeholder="Sua resposta..." ref={inputRef} />)}
                                            </Form.Item>
                                            <Button type="primary" block htmlType="submit">
                                                <Icon type="right" />
                                            </Button>
                                        </FormWrapper>
                                    )}
                                    {msgs[currentMsg].format === 'money' && (
                                        <FormWrapper>
                                            <Form.Item>
                                                {form.getFieldDecorator('text', {
                                                    rules: [
                                                        { 
                                                            required: true, message: 'Campo obrigatório',
                                                            rules: [{ required: true, message: 'Por favor informe o valor' }],
                                                            normalize: (value) => currency2Float(value)
                                                        },
                                                    ],
                                                })(
                                                    <InputCurrency
                                                        placeholder="Sua resposta..."
                                                        ref={inputRef}
                                                    />
                                                )}
                                            </Form.Item>
                                            <Button type="primary" block htmlType="submit">
                                                <Icon type="right" />
                                            </Button>
                                        </FormWrapper>
                                    )}
                                    {msgs[currentMsg].format === 'select' && msgs[currentMsg].options.length < 6 && (
                                        <FormWrapper>
                                            {msgs[currentMsg].options && msgs[currentMsg].options.map((option, index) => (
                                                <Button
                                                    key={index}
                                                    type="dashed"
                                                    block
                                                    style={{ backgroundColor: '#f5f5f5' }}
                                                    onClick={() => {
                                                        setChat([
                                                            ...chat,
                                                            {
                                                                id: uid(),
                                                                text: option.name,
                                                                from: 'user',
                                                                field_name: msgs[currentMsg].field_name,
                                                            },
                                                        ]);
                                                        setCurrentMsg(currentMsg + 1);
                                                    }}
                                                >
                                                    {option.name}
                                                </Button>
                                            ))}
                                        </FormWrapper>
                                    )}
                                    {/* if >=6 then display a select option */}
                                    {msgs[currentMsg].format === 'select' && msgs[currentMsg].options.length >= 6 && (
                                        <FormWrapper>
                                            <Form.Item>
                                                {form.getFieldDecorator('text', {
                                                    rules: [{ required: true, message: 'Campo obrigatório' }],
                                                })(
                                                    <Select placeholder="Selecione uma opção" ref={inputRef} size={'large'}>
                                                        {msgs[currentMsg].options.map((option, index) => (
                                                            <Select.Option key={index} value={option.value}>
                                                                {option.name}
                                                            </Select.Option>
                                                        ))}
                                                    </Select>
                                                )}
                                            </Form.Item>
                                            <Button type="primary" block htmlType="submit">
                                                <Icon type="right" />
                                            </Button>
                                        </FormWrapper>
                                    )}
                                    {msgs[currentMsg].format === 'select' && msgs[currentMsg].settings && settings[msgs[currentMsg].settings] && (
                                        <FormWrapper>
                                            <Form.Item>
                                                {form.getFieldDecorator('text', {
                                                    rules: [{ required: true, message: 'Campo obrigatório' }],
                                                })(
                                                    <Select placeholder="Selecione uma opção" ref={inputRef} size={'large'}>
                                                        {settings[msgs[currentMsg].settings].map((option, index) => (
                                                            <Select.Option key={index} value={option.value}>
                                                                {option.value}
                                                            </Select.Option>
                                                        ))}
                                                    </Select>
                                                )}
                                            </Form.Item>
                                            <Button type="primary" block htmlType="submit">
                                                <Icon type="right" />
                                            </Button>
                                        </FormWrapper>
                                    )}
                                </Col>
                            </Row>
                        </Form>
                    )}
                </ChatWrapper>
            </BotWrapper>
        </Container>
    );
}

export default Form.create({ name: 'chat_bot' })(ChatBot);