import React, { Component } from 'react';

import { Button, DatePicker, Dropdown, Menu, Radio } from 'antd';
import moment from 'moment';
import 'moment/locale/pt-br';

import Layout from '../components/UI/Layout/Layout';
import Grid from '../components/CalendarTask/Grid';
import List from '../components/CalendarTask/List/List';
import { department } from '../components/RegisterTasks/Utils';

import locale from '../components/UI/DatePicker/Locale';
import { connect } from 'react-redux';
import { SporadictDocumentUpload } from '~/components/CalendarTask/Drawers/SporadicDocumentUpload';
import { SporadicDocumentUploadInBatch } from '~/components/CalendarTask/Drawers/SporadicDocumentUploadInBatch';
import Tasks from '~/components/CalendarTask/Tasks';
import { ButtonsWrapper, HeaderWrapper, MonthPickerWrapper, TitleAndMonthPickerWrapper } from '~/components/CalendarTask/styles';
import { ModuleHeader } from '~/components/ModuleHeader';
import { PendingDocumentsDrawer } from '~/containers/Documents/components/PendingDocumentsDrawer';

const { MonthPicker } = DatePicker;

const TASKS_VISUALIZATION_MODE = 'tasks_visualization_mode';

class ScreensCalendarTask extends Component {

    state = {
        date: moment(),
        grid: null,
        list: null,
        listFilter: null,
        actived: [0,1,2],
        documentDrawer: {
            isOpen: false,
        },
        pendingDocumentsDrawer: {
            isOpen: false
        },
        documentDrawerInBatch: {
            isOpen: false
        },
        visualizationMode: localStorage.getItem(TASKS_VISUALIZATION_MODE) || 'box'
    }

    openGrid = (data) => {
        this.setState({ grid: data });
    }

    closeGrid = () => {
        this.setState({ grid: null });
    }

    openList = (index) => {
        this.setState({ list: index });
    }

    closeList = () => {
        this.setState({ list: null });
    }

    openDocumentDrawer = () => {
        this.setState({ documentDrawer: {
            isOpen: true
        }});
    }

    closeDocumentDrawer = () => {
        this.setState({ documentDrawer: {
            isOpen: false
        }});
    }

    openInBatchDocumentsDrawer = () => {
        this.setState({
            documentDrawerInBatch: {
                isOpen: true
            }
        });
    }

    closeDocumentDrawerInBatch = () => {
        this.setState({
            documentDrawerInBatch: {
                isOpen: false
            }
        });
    }

    openPendingDocumentsDrawer = () => {
        this.setState({
            pendingDocumentsDrawer: {
                isOpen: true
            }
        })
    }

    closePendingDocumentsDrawer = () => {
        this.setState({
            pendingDocumentsDrawer: {
                isOpen: false
            }
        })
    }

    render() {
        const { date, grid, list, actived } = this.state;

        return (
            <Layout>
                <div style={{ 
                    margin: `0px -16px`, 
                    padding: '15px', 
                    background: '#fff', 
                    marginBottom: `10px`
                    }}
                >
                    <HeaderWrapper>
                        <TitleAndMonthPickerWrapper>
                            <ModuleHeader
                                breadcrumbs={
                                    this.props.match.params.id === '1'
                                        ? ['Dep. Societário', 'Tarefas']
                                        : ['Tarefas', 'Tarefas do mês', department(this.props.match.params.id)]
                                }
                                title={this.props.match.params.id === '1' ? 'Tarefas' : 'Tarefas do mês'}
                            />
                            <MonthPickerWrapper>
                                <span>Vencimento:</span>
                                <MonthPicker 
                                    defaultValue={moment(date, 'MM/YYYY')} 
                                    format={'MM/YYYY'}
                                    locale={locale}
                                    onChange={(value) => this.setState({date: value})}
                                    allowClear={false}
                                    disabled={this.props.isTasksContextLoading}
                                />
                            </MonthPickerWrapper>
                        </TitleAndMonthPickerWrapper>
                        <Radio.Group
                            value={actived} 
                            onChange={(e) => this.setState({actived: actived.includes(e.target.value)? actived.filter(n=>n!=e.target.value):[...actived, e.target.value]})}>
                            <Radio.Button value={0} style={actived.includes(0)?{color: `#f5222d`}:{}}>Atrasadas</Radio.Button>
                            <Radio.Button value={1} style={actived.includes(1)?{color: `#d4b106`}:{}}>Pendentes</Radio.Button>
                            <Radio.Button value={2} style={actived.includes(2)?{color: `#a0d911`}:{}}>Concluidas</Radio.Button>
                        </Radio.Group>
                        <ButtonsWrapper>
                            <Radio.Group
                                value={this.state.visualizationMode}
                                onChange={({ target }) => {
                                    this.setState({ visualizationMode: target.value });
                                    localStorage.setItem(TASKS_VISUALIZATION_MODE, target.value);
                                }}
                            >
                                <Radio.Button value="box">Box</Radio.Button>
                                <Radio.Button value="list">Lista</Radio.Button>
                            </Radio.Group>
                            <Dropdown
                                trigger={["click"]}
                                overlay={
                                    <Menu>
                                        <Menu.Item key="1" onClick={this.openDocumentDrawer}>
                                            Individual
                                        </Menu.Item>
                                        <Menu.Item key="2" onClick={this.openInBatchDocumentsDrawer}>
                                            Em lote
                                        </Menu.Item>
                                        <Menu.Item key="3" onClick={this.openPendingDocumentsDrawer}>
                                            Pendentes
                                        </Menu.Item>
                                    </Menu>
                                }
                            >
                                <Button
                                    type="primary"
                                >
                                    Adicionar documento
                                </Button>
                            </Dropdown>
                        </ButtonsWrapper>
                    </HeaderWrapper>
                </div>
                <Tasks
                    {...this.props}
                    date={date}
                    openGrid={this.openGrid}
                    openList={this.openList}
                    filter={actived}
                    isListOpen={list !== null}
                    visualizationMode={this.state.visualizationMode}
                />

                <Grid 
                    {...this.props}
                    visible={(grid !== null)}
                    onClose={this.closeGrid}
                    data={grid}
                    date={date}
                />
                <List 
                    date={date}
                    visible={(list !== null)}
                    onClose={this.closeList}
                    id={list}
                    departmentId={this.props.match.params.id}
                />
                <SporadictDocumentUpload
                    isOpen={this.state.documentDrawer.isOpen}
                    onClose={this.closeDocumentDrawer}
                    selectedDepartmentId={this.props.match.params.id}
                />
                <SporadicDocumentUploadInBatch
                    isVisible={this.state.documentDrawerInBatch.isOpen}
                    onClose={this.closeDocumentDrawerInBatch}
                    selectedDepartmentId={this.props.match.params.id}
                />
                <PendingDocumentsDrawer
                    isVisible={this.state.pendingDocumentsDrawer.isOpen}
                    onClose={this.closePendingDocumentsDrawer}
                />
            </Layout>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        isTasksContextLoading: state.allDepartmentsCalendarTasks.isLoading
    }
}

export default connect(mapStateToProps)(ScreensCalendarTask);
