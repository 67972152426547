import React, { useState } from 'react';
import { Icon, Tag } from 'antd';
import { Link, useLocation, useHistory } from "react-router-dom";

import DrawerForms from './Forms';

import { ClicksCountModal } from './components/clicks-count/ClicksCountModal';
import { ModuleHeader } from '~/components/ModuleHeader';
import { AutomationsModal } from './components/automations/AutomationsModal';

import {
  Card,
  CardContent,
  CardDescription,
  Cards,
  CardTitle,
  Container,
  Header,
  MainContainer,
} from './styles';

export const Plugins = () => {
  const location = useLocation();
  const history = useHistory();
  const pathname = location.pathname;

  const [clicksCountModal, setClicksCountModal] = useState({
    isVisible: false,
  });
  const [automationsModal, setAutomationsModal] = useState({
    isVisible: false,
  });

  function hideClicksCountModal() {
    setClicksCountModal({
      isVisible: false,
    });
  }

  function showClicksCountModal() {
    setClicksCountModal({
      isVisible: true,
    });
  }

  function hideAutomationsModal() {
    setAutomationsModal({
      isVisible: false,
    });
  }

  // function showAutomationsModal() {
  //   setAutomationsModal({
  //     isVisible: true,
  //   });
  // }

  return (
    <Container>
      <Header>
        <ModuleHeader breadcrumbs={['Plugins']} />
      </Header>
      <MainContainer>
        <Cards>
          <Link to="/plugins/forms">
            <Card>
              <CardContent>
                <CardTitle><Icon type="form" /> Formulários <Tag color="purple" style={{position: 'absolute', right: 0}}>novo!</Tag></CardTitle>
                <CardDescription>
                  Crie formulários no formato chatbot para capturar informações, conecte com outros modulos e automatize processos.
                </CardDescription>
              </CardContent>
            </Card>
          </Link>
          <Card onClick={showClicksCountModal}>
            <CardContent>
              <CardTitle><Icon type="link" /> Contador de cliques</CardTitle>
              <CardDescription>
                Acompanhe quantas vezes um link foi clicado, ideal para campanhas de marketing.
              </CardDescription>
            </CardContent>
          </Card>
          <Link to="/indicacao">
            <Card>
              <CardContent>
                <CardTitle><Icon type="gift" /> <span>Indicação premiada</span> <Tag color="purple" style={{position: 'absolute', right: 0}}>novo!</Tag></CardTitle>
                <CardDescription>
                  Aumente sua base de clientes coletando indicações dos seus próprios clientes.
                </CardDescription>
              </CardContent>
            </Card>
          </Link>
          {/* <Card onClick={showAutomationsModal}>
            <CardContent>
              <CardTitle>Automações</CardTitle>
              <CardDescription>
                Crie automações inteligentes para automatizar processos.
              </CardDescription>
            </CardContent>
          </Card> */}
        </Cards>
        {pathname === '/plugins/forms' && (
          <DrawerForms
            isVisible={true} 
            onClose={() => {
              history.push('/plugins');
            }}
          />
        )}
        <ClicksCountModal
          isVisible={clicksCountModal.isVisible}
          onClose={hideClicksCountModal}
        />
        <AutomationsModal
          isVisible={automationsModal.isVisible}
          onClose={hideAutomationsModal}
        />
      </MainContainer>
    </Container>
  );
};
