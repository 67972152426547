import React from 'react';
import { Table, Icon, Dropdown, Menu, Popover } from 'antd';
import Moment from 'react-moment';
import moment from 'moment';
import { toBRL } from '~/components/UI/InputCurrency';
import { getLabel } from '~/helpers/paymentMethods'
import { getDefaultPaginationConfig } from '~/utils/get-default-pagination-config';
import { PERMISSIONS } from '~/constants/permissions.constants';
import Download from '~/components/Components/Download';
import { Status } from '~/components/UI';
import { STORAGE } from '~/constants/storage.constants';

const { Column } = Table;

export default function TableList({
    isLoading,
    filtered,
    monthly,
    drawerPay,
    isTwelfthOnboardingActive,
    rowSelection,
    user,
    onAction,
    selectRow
}) {

    const getFilename = (data) => {
        const now = moment(data.apuration_at).format('MM-YYYY');
        return `${data.customer.name}_MENSALIDADE_${now}.pdf`;
    }

    return (
        <Table
            loading={isLoading}
            rowKey="id"
            dataSource={filtered}
            pagination={getDefaultPaginationConfig({
                storageKey: STORAGE.defaultPageSize.monthlyPayments
            })}
            bordered={true}
            rowClassName="row-clabs"
            scroll={{ x: 2470, y: `${window.innerHeight - 175}px` }}
            rowSelection={rowSelection}
            onRow={record => ({
              onClick: () => {
                selectRow(record);
              }
            })}
          >

            <Column
                title="Status"
                dataIndex="status"
                key="status"
                width={120}
                fixed="left"
                render={val => <Status value={val} />}
                filters={[{
                    text: 'Pago',
                    value: 'Pago'
                }, {
                    text: 'Pendente',
                    value: 'Pendente'
                },
                {
                    text: 'Inadimplente',
                    value: 'Inadimplente'
                },
                {
                    text: 'Ajuizado',
                    value: 'Ajuizado'
                },
                ]}
                filterMultiple={false}
                onFilter={(value, record) => record.status && record.status.indexOf(value) === 0}
            />
            <Column
                title="Cod"
                dataIndex="cod"
                key="cod"
                fixed="left"
                width={100}
            />
            <Column
                title="Empresa"
                dataIndex="customer.name"
                key="name"
                fixed="left"
                width={400}
                render={(val, record) => <span>{record.customer.cod} - {record.customer.name}</span>}
            />
            <Column
                title="Competência"
                dataIndex="apuration_at"
                key="apuration_at"
                width={150}
                sorter={(a, b) => a.apuration_at && a.apuration_at.localeCompare(b.apuration_at)}
                render={apuration_at =>
                    <center>
                    <Moment format="MM/YYYY">
                        {apuration_at}
                    </Moment>
                    </center>
                }
            />
            <Column
                title="Vencimento"
                dataIndex="due_at"
                key="due_at"
                width={150}
                sorter={(a, b) => a.due_at && a.due_at.localeCompare(b.due_at)}
                render={due_at =>
                    <center>
                    <Moment format="DD/MM/YYYY">
                        {due_at}
                    </Moment>
                    </center>
                }
            />
            <Column
                title="Método Pgto"
                dataIndex="method"
                key="method"
                width={150}
                render={value => !!value && getLabel(value).name}
            />
            <Column
              title="Data de Pag."
                dataIndex="paid_at"
                key="paid_at"
                width={150}
                sorter={(a, b) => a.paid_at && a.due_at.localeCompare(b.paid_at)}
                render={paid_at =>
                (paid_at &&
                    <center>
                    <Moment format="DD/MM/YYYY">
                        {paid_at}
                    </Moment>
                    </center>)
                }
            />
            <Column
                title="Mensalidade"
                dataIndex="amount"
                key="amount"
                width={150}
                sorter={(a, b) => a.amount && (a.amount - b.amount)}
                render={value => toBRL(value)}
            />
            <Column
                title="Adicionais"
                dataIndex="additionals_amount"
                key="additionals_amount"
                width={150}
                sorter={(a, b) => a.additionals_amount && (a.additionals_amount - b.additionals_amount)}
                render={value => toBRL(value)}
            />
            <Column
                title="Pago"
                dataIndex="paid"
                key="paid"
                width={200}
                sorter={(a, b) => a.amount && (a.amount - b.amount)}
                render={(val, record) => record.paid ? toBRL(record.paid) : '0,00'}
            />
            <Column
                title="Juros recebido"
                dataIndex="interest"
                key="interest"
                width={200}
                sorter={(a, b) => a.interest && (a.interest - b.interest)}
                render={(val, record) => record.interest ? toBRL(record.interest) : '0,00'}
            />
            <Column
                title="Desconto cedido"
                dataIndex="discount"
                key="discount"
                width={200}
                sorter={(a, b) => a.discount && (a.discount - b.discount)}
                render={(val, record) => record.discount ? toBRL(record.discount) : '0,00'}
            />
            <Column
                title="13º Salário"
                dataIndex="amount13"
                key="amount13"
                width={150}
                sorter={(a, b) => a.amount13 && (a.amount13 - b.amount13)}
                render={value => toBRL(value)}
            />
            <Column
                title="13º Salário Pago"
                dataIndex="paid13"
                key="paid13"
                width={150}
                sorter={(a, b) => a.amount13 && (a.amount13 - b.amount13)}
                render={value => toBRL(value)}
            />
            <Column
                title="Saldo"
                dataIndex="balance"
                key="balance"
                width={200}
                render={(val, record) => record.paid ? toBRL((parseFloat(record.amount)+parseFloat(record.additionals_amount)) - parseFloat(record.paid)) : toBRL(parseFloat(record.amount)+parseFloat(record.additionals_amount))}
            />
            <Column
                dataIndex="actions"
                key="actions"
                fixed="right"
                width={150}
                render={(text, record, index) => {
                    const showOnboarding =
                    index === monthly.length - 1 &&
                    isTwelfthOnboardingActive &&
                    !drawerPay;
                    
                    return (
                    <center key={index}>
                        <Dropdown overlay={
                        <Menu onClick={(e) => onAction(e, record)}>
                            {record.paid_at === null ?
                            !user.permissions.includes(PERMISSIONS.financial.monthlyPayments.edit) ?
                                <Menu.Item key="pay" disabled={showOnboarding}>
                                <Icon type="check"></Icon> Marcar como Pago
                                </Menu.Item>
                                :
                                <Menu.Item key="pay">
                                <Icon type="edit"></Icon> Editar
                                </Menu.Item>
                            :
                            <Menu.Item key="pay" disabled={showOnboarding}>
                                <Icon type="edit"></Icon> Alterar
                                </Menu.Item>
                            }

                            {record.status !== 'Pago' && record.status !== 'Inadimplente' && (
                            <Menu.Item key="inadimplente" disabled={showOnboarding}>
                                <Icon type="exclamation-circle"></Icon> Marcar como inadimplente
                            </Menu.Item>
                            )}
                            {record.status !== 'Pago' && record.status !== 'Ajuizado' && (
                            <Menu.Item key="ajuizado" disabled={showOnboarding}>
                                <Icon type="audit"></Icon> Marcar como ajuizado
                            </Menu.Item>
                            )}

                            <Menu.Item disabled={showOnboarding}>
                            <Download url={`/monthly/payments/print/${record.id}`} filename={getFilename(record)}>
                                <div><Icon style={{
                                minWidth: '12px',
                                marginRight: '8px',
                                fontSize: '12px'
                                }} type="printer" ></Icon> Imprimir</div>
                            </Download>
                            </Menu.Item>
                            <Menu.Divider />
                            {user.permissions.includes(PERMISSIONS.financial.monthlyPayments.delete) && (
                            <Menu.Item key="delete" disabled={showOnboarding}>
                                <Icon type="delete"></Icon> Deletar
                            </Menu.Item>
                            )}

                        </Menu>
                        } trigger={['click']}>
                        <Popover
                            placement="bottomRight"
                            content="Clique aqui, edite esta mensalidade e defina como quitado"
                            visible={showOnboarding}
                        >
                            <a className="ant-dropdown-link" href="#">
                            Ações <Icon type="down" />
                            </a>
                        </Popover>
                        </Dropdown>
                    </center>
                )
              }}
            />
        </Table>
    );
}