import React from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';

import ScreensLogin from './screens/Login';
import ScreensRecoverPassword from './screens/Recover';
import ScreensSignUp from './screens/SignUp';
import ScreensInvited from './screens/Invited';
import ScreensUsers from './screens/Users';
import ScreensCalendarTask from './screens/CalendarTask';
import ScreensLogout from './screens/Logout';
import ScreensAccount from './screens/Account';
import ScreeesNoMatch from './screens/NoMatch';
import ScreensPermissions from './screens/Permissions';
import { ScreensLinks } from './screens/Links';
import { ScreensDocuments } from './screens/Documents';
import { ScreensMalhaFina } from './containers/MalhaFina';
import { ScreensProcurations } from './screens/Procurations';
import { ScreensIRPF } from './containers/IRPF';
import { ScreensCertificates } from './screens/Certificates';
import { ScreensSocietario } from './screens/Societario/Index';
import { ScreensRefunds } from './containers/Refunds';
import { ScreensBillsToReceive } from './screens/BillsToReceive';
import { ScreensMonthlyPayment } from './screens/Financial/MonthlyPayment';
import { ScreensConventions } from './screens/Conventions';
import { ScreensRegisterTasks } from './screens/RegisterTasks';
import { ScreensDashboard } from './screens/Dashboard';
import { ScreensCustomers } from './screens/Customers';
import { ScreensLeads } from './screens/Leads';
import { ScreensRT } from './screens/RT';
import { ScreensChecklist } from './screens/Checklist';
import { ScreensLeadForms } from './screens/LeadForms';
import FormsIndication from './screens/FormsIndication';
import { ScreensPlugins } from './screens/Plugins';
import { AppContext } from './AppContext';
import { ScreensDocumentsDeadlines } from './screens/DocumentsDeadlines';
import { ROUTES } from './constants/routes.constants';
import { Onboarding } from './components/Onboarding';
import { ScreensEmailsLogs } from './screens/EmailsLogs';
import { ScreensFeedbacks } from './screens/Feedbacks';
import { ScreensHonoraries } from './screens/Honoraries';
import { ScreensKnowledgeBase } from './screens/KnowledgeBase';
import { ScreensBoards } from './screens/Boards/index';
import ReferralProgram from './screens/ReferralProgram';
import { ScreensPluginsForms } from './screens/PluginsForms';
import ScreenDefaultersReports from './screens/Reports/Defaulters';

const ScreensRoot = () => {
  return (
    <Router>
      <Switch>
        <Route path={ROUTES['/login']} component={ScreensLogin} />
        <Route path={ROUTES['/esqueci-a-senha']} component={ScreensRecoverPassword} exact/>
        <Route path={ROUTES['/esqueci-a-senha/:token']} component={ScreensRecoverPassword} />
        <Route path={ROUTES['/cadastro']} component={ScreensSignUp} />
        <Route path={ROUTES['/convidado/:invitation']} component={ScreensInvited} />
        <Route path={ROUTES['/leads/forms/:id']} component={ScreensLeadForms} />
        <Route path={ROUTES['/f/:token']} component={ScreensPluginsForms} />
        <Route path={ROUTES['/i/:id/:indicated_by_id?']} component={FormsIndication} />

        {/* Routas que necessitam de autenticação */}
        <AppContext>
          <Route path={ROUTES['/usuarios']} component={ScreensUsers} />
          <Route path={ROUTES['/permissoes']} component={ScreensPermissions} />
          <Route path={ROUTES['/clientes']} component={ScreensCustomers} strict/>
          <Route path={ROUTES['/checklist']} component={ScreensChecklist} strict/>
          <Route path={ROUTES['/certificados']} component={ScreensCertificates} strict/>
          <Route path={ROUTES['/lembretes']} component={ScreensProcurations} strict/>
          <Route path={ROUTES['/convencoes']} component={ScreensConventions} strict/>
          <Route path={ROUTES['/societario']} component={ScreensSocietario} strict/>
          <Route path={ROUTES['/conta']} component={ScreensAccount} strict/>
          <Route path={ROUTES['/tarefas']} component={ScreensRegisterTasks} strict/>
          <Route path={ROUTES['/documentos']} component={ScreensDocuments} strict/>
          <Route path={ROUTES['/calendario/:id/:department']} component={ScreensCalendarTask} strict/>
          <Route path={ROUTES['/financeiro/extras']} component={ScreensBillsToReceive} strict/>
          <Route path={ROUTES['/financeiro/mensalidades']} component={ScreensMonthlyPayment} strict/>
          <Route path={ROUTES['/financeiro/comissoes']} component={ScreensRefunds} strict/>
          <Route path={ROUTES['/leads']} component={ScreensLeads} strict/>
          <Route path={ROUTES['/recuperacao-tributaria']} component={ScreensRT} strict/>
          <Route path={ROUTES['/links']} component={ScreensLinks} strict />
          <Route path={ROUTES['/IRPF']} component={ScreensIRPF} strict/>
          <Route path={ROUTES['/malha-fina/:department']} component={ScreensMalhaFina} strict/>
          <Route path={ROUTES['/relatorios/inadimplentes']} component={ScreenDefaultersReports} strict />
          <Route path={ROUTES['/relatorios/prazos']} component={ScreensDocumentsDeadlines} strict />
          <Route path={ROUTES['/relatorios/honorarios']} component={ScreensHonoraries} strict />
          <Route path={ROUTES['/relatorios/emails']} component={ScreensEmailsLogs} strict />
          {/* <Route path={ROUTES['/relatorios/emails/erros']} component={ScreensEmailsLogs} strict /> */}
          <Route path={ROUTES['/relatorios/kpis']} component={ScreensFeedbacks} strict />
          <Route path={ROUTES['/boards']} component={ScreensBoards} strict/>
          <Route path={ROUTES['/plugins/forms']} component={ScreensPlugins} strict />
          <Route path={ROUTES['/plugins']} component={ScreensPlugins} strict />
          <Route path={ROUTES['/base-de-conhecimento']} component={ScreensKnowledgeBase} strict />
          <Route path={ROUTES['/indicacao']} component={ReferralProgram} strict />
          <Route path={ROUTES['/sair']} component={ScreensLogout} />
          <Route path={ROUTES['/']} component={ScreensDashboard} exact/>
          <Onboarding />
        </AppContext>

        {/* Rota 404 */}
        <Route path="*" component={ScreeesNoMatch}/>
      </Switch>
    </Router>
  )
};

export default ScreensRoot;