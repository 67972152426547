import update from 'immutability-helper';
import get from 'lodash/get';
import moment from 'moment';
import { orderBy } from 'natural-orderby';
import * as LEADS from './actions';
import * as LEAD from '../DrawerLeads/actions';
import { getStageName } from './helpers/getStageName';
import { CACHE_ACTIONS } from '~/constants/cache-actions.constants';

const orderDesc = (leads) => orderBy(leads, [l => get(l, 'tasks[0].due_at'), l => get(l, 'tasks[0].id')], ['asc', 'asc']); 
const orderDescByColumnLog = (leads) => orderBy(leads, [l => get(l, 'last_column_updated_at'), l => get(l, 'id')], ['desc', 'desc']);

const ACTION_HANDLERS = {
    [CACHE_ACTIONS.leads]: (state, action) => {
        return {
            ...state,
            isLoading: false,
            hideLeads: action.payload.hideLeads,
            columns: state.columns.map((column) => {
                const currentColumn = action.payload.columns.find(({ id }) => id === column.id);
                return {
                    ...column,
                    leads: [1, 5].includes(column.id)
                        ? orderDesc(currentColumn.leads)
                        : orderDescByColumnLog(currentColumn.leads),
                    total: currentColumn.total,
                    selectedMonth: action.payload.metaInfo.qtdd[column.id]
                }
            }),
            currentMetrics: action.payload.metaInfo.metrics,
            currentSummary: {
                ...state.currentSummary,
                ...action.payload.metaInfo.summary
            }
        }
    },

    [LEADS.LOAD_LEADS.SUCCESS]: (state, action) => {
        const handledState = action.payload.data.leads;
        return {
            ...state,
            hideLeads: action.payload.data.hideLeads,
            columns: state.columns.map((column) => {
                const currentColumn = handledState.find(({ id }) => id === column.id);
                return {
                    ...column,
                    leads: column.id === 1 || column.id === 5
                        ? orderDesc(currentColumn.leads)
                        : orderDescByColumnLog(currentColumn.leads),
                    total: currentColumn.total,
                }
            }),
            isLoading: false
        };
    },

    [LEADS.LOAD_MORE_LEADS.SUCCESS]: (state, action) => {
        return {
            ...state,
            columns: state.columns.map((column) => {
                const requestedColumnId = action.meta.previousAction.payload.request.columnId;
                if (column.id !== requestedColumnId) {
                    return column;
                }
                const leads = [
                    ...column.leads,
                    ...action.payload.data.leads
                ];
                return {
                    ...column,
                    leads: column.id === 1 || column.id === 5
                        ? orderDesc(leads)
                        : orderDescByColumnLog(leads),
                }
            })
        }
    },

    [LEADS.SAVE_METRICS.SUCCESS]: (state, action) => {
        const sentMetrics = action.meta.previousAction.payload.request.data;

        return {
            ...state,
            currentMetrics: {
                access_count: sentMetrics.access_count,
                ads_amount: sentMetrics.ads_amount,
                blog_amount: sentMetrics.blog_amount
            }
        }
    },

    [LEADS.FETCHQTDD.SUCCESS]: (state, action) => {
        return {
            ...state,
            columns: state.columns.map((column) => ({
                ...column,
                selectedMonth: get(action, `payload.data.data.qtdd[${column.id}]`)
            })),
            currentMetrics: action.payload.data.data.metrics,
            currentSummary: {
                ...state.currentSummary,
                ...action.payload.data.data.summary
            }
        }
    },

    [LEAD.CREATE.SUCCESS]: (state, action) => {
        return update(state, {
            columns: {
                $apply: c => c.map((column) => {
                    if(column.id !== 1) return column;
                    const createdLead = get(action, 'payload.data.data'); 
                    const newIndicatedLeads = get(action, 'payload.data.new_indicated_leads');
                    return {
                        ...column,
                        leads: orderDesc([
                            createdLead,
                            ...newIndicatedLeads,
                            ...column.leads
                        ])
                    }
                })
            },
            currentSummary: {
                $set: {
                    ...state.currentSummary,
                    stages: {
                        ...state.currentSummary.stages,
                        opportunity: {
                            ...state.currentSummary.stages.opportunity,
                            current_month_count: state.currentSummary.stages.opportunity.current_month_count + 1
                        }
                    }
                }
            }
        })
    },

    [LEAD.SAVE.SUCCESS]: (state, action) => {
        const updatedLead = get(action, 'payload.data.data');
        const newIndicatedLeads = get(action, 'payload.data.new_indicated_leads');
        return update(state, {
            hideLeads: {
                $set: {
                    form: updatedLead.stage === 0
                        ? state.hideLeads.form.map((lead) => lead.id === updatedLead.id ? updatedLead : lead)
                        : state.hideLeads.form
                }
            },
            columns: {
                $apply: c => c.map((column) => {
                    if(newIndicatedLeads.length > 0 && column.id === 1){
                        const formattedLeads = column.id === updatedLead.stage
                            ? column.leads.map((lead) => lead.id === updatedLead.id ? updatedLead : lead)
                            : column.leads;

                        return {
                            ...column,
                            leads: orderDesc([
                                ...formattedLeads,
                                ...newIndicatedLeads
                            ])
                        }
                    } else if (column.id === updatedLead.stage) {
                        return {
                            ...column,
                            leads: (column.id == 5 || column.id == 1) ? orderDesc(column.leads.map(l => l.id === updatedLead.id? updatedLead : l)): orderDescByColumnLog(column.leads.map(l => l.id === updatedLead.id? updatedLead : l)),
                        }
                    } else{
                        return column;
                    }
                })
            },
        })
    },

    [LEAD.DELETE.SUCCESS]: (state, action) => {
        const lead = get(action, 'meta.previousAction.payload.lead');
        const stageName = getStageName(lead.stage);
    
        const isLeadCreatedThisMonth =
          moment(lead.created_at).format('YYYY-MM') === moment().format('YYYY-MM');
    
        return update(state, {
          hideLeads: {
            $set: {
                form: state.hideLeads.form.filter(({ id }) => id !== lead.id)
            }
          },
          columns: {
            $apply: (c) =>
              c.map((column) => {
                if (column.id === lead.stage) {
                  return {  
                    ...column,
                    leads: column.leads.filter((l) => l.id !== lead.id),
                  };
                } else {
                  return column;
                }
              }),
          },
          ...(isLeadCreatedThisMonth && {
            currentSummary: {
              $set: {
                ...state.currentSummary,
                stages: stageName
                    ? {
                        ...state.currentSummary.stages,
                        [stageName]: {
                            ...state.currentSummary.stages[stageName],
                            current_month_count: state.currentSummary.stages[stageName].current_month_count - 1,
                        },
                    }
                    : state.currentSummary.stages
              },
            },
          }),
        });
      },

    [LEADS.MOVE.INIT]: (state, action) => {

        const sourceClone = Array.from(action.props[0]);
        const destClone = Array.from(action.props[1]);
        const droppableSource = action.props[2];
        const droppableDestination = action.props[3];
        const [removed] = sourceClone.splice(droppableSource.index, 1);

        destClone.splice(droppableDestination.index, 0, removed);
    
        return update(state, {
            columns: {
                $apply: c => c.map((column) => {
                    if(column.id !== droppableSource.droppableId 
                        && column.id !== droppableDestination.droppableId) 
                        return column;

                    else if(column.id === droppableSource.droppableId)
                        return {
                            ...column,
                            leads: sourceClone
                        }
                    else if(column.id === droppableDestination.droppableId)
                    return {
                        ...column,
                        leads: destClone
                    }
                
                })
            }
        })
    },

    [LEADS.MOVE.SUCCESS]: (state, action) => {
        const lead = get(action, 'payload.data.data');
        return update(state, {
            columns: {
                $apply: c => c.map((column) => {
                    if(column.id === lead.stage){
                        return {
                            ...column,
                            leads: lead.stage === 5 ?orderDesc(column.leads.map(l => l.id === lead.id? lead : l)): orderDescByColumnLog(column.leads.map(l => l.id === lead.id? lead : l))
                        }
                    }else{
                        return column;
                    }
                })
            }
        })
    },
}

const defaultState = {
    isLoading: true,
    columns: [
        { id: 1, name:'Oportunidade', style: { background: '#f5f5f5', color: '#722ed1', fontSize: '20px', textAlign: 'center' }, leads: [], selectedMonth: null },
        // { id: 2, name:'Ligação',  style: { background: '#f5f5f5', color: '#faad14', fontSize: '20px', textAlign: 'center'  }, leads: [], selectedMonth: null},
        { id: 5, name:'Follow Up',  style: { background: '#f5f5f5', color: '#1890ff', fontSize: '20px', textAlign: 'center'  }, leads: [], selectedMonth: null},
        { id: 3, name:'Positivo', style: { background: '#f5f5f5', color: '#52c41a', fontSize: '20px', textAlign: 'center'  }, leads: [], selectedMonth: null},
        { id: 4, name:'Negativo', style: { background: '#f5f5f5', color: '#f5222d', fontSize: '20px', textAlign: 'center' }, leads: [], selectedMonth: null},     
    ],
    hideLeads: {
        form: []
    },
    updatedLead: null,
    currentMetrics: {
        access_count: null,
        ads_amount: null,
        blog_amount: null,
    },
    currentSummary: {
        stages: {
            clicks: {
                current_month_count: 0,
                latest_month_count: 0,
            },
            opportunity: {
                current_month_count: 0,
                latest_month_count: 0,
            },
            proposal: {
                current_month_count: 0,
                latest_month_count: 0,
            },
            positive: {
                current_month_count: 0,
                latest_month_count: 0,
            },
            negative: {
                current_month_count: 0,
                latest_month_count: 0,
            },
        },
        moved_stages: {
            clicks_percentage: 0,
            opportunity_percentage: 0,
            proposal_percentage: 0,
            positive_percentage: 0,
        }
    }
}

const reducer = (state = defaultState, action) => {
    const handler = ACTION_HANDLERS[action.type];
  
    return handler ? handler(state, action) : state;
};

export default reducer;