import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import get from 'lodash/get';
import 'moment/locale/pt-br';
import uid from 'uid';

import {
    Input,
    Button,
    Form,
    Row,
    Col,
    Drawer,
    Divider,
    Progress,
    Icon,
} from 'antd';

import { Cod } from '~/components/UI';
import TodoList from '~/components/TodoList';


import * as drawerBoard from './actions';

const DrawerBoardItem = ({
    form,
    drawer,
    dispatchClose,
    dispatchCreate,
    dispatchSave,
    dispatchDelete
}) => {

    const { getFieldDecorator } = form;
    const { data } = drawer;

    const [tasks, setTasks] = useState([]);
    const [loading, setLoading] = useState(false);
    const [deleteLoading, setDeleteLoading] = useState(false);

    useEffect(() => {
        setTasks([]);
        if (get(data, 'tasks')) {
            setTasks(data.tasks);
        }
    }, [data]);

    const closeDrawer = () => {
        form.resetFields();
        dispatchClose();
    }

    const handleCreate = () => {
        setLoading(true);
        form.validateFields((err, values) => {
            if (!err) {
                dispatchCreate({
                    ...values,
                    tasks,
                }).then(() => {
                    setLoading(false);
                    closeDrawer();
                });
            }
        });
    }

    const handleSave = () => {
        setLoading(true);
        form.validateFields((err, values) => {
            if (!err) {
                dispatchSave(data.id, {
                    ...values,
                    tasks,
                }).then(() => {
                    setLoading(false);
                    closeDrawer();
                });
            }
        });
    }

    const handleDelete = () => {
        setDeleteLoading(true);
        dispatchDelete(data).then(() => {
            setDeleteLoading(false);
            closeDrawer();
        });
    }

    const progress = tasks.length > 0 ? (tasks.filter(task => task.done).length / tasks.length) * 100 : 0;

  return (
    <Drawer
      title={(data.id) ? <span>Editar Item <Cod float="right">#{data.id}</Cod></span> : 'Cadastrar Item'}
      width={800}
      placement="right"
      onClose={closeDrawer}
      maskClosable={false}
      visible={drawer.show}
      destroyOnClose={true}
      style={{
        height: '100%',
        overflow: 'auto',
      }}
      bodyStyle={{ paddingBottom: '100px' }}
      >
        <Form layout="vertical">
            <Row gutter={16}>
                <Col span={24}>
                <Form.Item label="Título">
                    {getFieldDecorator('title', {
                    initialValue: get(data, 'title'),
                    rules: [{ required: true, message: 'Por favor informe um título' }],
                    })(<Input placeholder="Título" />)}
                </Form.Item>
                </Col>
                <Col span={24}>
                <Form.Item label="Descrição">
                    {getFieldDecorator('description', {
                    initialValue: get(data, 'description'),
                    })(<Input.TextArea placeholder="Descrição" rows={5}/>)}
                </Form.Item>
                </Col>
            </Row>
            <Divider>Tarefas</Divider>
            {tasks.length > 0 && (
                <>
                    <Progress 
                        percent={progress}
                        className="mb-10"
                    />
                    <TodoList
                        tasks={tasks}
                        setTasks={setTasks}
                    />
                </>
            )}
            <Button onClick={() => setTasks([...tasks, { id: uid(), title: '' }])} className="mt-10">
                <Icon type="folder-add" /> Adicionar Tarefa
            </Button>
        </Form>
        <div
            style={{
                position: 'absolute',
                bottom: 0,
                width: '100%',
                borderTop: '1px solid #e8e8e8',
                padding: '10px 16px',
                textAlign: 'right',
                left: 0,
                background: '#fff',
                borderRadius: '0 0 4px 4px',
            }}
        >
        {get(data, 'id') && (
            <Button 
                onClick={handleDelete} 
                type="danger" 
                loading={deleteLoading} 
                style={{ marginRight: '8px' }}>Deletar
            </Button>
        )}
        <Button
          style={{
            marginRight: 8,
          }}
          onClick={closeDrawer}
        >
          Fechar
        </Button>
        {(get(data, 'id')) && (
            <Button 
                onClick={handleSave} 
                type="primary" 
                loading={loading}>Salvar
            </Button>
        )}
        {!get(data, 'id') && (
            <Button 
                onClick={handleCreate} 
                type="primary" 
                loading={loading}>Cadastrar
            </Button>
        )}
      </div>
    </Drawer>
  )
}

const mapStateToProps = state => ({
  drawer: state.drawerBoard,
});

const mapDispatchProps = dispatch => ({
    dispatchClose: () => dispatch(drawerBoard.close()),
    dispatchCreate: (payload) => dispatch(drawerBoard.create(payload)),
    dispatchSave: (id, payload) => dispatch(drawerBoard.save(id, payload)),
    dispatchDelete: (payload) => dispatch(drawerBoard.remove(payload)),
});

export default connect(mapStateToProps, mapDispatchProps)(Form.create()(DrawerBoardItem));