import React, { Component } from 'react';
import { Button, DatePicker, Select, Form } from 'antd';
import { connect } from 'react-redux';

import moment from 'moment';
import 'moment/locale/pt-br';

import locale from '../../UI/DatePicker/Locale';
import * as montlyPaymentActions from '~/components/MonthlyPayment/actions';

const { MonthPicker } = DatePicker;
const { Option } = Select;

class AdvancedFilter extends Component {

    state = {
      filter_by: 'due_at'
    }

    handleFilter = () => {
        this.props.form.validateFields((err, {
          filter_by,
          from,
          to,
          status
        }) => {
            if (err) return;

            this.props.loadPayments({
              filter_by,
              from: from.format('YYYY-MM-01'),
              to: to.endOf('month').format('YYYY-MM-DD'),
              status
            });
        });
    }

    render() {
        const { filter_by } = this.state;
        const { getFieldDecorator } = this.props.form;

        return (
            <Form layout="inline" className="ant-advanced-search-form-inline" onSubmit={(e) => {
              e.preventDefault();
              this.handleFilter();
            }}>
               <Form.Item label="Filtrar por:">
                  {getFieldDecorator('filter_by', {
                      initialValue: filter_by,
                  })(
                  <Select>
                      <Option value="due_at">Vencimento</Option>
                      <Option value="apuration_at">Competência</Option>
                  </Select>
                  )}
                </Form.Item>
                <Form.Item label="Período" style={{ marginBottom: 0 }}>
                  <Form.Item style={{ marginRight: 0 }}>
                      {getFieldDecorator('from', {
                          initialValue: moment()
                      })(
                      <MonthPicker 
                          format={'MM/YYYY'}
                          locale={locale}
                      />
                      )}
                  </Form.Item>
                  <span style={{ display: 'inline-block', width: '24px', textAlign: 'center' }}>-</span>
                  <Form.Item style={{ marginRight: 0 }}>
                      {getFieldDecorator('to', {
                          initialValue: moment()
                      })(
                      <MonthPicker 
                          format={'MM/YYYY'}
                          locale={locale}
                      />
                      )}
                  </Form.Item>
                </Form.Item>
                <Form.Item label="Status">
                    {getFieldDecorator('status', {
                        initialValue: 'all'
                    })(
                    <Select>
                      <Option key={1} value="all">Todos</Option>
                      <Option key={2} value="paid">Pago</Option>
                      <Option key={3} value="pendent">Pendente</Option>
                      <Option key={4} value="defaulting">Inadimplente</Option>
                      <Option key={5} value="processed">Ajuizado</Option>
                    </Select>
                    )}
                </Form.Item>
                <Form.Item>
                <Button type="primary" htmlType="submit">
                    Filtrar
                </Button>
            </Form.Item>
          </Form>
        )
    }
}

const mapDispatchProps = (dispatch) => {
  return {
    loadPayments: (filter) =>
      dispatch(
        montlyPaymentActions.loadPayments(filter)
      ),
  };
};

export default connect(null, mapDispatchProps)(Form.create()(AdvancedFilter));