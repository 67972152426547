import React from "react";
import "moment/locale/pt-br";

import {
  ButtonsWrapper,
  Container,
  Content,
  RegistrationButton,
} from "./styles";
import { ModuleHeader } from '~/components/ModuleHeader';

export const Header = ({
  showDrawer,
  isSeventhOnboardingStepActive,
}) => {
  return (
    <Container>
      <Content>
        <ModuleHeader breadcrumbs={['Recuperação Tributária']} />
        <ButtonsWrapper>
            <RegistrationButton
              type="primary"
              onClick={() => showDrawer()}
              style={{ zIndex: isSeventhOnboardingStepActive ? 999 : "auto" }}
            >
              Cadastro
            </RegistrationButton>
        </ButtonsWrapper>
      </Content>
    </Container>
  );
};
