import React, { useState } from 'react';
import { useDispatch, useSelector } from "react-redux";
import 'moment/locale/pt-br';
import {
    Form,
    Drawer,
    Button,
    Select,
    Row,
    Col,
    Divider,
    Tooltip,
} from 'antd';
import moment from 'moment';

import DrawerFooter from '~/components/UI/Drawer';

import * as actions from './actions'
import * as referralActions from '~/screens/ReferralProgram/actions';

import { WhatsappLink, WhatsappIcon, phoneMask } from '~/icons/WhatsappIcon';

const { Option } = Select;

const DrawerReferralIndication = ({
  form
}) => {
    const dispatch = useDispatch();
    const state = useSelector((state) => state.drawerReferralIndication);
    const customersList = useSelector((state) => state.customers.list);
    const data = state && state.data ? state.data : {};

    const [loading, setLoading] = useState(false);

    const { getFieldDecorator } = form;

    const handleSave = () => {
      form.validateFields((err, values) => {
        if (!err) {
          setLoading(true);
          dispatch(actions.save(data.id, {
              ...values,
          })).then(() => {
              setLoading(false);
              dispatch(referralActions.all());
          })
          .catch(() => {
              setLoading(false);
          });
        }
      });
    }

  if(!state.show){
    return null;
  }

  const getCustomerName = (id) => {
    const customer = customersList.find(c => c.id === id);
    return customer ? customer.name : '';
  }

  return (
    <Drawer
      title={'Detalhes da Indicação'}
      width={800}
      placement="right"
      onClose={() => dispatch(actions.close())}
      maskClosable={false}
      visible={state.show}
      destroyOnClose={true}
      style={{
        height: '100%',
        overflow: 'auto',
      }}
      bodyStyle={{ paddingBottom: '100px' }}
      >
        <Form layout="vertical">
          <Row gutter={16}>
            <Col span={12}>
              <Form.Item label={<strong>Empresa</strong>}>
                {data.company_name}
              </Form.Item>
            </Col>
           <Col span={12}>
              <Form.Item label={<strong>Atividade</strong>}>
                {data.company_activity}
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item label={<strong>Sócio responsável</strong>}>
                {data.company_contact}
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item label={<strong>Whatsapp da empresa</strong>}>
                {data.company_contact_phone && (
                <Tooltip title="Abrir no Whatsapp">
                    <WhatsappLink
                        href={`https://api.whatsapp.com/send?phone=55${
                          data.company_contact_phone.replace(/\D/g, '')
                        }`}
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        <WhatsappIcon /> {phoneMask(data.company_contact_phone.replace(/\D/g, ''))}
                    </WhatsappLink>
                </Tooltip>
                )}
              </Form.Item>
            </Col>
          </Row>

          <Divider orientation="left">Quem indicou</Divider>
          <Row gutter={16}>
            <Col span={12}>
              <Form.Item label={<strong>Nome</strong>}>
                {data.indicated_by}
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item label={<strong>WhatsApp</strong>}>
                {data.indicated_by_phone && (
                <Tooltip title="Abrir no Whatsapp">
                    <WhatsappLink
                        href={`https://api.whatsapp.com/send?phone=55${
                          data.indicated_by_phone.replace(/\D/g, '')
                        }`}
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        <WhatsappIcon /> {phoneMask(data.indicated_by_phone.replace(/\D/g, ''))}
                    </WhatsappLink>
                </Tooltip>
                )}
              </Form.Item>
            </Col>
            {data.indicated_by_id && getCustomerName(data.indicated_by_id) && (
            <Col span={12}>
              <Form.Item label={<strong>Empresa</strong>}>
                {getCustomerName(data.indicated_by_id)}
              </Form.Item>
            </Col>
            )}
          </Row>

          <Divider orientation="left">Ações</Divider>
          <Row gutter={16}>
            <Col span={12}>
              <Form.Item label={<strong>Status</strong>}>
                {getFieldDecorator('status', {
                  initialValue: data.status,
                })(
                  <Select
                    placeholder="Selecione o status"
                  >
                    <Option value={0}>Pendente</Option>
                    <Option value={1}>Positivo</Option>
                    <Option value={2}>Negativo</Option>
                  </Select>
                )}
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item label={<strong>Data da indicação</strong>}>
                {moment(data.created_at).format('DD/MM/YYYY HH:mm')}
              </Form.Item>
            </Col>
          </Row>
        </Form>

        <DrawerFooter>
        <Button onClick={() => dispatch(actions.close())}>Fechar</Button>
        {' '}
        {data.id ? (
          <Button 
            type="primary" 
            loading={loading}
            onClick={handleSave}
            >
            Salvar
            </Button>
        ) : (
          <Button type="primary">Cadastrar</Button>
        )}
        </DrawerFooter>
    </Drawer>
  )
}

export default Form.create({ name: 'drawer_referral_indication' })(DrawerReferralIndication);