/* eslint-disable jsx-a11y/anchor-is-valid */
import { Button, List, Timeline, Icon, Tooltip, Modal } from 'antd';
import React, { useState } from 'react';
import moment from 'moment';
import uid from 'uid';

import { PartnersSetModal } from './PartnersSetModal';
import { DateTag, TimelineContainer } from './styles';

import { WhatsappIcon, WhatsappLink, onlyNumbers } from '~/icons/WhatsappIcon';

export const Partners = ({ partnersSets, setPartnersSets }) => {
  const [partnersSetModal, setPartnersSetModal] = useState({
    isVisible: false,
  });

  function closePartnersSetModal() {
    setPartnersSetModal({
      isVisible: false,
    });
  }

  function openPartnersSetModal(selectedData = {}) {
    setPartnersSetModal({
      isVisible: true,
      action: 'add',
      ...selectedData,
    });
  }

  function wouldCopyLatestScenario() {
    if (partnersSets.length > 0) {
      Modal.confirm({
        title: 'Deseja copiar o último quadro societário?',
        okText: 'Sim',
        cancelText: 'Não',
        onOk() {
          const latestScenario = partnersSets[0];

          setPartnersSetModal({
            isVisible: true,
              ...latestScenario,
              partners: latestScenario.partners.map((partner) => ({
                ...partner,
                id: uid()
              })),
              id: uid(),
              action: 'add',
          });
        },
        onCancel() {
          setPartnersSetModal({
            isVisible: true,
          });
        }
      });
    } else {
      setPartnersSetModal({
        isVisible: true,
      });
    }
  }

  return (
    <div>
      <Button type="primary" onClick={() => wouldCopyLatestScenario()}>
        Novo cenário
      </Button>
      <TimelineContainer>
        <Timeline mode="left">
          {partnersSets
            .sort((a, b) => moment(b.change_date).diff(a.change_date))
            .map(({ id, formatted_change_date, partners }) => (
              <Timeline.Item key={id}>
                <DateTag>{formatted_change_date}</DateTag> <a href="#" onClick={
                  (e) => {
                    e.preventDefault();
                    openPartnersSetModal({
                      action: 'view',
                      id,
                      formatted_change_date,
                      partners
                    });
                  }
                }><Icon type="eye" /></a>
                <List
                  size="small"
                  bordered
                  dataSource={partners}
                  renderItem={({ name, percentage, phone }) => (
                    <List.Item
                      actions={phone ?
                        [
                          <Tooltip title="Abrir Whatsapp">
                            <WhatsappLink
                              href={`https://api.whatsapp.com/send?phone=55${
                                onlyNumbers(phone)
                              }`}
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              <WhatsappIcon />
                            </WhatsappLink>
                          </Tooltip>
                        ] : []
                      }>
                      {name} - {percentage}%
                    </List.Item>
                  )}
                />
              </Timeline.Item>
            ))}
        </Timeline>
      </TimelineContainer>
      <PartnersSetModal
        initialData={partnersSetModal}
        isVisible={partnersSetModal.isVisible}
        onClose={closePartnersSetModal}
        setPartnersSets={setPartnersSets}
      />
    </div>
  );
};
