import React, { useState } from 'react';
import { Button, Form, Select, Table, Tooltip } from 'antd';
import moment from 'moment';
import { useDispatch, useSelector } from 'react-redux';
import { compare } from 'natural-orderby';

import {
  Container,
  FilterSelect,
  FiltersContainer,
  Header,
  Main,
} from './styles';
import { capitalizeFormatter } from '~/utils/formatters';
import { ModuleHeader } from '~/components/ModuleHeader';
import * as honorariesActions from '~/containers/Honoraries/actions';
import './table-styles.css';
import { getDefaultPaginationConfig } from '~/utils/get-default-pagination-config';
import { STORAGE } from '~/constants/storage.constants';

export const Honoraries = ({ yearFilter, setYearFilter }) => {
  const dispatch = useDispatch();
  const { customers, isLoading } = useSelector((state) => ({
    customers: state.honoraries.list,
    isLoading: state.honoraries.isLoading,
  }));

  const [filter, setFilter] = useState();

  function createFlag(record) {
    const { id, average, hired, months } = record;

    const currentMonth = moment().format('MMM');

    const { billing, employees } = months[currentMonth];

    dispatch(
      honorariesActions.createFlag({
        customer_id: id,
        billing,
        billing_average: average.billing,
        billing_hired: hired.billing,
        employees_count: employees,
        employees_count_average: average.employees,
        employees_count_hired: hired.employees,
      })
    );
  }

  function removeFlag(customerId) {
    dispatch(honorariesActions.removeFlag(customerId));
  }

  const filteredCustomers = filter
    ? customers.filter(
        ({
          is_average_billing_higher,
          is_average_employees_higher,
          is_flagged,
        }) => {
          switch (filter) {
            case 'flaggeds': {
              return is_flagged;
            }
            case 'higher_billing_or_employees': {
              return is_average_billing_higher || is_average_employees_higher;
            }
            case 'higher_billing_and_employees': {
              return is_average_billing_higher && is_average_employees_higher;
            }
            case 'higher_billing': {
              return is_average_billing_higher;
            }
            case 'higher_employees': {
              return is_average_employees_higher;
            }
            default: {
              return true;
            }
          }
        }
      )
    : customers;

  const monthColumns = moment
    .monthsShort()
    .map((month) => [
      {
        title: capitalizeFormatter(month),
        align: 'center',
        key: `${month}.formatted_billing`,
        width: 140,
        colSpan: 2,
        dataIndex: `months.${month}.formatted_billing`,
        render: (value) => (
          <Tooltip title="Faturamento">
            <span
              style={{
                fontWeight: 'bold',
              }}
            >
              {value}
            </span>
          </Tooltip>
        ),
      },
      {
        align: 'center',
        key: `${month}.employees`,
        width: 80,
        colSpan: 0,
        dataIndex: `months.${month}.employees`,
        render: (value) => (
          <Tooltip title="Quantidade de funcionários">
            <span>{value}</span>
          </Tooltip>
        ),
      },
    ])
    .flat();

  const columns = [
    {
      title: 'Cod',
      key: 'cod',
      dataIndex: 'cod',
      fixed: 'left',
      defaultSortOrder: 'ascend',
      sorter: (a, b) => compare()(a.cod, b.cod),
      width: 100,
    },
    {
      title: 'Nome',
      key: 'name',
      dataIndex: 'name',
      fixed: 'left',
      width: 400,
    },
    ...monthColumns,
    {
      title: 'Média',
      align: 'center',
      key: 'average.formatted_billing',
      fixed: 'right',
      dataIndex: 'average.formatted_billing',
      colSpan: 2,
      width: 140,
      render: (
        value,
        { is_average_billing_higher, is_average_employees_higher }
      ) => {
        let backgroundColor = 'inherit';

        if (is_average_billing_higher) {
          backgroundColor = '#ff7875';
        } else if (is_average_employees_higher) {
          backgroundColor = '#ffa39e';
        }

        return {
          children: <Tooltip title="Faturamento">{value}</Tooltip>,
          props: {
            style: { backgroundColor },
          },
        };
      },
    },
    {
      align: 'center',
      key: 'average.employees',
      fixed: 'right',
      dataIndex: 'average.employees',
      colSpan: 0,
      width: 80,
      render: (
        value,
        { is_average_billing_higher, is_average_employees_higher }
      ) => {
        let backgroundColor = 'inherit';

        if (is_average_employees_higher) {
          backgroundColor = '#ff7875';
        } else if (is_average_billing_higher) {
          backgroundColor = '#ffa39e';
        }

        return {
          children: (
            <Tooltip title="Quantidade de funcionários">{value}</Tooltip>
          ),
          props: {
            style: { backgroundColor },
          },
        };
      },
    },
    {
      title: 'Contratado',
      align: 'center',
      key: 'hired.formatted_billing',
      fixed: 'right',
      colSpan: 2,
      dataIndex: 'hired.formatted_billing',
      width: 140,
      render: (value) => <Tooltip title="Faturamento">{value}</Tooltip>,
    },
    {
      align: 'center',
      key: 'hired.employees',
      fixed: 'right',
      colSpan: 0,
      dataIndex: 'hired.employees',
      width: 80,
      render: (value) => (
        <Tooltip title="Quantidade de funcionários">{value}</Tooltip>
      ),
    },
    {
      title: 'Honorário',
      align: 'center',
      key: 'hired.formatted_honorary',
      fixed: 'right',
      dataIndex: 'hired.formatted_honorary',
      width: 140,
      render: (value) => <Tooltip title="Honorário">{value}</Tooltip>,
    },
    {
      align: 'center',
      key: 'flag',
      fixed: 'right',
      dataIndex: 'is_flagged',
      width: 80,
      render: (isFlagged, record) => {
        return (
          <Button
            onClick={() =>
              isFlagged ? removeFlag(record.id) : createFlag(record)
            }
            icon={isFlagged ? 'close' : 'flag'}
            type={isFlagged ? 'danger' : 'default'}
          />
        );
      },
    },
  ];

  function generateYearOptions() {
    const currentYear = new Date().getFullYear();
    const years = [currentYear, currentYear - 1, currentYear - 2, currentYear - 3];
    
    return years.map((year) => (
      <Select.Option key={year} value={year}>
        {year}
      </Select.Option>
    ));
  }

  return (
    <Container>
      <Header>
        <ModuleHeader breadcrumbs={['Relatórios', 'Controle de honorários']} />
        <FiltersContainer>
          <Form layout="inline">
            <Form.Item label="Filtrar por">
              <FilterSelect
                placeholder="Selecione uma opção"
                allowClear
                onChange={(value) => setFilter(value)}
              >
                <Select.Option value="higher_billing_or_employees">
                  Faturamento ou funcionários superior ao contratado
                </Select.Option>
                <Select.Option value="higher_billing_and_employees">
                  Faturamento e funcionários superior ao contratado
                </Select.Option>
                <Select.Option value="higher_billing">
                  Faturamento maior que o contratado
                </Select.Option>
                <Select.Option value="higher_employees">
                  Quantidade de funcionários maior que o contratado
                </Select.Option>
                <Select.Option value="flaggeds">
                  Marcados com a flag
                </Select.Option>
              </FilterSelect>
            </Form.Item>

            <Form.Item label="Selecionar ano">
              <Select
                placeholder="Selecione um ano"
                defaultValue={new Date().getFullYear()}
                onChange={(value) => setYearFilter(value)}
              >
                {generateYearOptions()}
              </Select>
            </Form.Item>
          </Form>
        </FiltersContainer>
      </Header>
      <Main>
        <Table
          loading={isLoading}
          pagination={getDefaultPaginationConfig({
            storageKey: STORAGE.defaultPageSize.honoraries,
            defaultPageSize: 1000
          })}
          rowKey="id"
          bordered
          columns={columns}
          rowClassName={({ is_flagged }) => (is_flagged ? 'row-flagged' : '')}
          dataSource={filteredCustomers}
          scroll={{ y: 'calc(100vh - 300px)', x: true }}
        />
      </Main>
    </Container>
  );
};
