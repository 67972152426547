import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, DatePicker, Empty, Spin } from 'antd';
import moment from 'moment';

import { RatingChart } from './components/RatingChart';
import { Container, FeedbackCard, Header, Main } from './styles';
import { ModuleHeader } from '~/components/ModuleHeader';
import { FeedbacksModal } from './components/FeedbacksModal';
import * as feedbacksActions from '~/containers/Feedbacks/actions';

const { RangePicker } = DatePicker;

export const Feedbacks = () => {
  const dispatch = useDispatch();
  const { feedbacks, isLoading } = useSelector((state) => ({
    feedbacks: state.feedbacks.list,
    isLoading: state.feedbacks.isLoading,
  }));

  const [feedbacksModal, setFeedbacksModal] = useState({
    isVisible: false,
  });

  function closeFeedbacksModal() {
    setFeedbacksModal({ isVisible: false });
  }

  function openFeedbacksModal() {
    setFeedbacksModal({ isVisible: true });
  }

  return (
    <Container>
      <Header>
        <ModuleHeader breadcrumbs={['Relatórios', 'KPIs']} />
        <RangePicker
          format="DD/MM/YYYY"
          onChange={([initial, final]) => {
            dispatch(
              feedbacksActions.loadFeedbacks({
                initialDate: initial.format('YYYY-MM-DD'),
                finalDate: final.format('YYYY-MM-DD'),
              })
            );
          }}
          defaultValue={[moment().startOf('month'), moment().endOf('month')]}
          allowClear={false}
        />
      </Header>
      <Main>
        <FeedbackCard
          title="Área do cliente"
          extra={
            <Button type="primary" size="small" onClick={openFeedbacksModal}>
              Detalhes
            </Button>
          }
        >
          {isLoading ? <Spin /> : <RatingChart feedbacks={feedbacks} />}
          {!isLoading && feedbacks.length === 0 && (
            <Empty
              description="Não existem feedbacks neste período."
            />
          )}
        </FeedbackCard>
      </Main>
      <FeedbacksModal
        isVisible={feedbacksModal.isVisible}
        onClose={closeFeedbacksModal}
        feedbacks={feedbacks}
      />
    </Container>
  );
};
